import * as React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { allWordsSelector, activeCatAtom } from "../../../recoil/activeCat";
import { category_modules } from "../../../game/category_modules";
import { debugCategoryShowAll, debugCategoryMaxWords } from "../../../api";

import DebugWordView from "./DebugWordView";

const DebugCategory = () => {
  const [allWordsSel, setAllWordsSel] = useRecoilState(allWordsSelector);
  const activeCat = useRecoilValue(activeCatAtom);
  const [allWords, setAllWords] = React.useState(null);

  React.useEffect(() => {
    setAllWordsSel(true);
  }, []);

  React.useEffect(() => {
    if (!allWordsSel) {
      return;
    }
    if (!activeCat) {
      return;
    }
    if (debugCategoryShowAll) {
      setAllWords([...structuredClone(category_modules[activeCat.id].words)]);
      return;
    }
    setAllWords(structuredClone(allWordsSel));
  }, [allWordsSel, activeCat]);

  return (
    <div>
      <h1
        style={{ transform: "translateX(-50%)" }}
        className=" absolute left-1/2"
      >
        Debug Category Active
      </h1>
      {allWords &&
        allWords.reverse().map((wordObj, index) => {
          if (debugCategoryMaxWords != null) {
            if (index + 1 > debugCategoryMaxWords) {
              return;
            }
          }
          return wordObj.assets.map((asset, index) => (
            <DebugWordView
              key={`${wordObj.word}-${wordObj.difficulty}-${asset.data}`}
              wordObj={wordObj}
              wordList={allWords}
              assetIndex={index}
              categoryId={activeCat.id}
            ></DebugWordView>
          ));
        })}
    </div>
  );
};

export default DebugCategory;
