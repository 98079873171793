import { atom } from "recoil";
import AuthTypeEnum from "./AuthTypeEnum";

const userAtom = atom({
  key: "userAtom",
  default: {
    id: null,
    access_token: null,
    login: "",
    display_name: "",
    viewer_count: 0,
    authType: AuthTypeEnum.None,
  },
});

export default userAtom;
