import { atom } from "recoil";

const userGuessAtom = atom({
  key: "userGuessAtom",
  default: {
    wordInPlay: {},
    guessCommandString: null,
  },
});

export default userGuessAtom;
