//https://wes-rast.medium.com/recoil-project-structure-best-practices-79e74a475caa
import { atom } from "recoil";
import GameStateEnum from "./gameStateEnum";
import { bypassChatRequirement } from "../../api";

const gameStateAtom = atom({
  key: "gameStateAtom",
  default: bypassChatRequirement
    ? GameStateEnum.Selection
    : GameStateEnum.SignIn,
});

export default gameStateAtom;
