import * as React from "react";
import categories from "../../game/categories";
import CategoryButtonTile from "./CategoryButtonTile";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { activeCatAtom, activeDifficultiesAtom } from "../../recoil/activeCat";
import dailyCategory from "../../game/daily_category";
import { getCategoryCompletedPrecent } from "../utils/utils";

function CategorySelection() {
  const setActiveCatState = useSetRecoilState(activeCatAtom);
  const activeDifficulties = useRecoilValue(activeDifficultiesAtom);
  const [activeCategory, setActiveCategory] = React.useState();

  React.useEffect(() => {
    clear();

    //calc complete percentages
    categories.map((category) => {
      category.complete = getCategoryCompletedPrecent(category);
    });
  }, []);

  React.useEffect(() => {
    if (activeCategory != null) {
      let doClear = true;
      activeDifficulties.forEach((e) => {
        if (activeCategory.supportedDifficulties.includes(e)) {
          doClear = false;
        }
      });
      if (doClear) {
        clear();
      }
    }
  }, [activeDifficulties]);

  React.useEffect(() => {
    if (activeCategory != null) {
      setActiveCatState(
        //might cause problems https://stackoverflow.com/questions/41474986/how-to-clone-a-javascript-es6-class-instance
        Object.assign(
          Object.create(Object.getPrototypeOf(activeCategory)),
          activeCategory
        )
      );
    } else {
      setActiveCatState(null);
    }
  }, [activeCategory]);

  const clear = () => {
    resetList();
    setActiveCatState(null);
    setActiveCategory(null);
  };

  const resetList = () => {
    dailyCategory.active = false;
    categories.forEach((cat) => {
      cat.active = false;
    });
  };

  const addCategory = (category) => {
    resetList();
    category.active = true;
    setActiveCategory(category);
  };

  const removeCategory = () => {
    resetList();
    setActiveCategory(null);
  };

  const listCategories = () => {
    return (
      <>
        {/* <div className="h-20 w-full lg:w-3/5 xl:w-2/5 px-3 opacity-70"> */}
        {/* <CategoryButtonTile
            id={dailyCategory.id}
            complete={0}
            supportedDifficulties={dailyCategory.supportedDifficulties}
            label={dailyCategory.name}
            translate={dailyCategory.translateTitle}
            isChecked={dailyCategory.active}
            dailyCategory={dailyCategory}
            onClick={() => {
              if (!dailyCategory.active) {
                addCategory(dailyCategory);
              } else {
                removeCategory();
              }
            }}
          /> */}

        {/* <CategoryButtonTile
            id={dailyCategory.id}
            complete={0}
            supportedDifficulties={dailyCategory.supportedDifficulties}
            label={
              <span>
                {dailyCategory.name}{" "}
                <sub className="text-sm italic">Coming Soon</sub>
              </span>
            }
            isDisabled={true}
            translate={dailyCategory.translateTitle}
            isChecked={false}
          /> */}
        {/* </div> */}

        {categories.map((category) => {
          return (
            <div
              key={category.name}
              className="h-20 w-full lg:w-3/5 xl:w-2/5 px-3"
            >
              <CategoryButtonTile
                id={category.id}
                complete={category.complete}
                supportedDifficulties={category.supportedDifficulties}
                label={category.name}
                translate={category.translateTitle}
                isChecked={category.active}
                onClick={() => {
                  if (!category.active) {
                    addCategory(category);
                  } else {
                    removeCategory();
                  }
                }}
              />
            </div>
          );
        })}
      </>
    );
  };
  return (
    <div className="overflow-y-scroll  w-full h-full">
      <div className="flex flex-col xl:flex-row justify-center items-center flex-wrap w-full gap-y-12">
        {listCategories()}
      </div>
    </div>
  );
}

export default CategorySelection;
