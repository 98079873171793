import * as React from "react";

import { useRecoilValue } from "recoil";
import { GameStateEnum } from "../../recoil/gameState";
import useGameStateSet from "../utils/useGameStateSet";
import { activeCatAtom, activeDifficultiesAtom } from "../../recoil/activeCat";
import { chatAtom } from "../../recoil/chat";
import { bypassChatRequirement } from "../../api";
import { dataLayerPush } from "../../components/utils/utils";

function PlayBtn() {
  const gameStateSet = useGameStateSet();
  const activeCategory = useRecoilValue(activeCatAtom);
  const activeDifficulties = useRecoilValue(activeDifficultiesAtom);
  const chat = useRecoilValue(chatAtom);
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    let chatIsNotConnected = bypassChatRequirement
      ? false
      : !chat.chatConnected;
    setDisabled(activeCategory == null || chatIsNotConnected);
  }, [activeCategory, chat.chatConnected, activeDifficulties]);

  const button = () => {
    return (
      <button
        className={`mt-9 text-2xl  border-2 w-32 h-14 relative ${
          disabled
            ? "start-button--disabled cursor-default"
            : "start-button--enabled"
        }`}
        onClick={() => {
          if (!disabled) {
            gameStateSet(GameStateEnum.StartTransition);
            dataLayerPush("game_start");
          }
        }}
      >
        <div className="relative w-full h-full flex justify-center items-center">
          <div
            className={`absolute h-full bg-BOTC_DarkGreen  z-bg ${
              disabled ? "w-0" : "startBg-button w-full"
            }`}
          ></div>
          <span className="font-bold">Play</span>
        </div>
      </button>
    );
  };

  return button();
}

export default PlayBtn;
