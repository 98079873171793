import React, { useState } from "react";
import { usePopper } from "react-popper";
import PropTypes from "prop-types";
//https://popper.js.org/react-popper/v2/

const Tooltip = ({ tip, children, allowClick = true }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    { placement: "top-end" },
    {
      modifiers: [
        { name: "arrow", options: { element: arrowElement } },
        {
          name: "preventOverflow",
          options: {
            padding: 8,
          },
        },
      ],
    }
  );
  const [visibility, setVisibility] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          if (allowClick) {
            setVisibility(!visibility);
          }
        }}
        onBlur={() => {
          if (allowClick) {
            setVisibility(false);
          }
        }}
        onMouseEnter={() => {
          setVisibility(true);
        }}
        onMouseOut={() => {
          setVisibility(false);
        }}
        onMouseOver={() => {
          setVisibility(true);
        }}
        className="inline"
        role="button"
        ref={setReferenceElement}
      >
        {children}
      </div>

      {visibility && (
        <div
          className="bg-gray-600 p-1 text-gray-200 break-words z-50 rounded-md"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <span className="p-3 block">{tip}</span>

          <div
            ref={setArrowElement}
            style={styles.arrow}
            id="arrow"
            data-popper-arrow
          />
        </div>
      )}
    </>
  );
};

Tooltip.propTypes = {
  tip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node,
  allowClick: PropTypes.bool,
};
export default Tooltip;
