import { atom } from "recoil";

const roundScoresAtom = atom({
  key: "roundScoreListsAtom",
  default: {
    correctList: [],
    incorrectList: [],
    userScore: 0,
    optionsGuessCount: {},
  },
});

export default roundScoresAtom;
