import * as React from "react";
import PropTypes from "prop-types";

import { BGTransitionStateEnum } from "../../recoil/BGState";

import { MainBox, MainBoxStateEnum } from "./MainBox";
import { CameraTransition, CameraStateEnum } from "./CameraTransition";

import transitionStartSound from "../../audio/transitionStart.mp3";
import transitionNextSound from "../../audio/transitionNext.mp3";
import transitionEndSound from "../../audio/transitionEnd.mp3";
import shutdownSound from "../../audio/shutdown.mp3";

const EntityComposer = ({ bGState, setBGState, settings }) => {
  const [mainBoxStateEnum, setMainBoxStateEnum] = React.useState(
    MainBoxStateEnum.FreeForm
  );
  const [cameraStateEnum, setCameraStateEnum] = React.useState(
    CameraStateEnum.None
  );

  React.useEffect(() => {
    if (bGState.state === BGTransitionStateEnum.Start) {
      const sfx = new Audio(transitionStartSound);
      sfx.volume = settings.volume;
      sfx.play();

      setBGState({ ...bGState, backgroundClass: "bg-white" });
      setTimeout(() => {
        setMainBoxStateEnum(MainBoxStateEnum.NextFace);
        setCameraStateEnum(CameraStateEnum.Start);
      }, 400);
    } else if (bGState.state === BGTransitionStateEnum.NextRound) {
      const sfx = new Audio(transitionNextSound);
      sfx.volume = settings.volume;
      sfx.play();

      setBGState({ ...bGState, backgroundClass: "bg-white" });
      setMainBoxStateEnum(MainBoxStateEnum.NextFaceDelay);
      setCameraStateEnum(CameraStateEnum.Next);
    } else if (bGState.state === BGTransitionStateEnum.End) {
      const sfx = new Audio(transitionEndSound);
      sfx.volume = settings.volume;
      sfx.play();

      setBGState({ ...bGState, backgroundClass: "bg-white" });
      setTimeout(() => {
        const sfx = new Audio(shutdownSound);
        sfx.volume = settings.volume;
        sfx.play();
      }, 3700);

      setTimeout(() => {
        setBGState({ ...bGState, backgroundClass: "bg-BOTC_DarkBlue" });
      }, 4000);
      setMainBoxStateEnum(MainBoxStateEnum.FreeForm);
      setCameraStateEnum(CameraStateEnum.End);
    }
  }, [bGState.state]);

  return (
    <>
      <CameraTransition
        cameraStateEnum={cameraStateEnum}
        onStartEnd={() => {
          // console.log("Start trans done");
          setBGState({
            backgroundClass: "bg-BOTOC_DarkOrange",
            state: BGTransitionStateEnum.idle,
          });
        }}
        onNextEnd={() => {
          // console.log("Next done");
          setCameraStateEnum(CameraStateEnum.NextEnded);
          setBGState({
            backgroundClass: "bg-BOTOC_DarkOrange",
            state: BGTransitionStateEnum.idle,
          });
        }}
      />
      <MainBox
        mainBoxStateEnum={mainBoxStateEnum}
        onFaceTransitionEnd={() => {
          setMainBoxStateEnum(MainBoxStateEnum.Locked);
        }}
        nextFaceDelay={1000}
      />
    </>
  );
};

EntityComposer.propTypes = {
  bGState: PropTypes.object.isRequired,
  setBGState: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequiredm,
};

export default EntityComposer;
