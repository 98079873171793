import * as React from "react";
import CornerSVG from "../../images/corner.inline.svg";
import PlaceholderCanvas from "../three/PlaceholderCanvas";

import { gameStateAtom, GameStateEnum } from "../../recoil/gameState";
import { useRecoilValue } from "recoil";

const Placeholder = () => {
  const [showCanvas, setShowCanvas] = React.useState(null);
  const gameState = useRecoilValue(gameStateAtom);

  React.useEffect(() => {
    if (gameState > GameStateEnum.Selection) {
      setShowCanvas(true);
    } else {
      setShowCanvas(false);
    }
  }, []);

  return (
    <div className={`w-full h-full ${showCanvas === null && "hidden"}`}>
      {showCanvas ? (
        <div
          className="w-full h-full"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <PlaceholderCanvas />
        </div>
      ) : (
        <div className="w-full h-full mx-auto relative px-2 opacity-80">
          <h2
            style={{ transform: "translate(-50%, -50%)" }}
            className="absolute top-1/2 left-1/2 text-2xl"
          >
            Camera Safe Zone
          </h2>
          <div className="relative w-full h-full">
            <CornerSVG
              style={{ transform: "rotate(90deg)" }}
              className="block w-12 h-12 absolute top-0 left-0"
            />
            <CornerSVG
              style={{ transform: "rotate(90deg) scaleY(-1)" }}
              className="block w-12 h-12 absolute top-0 right-0"
            />
            <CornerSVG
              style={{ transform: "rotate(90deg) scaleX(-1)" }}
              className="block w-12 h-12 absolute bottom-0 left-0"
            />
            <CornerSVG
              style={{ transform: "rotate(90deg) scaleY(-1) scaleX(-1)" }}
              className="block w-12 h-12 absolute bottom-0 right-0"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Placeholder;
