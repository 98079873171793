import * as React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Spinner = ({ size = "1.3em" }) => {
  return (
    <span>
      <FontAwesomeIcon
        icon={faSpinner}
        className="spin"
        style={{ fontSize: size }}
      />
    </span>
  );
};

Spinner.propTypes = {
  size: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
export default Spinner;
