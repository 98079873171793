import { selector } from "recoil";

const SCORE_COUNT_LIMIT = 15;
// const limitTable = {
//   0: 10,
//   500: 20,
//   1000: 50,
//   2000: 100,
// };
const scoreCountLimitSelector = selector({
  key: "scoreCountLimitSelector",
  get: () => {
    // const userData = get(userAtom);
    let scoreCountLimit = SCORE_COUNT_LIMIT;
    // if (userData.viewer_count) {
    //   Object.keys(limitTable).forEach((key) => {
    //     if (userData.viewer_count >= parseInt(key)) {
    //       scoreCountLimit = limitTable[key];
    //     }
    //   });
    // }
    return scoreCountLimit;
  },
});

export { scoreCountLimitSelector };
