// import { AuthTypeEnum } from "../game/gameEnums.mjs";
import axios from "axios";

const ShuffleArray = (a) => {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
};

const RegExpEscape = (s) => {
  //Removed "-". Fine since we are not using []
  // eslint-disable-next-line no-useless-escape
  return s.replace(/[\/\\^$*+?.()|[\]{}]/g, "\\$&");
};

const SetInputFilter = (textbox, inputFilter, defualtValue) => {
  textbox.value = defualtValue;
  [
    "input",
    "keydown",
    "keyup",
    "mousedown",
    "mouseup",
    "select",
    "contextmenu",
    "drop",
  ].forEach(function (event) {
    textbox.addEventListener(event, function () {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (Object.prototype.hasOwnProperty.call(this, "oldValue")) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = "";
      }
    });
  });
};

const CalcVolume = (masterVolume, sub = 0) => {
  let volume = masterVolume - sub;
  return volume < 0 ? 0 : volume;
};

const DataLayerPush = (eventName, data = {}) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({ event: eventName, ...data });
  } else if (process.env.NODE_ENV === "production") {
    console.error("dataLayer undefined");
  }
};

// const GetAuthTypeString = (authType) => {
//   switch (authType) {
//     case AuthTypeEnum.Twitch:
//       return "Twitch";
//     case AuthTypeEnum.Youtube:
//       return "Youtube";
//     case AuthTypeEnum.TwitchChannelName:
//       return "Twitch Channel Name";
//     default:
//       console.warn("Failed to get auth type string");
//       return "Unknown";
//   }
// };

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const getTwitchChannelData = (token, client_id, channelName) => {
  return new Promise((reslove, reject) => {
    let url = "";
    if (typeof channelName === "string") {
      if (channelName.length > 0) {
        url = `https://api.twitch.tv/helix/users?login=${channelName}`;
      } else {
        return reject("channel name empty");
      }
    } else {
      url = "https://api.twitch.tv/helix/users";
    }

    axios({
      method: "get",
      url: url,
      headers: {
        "Client-Id": client_id,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        let data = res.data.data[0];
        reslove(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export {
  RegExpEscape,
  ShuffleArray,
  SetInputFilter,
  CalcVolume,
  DataLayerPush,
  getRandomInt,
  getTwitchChannelData,
};
