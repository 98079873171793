import * as React from "react";

import countdown from "../../audio/countdown.mp3";
import hover from "../../audio/hover.mp3";
import lost from "../../audio/lost.wav";
import start from "../../audio/start.mp3";
import transitionStart from "../../audio/transitionStart.mp3";
import transitionEnd from "../../audio/transitionEnd.mp3";
import transitionNext from "../../audio/transitionNext.mp3";
import win from "../../audio/win.mp3";
import zoomOut from "../../audio/zoomOut.mp3";
import roundStartSound from "../../audio/roundStart.mp3";

const audioFiles = [
  countdown,
  hover,
  lost,
  start,
  transitionStart,
  transitionEnd,
  transitionNext,
  win,
  zoomOut,
  roundStartSound,
];

const AudioPreLoader = () => {
  React.useEffect(() => {
    preload();
  }, []);

  const preload = () => {
    for (var i in audioFiles) {
      preloadAudio(audioFiles[i]);
    }
  };

  const preloadAudio = (url) => {
    var audio = new Audio();
    audio.src = url;
  };

  return <></>;
};

export default AudioPreLoader;
