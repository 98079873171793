import * as React from "react";
import PropTypes from "prop-types";
import BOTCDialog from "./BOTCDialog";

function ConfirmDialog({ onYes, onNo = () => null, onClose, show }) {
  return (
    <BOTCDialog
      onClose={onClose}
      show={show}
      title="Are You Sure?"
      divClass="bg-white text-black text-center border-black border-2"
    >
      <button
        className="bg-white px-5 mx-1 text-lg mt-3 border-2 hover:border-black border-gray-400"
        onClick={() => {
          onYes();
        }}
      >
        Yes
      </button>
      <button
        className="bg-white px-5 mx-1 text-lg border-2 hover:border-black border-gray-400"
        onClick={() => {
          onNo();
        }}
      >
        No
      </button>
    </BOTCDialog>
  );
}
ConfirmDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func,
  onClose: PropTypes.func,
};

export default ConfirmDialog;
