import React from "react";
import PropTypes from "prop-types";
import { getRandomInt } from "../utils/utils";

const BoxPattern = ({
  tileWidth = 80,
  tileHeight = 55,
  tick = 2000,
  ammount = { min: 10, max: 15 },
  opacity = { min: 0.1, max: 0.3 },
  tileClass,
  tileWrapper,
}) => {
  const [tiles, setTiles] = React.useState();
  const wrapper = React.useRef();
  const tileRef = React.useRef(tiles);
  tileRef.current = tiles;

  const createTile = () => {
    const tile = document.createElement("div");
    tile.classList.add("tile");
    tile.style.opacity = 0;

    const classes = tileClass?.trim();
    if (!classes || classes.length === 0) {
      return tile;
    }

    tile.classList.add(...classes.split(" "));
    return tile;
  };

  const createTiles = (quantity) => {
    const allTiles = Array.from(Array(quantity)).map((_, index) => {
      const tile = createTile(index);
      wrapper.current.appendChild(tile);
      return tile;
    });

    setTiles(allTiles);
  };

  React.useEffect(() => {
    createGrid();
  }, []);

  React.useEffect(() => {
    if (!tiles) {
      return;
    }
    updateTile(getRandomInt(ammount.min, ammount.max));
    const interval = setInterval(() => {
      updateTile(getRandomInt(ammount.min, ammount.max));
    }, tick);

    return () => {
      clearInterval(interval);
    };
  }, [tiles]);

  const updateTile = (ammount) => {
    //clear
    tileRef.current.map((tile) => {
      tile.style.opacity = 0;
    });
    //show
    Array.from(Array(ammount)).map(() => {
      const tile = tileRef.current[getRandomInt(0, tileRef.current.length - 1)];
      tile.style.opacity =
        getRandomInt(opacity.min * 10, opacity.max * 10) / 10;
    });
  };

  window.onresize = () => createGrid();

  const createGrid = () => {
    wrapper.current.innerHTML = "";
    let gridSize = {
      columns: Math.floor(
        wrapper.current.parentElement.offsetWidth / tileWidth
      ),
      rows: Math.floor(wrapper.current.parentElement.offsetHeight / tileHeight),
    };
    // wrapper.current.body.width
    wrapper.current.style.setProperty("--columns", gridSize.columns);
    wrapper.current.style.setProperty("--rows", gridSize.rows);

    createTiles(gridSize.columns * gridSize.rows);
  };

  return (
    <div ref={wrapper} className={`tilesWrapper ${tileWrapper}`}>
      BGPattern
    </div>
  );
};

BoxPattern.propTypes = {
  tileWidth: PropTypes.number,
  tileHeight: PropTypes.number.number,
  tick: PropTypes.number,
  ammount: PropTypes.object,
  opacity: PropTypes.object,
  tileClass: PropTypes.string,
  tileWrapper: PropTypes.string,
};

export default BoxPattern;
