import * as React from "react";
import PropTypes from "prop-types";
import NextRoundBtn from "./NextRoundBtn";
import ChannelName from "../utils/ChannelName";
import { useRecoilValue, useRecoilState } from "recoil";
import { roundScoresAtom, gameScoresAtom } from "../../recoil/roundState";
import { scoreCountLimitSelector, settingsAtom } from "../../recoil/settings";
import OptionTile from "./OptionTile";
import { dataLayerPush } from "../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

import win from "../../audio/win.mp3";
import lost from "../../audio/lost.wav";
import GuessCounts from "./GuessCounts";
import { getMinorityBonus, scoreTable } from "./scoreUtils";

function RoundResults({ wordInPlay, currentRound, max_rounds }) {
  const settings = useRecoilValue(settingsAtom);
  const roundScores = useRecoilValue(roundScoresAtom);
  const scoreCountLimit = useRecoilValue(scoreCountLimitSelector);
  const [gameScores, setGameScores] = useRecoilState(gameScoresAtom);
  const [correctPercentage, setCorrectPercentage] = React.useState(0);
  const [minorityBonusIndicator, setMinorityBonusIndicator] =
    React.useState(false);

  const [hasWon, setHasWon] = React.useState(false);

  const [correctList, setCorrectList] = React.useState([]);
  const [incorrectList_filtered, setIncorrectList_filtered] = React.useState(
    []
  );

  React.useEffect(() => {
    //get correct percentage
    let newCorrectPercentage = 0;
    if (roundScores.correctList.length) {
      newCorrectPercentage =
        roundScores.correctList.length /
        (roundScores.correctList.length + roundScores.incorrectList.length);
      setCorrectPercentage(newCorrectPercentage);
    }
    //deep copy chat Leaderboard
    let newChatLeaderboard = structuredClone(gameScores.chatLeaderboard);

    //if they get it wrong, subtract some points from their exiting scores
    const newIncorrectList_filtered = [];
    roundScores.incorrectList.forEach((incorrectObj) => {
      newChatLeaderboard.forEach((entry) => {
        if (entry.display_name === incorrectObj.display_name) {
          const newScore = entry.score - incorrectObj.scorePenalty;
          if (entry.score > 1) {
            newIncorrectList_filtered.push(incorrectObj);
          }
          entry.score = newScore < 0 ? 0 : newScore;
        }
      });
    });
    setIncorrectList_filtered(newIncorrectList_filtered);

    //Update scores for people  who got it right
    const newCorrectList = [];
    roundScores.correctList.forEach((correctObj) => {
      let contains = false;
      const newCorrectObj = structuredClone(correctObj);

      //Add a bonus score for if the correct ammount of people are less than the incorrect ammount
      if (newCorrectPercentage < scoreTable.minorityBonusThreshold) {
        newCorrectObj.score += getMinorityBonus(newCorrectPercentage);
        setMinorityBonusIndicator(true);
      }
      newChatLeaderboard.forEach((entry) => {
        if (entry.display_name === correctObj.display_name) {
          contains = true;
          entry.score += newCorrectObj.score;
        }
      });
      //Add new entry if chatter has no score
      if (!contains) {
        newChatLeaderboard.push({
          ...newCorrectObj,
        });
      }
      //add it to hook
      newCorrectList.push(newCorrectObj);
    });
    setCorrectList(newCorrectList);

    //sort
    newChatLeaderboard = newChatLeaderboard.sort((e1, e2) => {
      if (e1.score > e2.score) {
        return -1;
      } else {
        return 1;
      }
    });
    //Get user's placement
    const userPlacement =
      newChatLeaderboard.findIndex((entry) => entry.isUser) + 1;
    //Calc total score
    let newTotalScore = 0;
    let ammountToAdd =
      newChatLeaderboard.length < scoreCountLimit
        ? newChatLeaderboard.length
        : scoreCountLimit;
    for (let ctr = 0; ctr < ammountToAdd; ctr++) {
      newTotalScore += newChatLeaderboard[ctr].score;
    }

    //Update gamescores object
    const updatedGameScores = {
      ...gameScores,
      userPlacement: userPlacement,
      userGameScore: roundScores.userScore + gameScores.userGameScore,
      chatLeaderboard: newChatLeaderboard,
      totalScore: newTotalScore,
      totalCorrectGuesses:
        roundScores.correctList.length + gameScores.totalCorrectGuesses,
      totalIncorrectGuesses:
        roundScores.incorrectList.length + gameScores.totalIncorrectGuesses,
      totalGuesses:
        roundScores.correctList.length +
        roundScores.incorrectList.length +
        gameScores.totalGuesses,
    };
    //check round win condition
    const hasWon =
      roundScores.correctList.length + (roundScores.userScore ? 1 : 0);

    setGameScores(updatedGameScores);
    setHasWon(hasWon);

    if (hasWon) {
      const sfx = new Audio(win);
      sfx.volume = settings.volume;
      sfx.play();
    } else {
      const sfx = new Audio(lost);
      sfx.volume = settings.volume;
      sfx.play();
    }

    //push to data layer
    let dataPush = {
      word_id: wordInPlay.id,
      word: wordInPlay.word,
      command_string: wordInPlay.commandString,
      asset_data: wordInPlay.chosenAsset.data,
      total_guesses:
        roundScores.correctList.length + roundScores.incorrectList.length + 1,
      total_correct: getCorrectAmmount(),
      total_incorrect: getIncorrectAmmount(),
    };

    wordInPlay.tileOptions.map((option, index) => {
      dataPush[`word_option_${index}`] = option.word;
      dataPush[`word_option_count_${index}`] =
        roundScores.optionsGuessCount[option.commandString];
    });
    dataLayerPush("round_results", dataPush);
  }, []);

  const listCorrectList = () => {
    return correctList.map((correctObj, index) => (
      <div key={`${correctObj.display_name}-${index}`}>
        {correctObj.isUser ? (
          <div>
            <span className="mr-1">{index + 1}. </span>
            <ChannelName size={"md"} minimal={true} imgSize={25} />
            <span className="ml-2 font-bold text-yellow-600">
              : {roundScores.userScore}
            </span>
          </div>
        ) : (
          <div>
            <span className="mr-1">{index + 1}. </span>
            <span className="font-bold" style={{ color: correctObj.colour }}>
              {correctObj.display_name}
            </span>
            :
            <span className="ml-2 font-bold text-yellow-600">
              {correctObj.score}
            </span>
          </div>
        )}
      </div>
    ));
  };

  //Only list users who were incorrect and got a penalty to their score
  const listIncorrectList_filtered = () => {
    return incorrectList_filtered.map((incorrectObj, index) => (
      <div key={`${incorrectObj.display_name}-${index}`}>
        {incorrectObj.isUser ? (
          <div>
            <span className="mr-1">{index + 1}. </span>
            <ChannelName size={"md"} minimal={true} imgSize={25} />:
            <span className="ml-2 font-bold text-red-600 text-lg">
              -{incorrectObj.scorePenalty}
            </span>
          </div>
        ) : (
          <div>
            <span className="mr-1">{index + 1}. </span>
            <span className="font-bold" style={{ color: incorrectObj.colour }}>
              {incorrectObj.display_name}:
            </span>

            <span className="ml-2 font-bold text-red-600 text-lg">
              -{incorrectObj.scorePenalty}
            </span>
          </div>
        )}
      </div>
    ));
  };

  const getCorrectAmmount = () => {
    return roundScores.correctList.length;
  };

  const getIncorrectAmmount = () => {
    return roundScores.incorrectList.length;
  };

  const assetMetaData = () => {
    return (
      <>
        {wordInPlay.chosenAsset.author && (
          <span>
            {capitalizeFirstLetter(wordInPlay.chosenAsset.type)} By:{" "}
            {wordInPlay.chosenAsset.author}
          </span>
        )}
        {wordInPlay.chosenAsset.source && (
          <span className="text-white">
            <a
              className="hover:underline font-bold"
              href={wordInPlay.chosenAsset.source}
              aria-label={`${wordInPlay.chosenAsset.type} source`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="mr-1">Source</span>
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="1x" />
            </a>
          </span>
        )}
      </>
    );
  };

  return (
    <div className=" w-full lg:w-11/12 xl:w-5/6 mx-auto text-center h-full opacity-div--spawn">
      <div className="flex flex-col h-full justify-between gap-y-3 items-center ">
        {/* Answer Data */}
        <h1 className="text-5xl font-bold bg-BOTC_DarkBlue border border-black rounded-md w-80 py-4 relative -top-4">
          Answer
        </h1>

        <div className="w-80 h-28 mx-auto flex-shrink-0">
          <OptionTile
            word={wordInPlay.word}
            commandString={wordInPlay.commandString}
            translate={wordInPlay.translate ? wordInPlay.translate : "yes"}
          />
        </div>

        <div className="w-full flex flex-col justify-center items-center text-BOTC_Black font-bold text-lg">
          {assetMetaData()}
        </div>

        <hr className="h-1 w-full border-t-2 border-BOTC_DarkBlue border-opacity-80" />

        {/* Stats */}
        <div className="flex flex-col lg:flex-row w-full h-0 gap-x-2 gap-y-1 flex-grow flex-shrink">
          {/* Left Stats */}
          <div className="w-full lg:w-8/12 bg-BOTC_DarkBlue border border-black rounded-md h-1/2 md:h-full flex-shrink flex flex-col justify-center items-center p-4 gap-y-1">
            <span
              className={`text-2xl font-bold ${
                hasWon ? "text-BOTC_Orange" : "text-red-500"
              }`}
            >
              Correct: {getCorrectAmmount()}
            </span>
            <span
              className={`block font-bold text-lg ${
                hasWon ? "text-BOTC_Orange" : "text-red-500"
              }`}
            >
              Correct Percentage: {Math.floor(correctPercentage * 100)}%
            </span>

            {minorityBonusIndicator && (
              <span className="text-xl font-bold text-BOTC_Orange">
                Bonus Applied!
              </span>
            )}

            {/* Correct Leaderboard */}
            <div className="px-4 overflow-y-scroll h-full text-left w-full xl:w-5/6">
              {listCorrectList()}
            </div>
          </div>

          {/* Right Stats */}
          <div className="w-full h-1/2 md:h-full flex-grow flex flex-col gap-y-2 relative">
            {/* Display Counts */}
            <div className="h-1/2 w-full overflow-y-scroll px-4 bg-BOTC_DarkBlue border border-black rounded-md relative">
              <hr className="absolute right-1/2 h-full w-1 border-l border-opacity-40 border-BOTC_LightBlue" />

              <div className="flex h-full flex-row flex-grow flex-wrap content-center justify-center items-center overflow-y-scroll gap-y-5">
                <GuessCounts wordInPlay={wordInPlay} />
              </div>
            </div>
            {/* Incorrect List */}
            <div className="bg-BOTC_DarkBlue border border-black rounded-md w-full xl:w-8/12 h-1/2 p-4 gap-2 flex flex-col justify-center items-center">
              <span className="text-2xl font-bold text-red-500">
                Incorrect: {roundScores.incorrectList.length}
              </span>
              <span className="font-bold text-red-500">
                Penalties Applied: {incorrectList_filtered.length}
              </span>
              <div className="px-4 overflow-y-scroll h-full text-left w-full xl:w-4/6">
                {listIncorrectList_filtered()}
              </div>
            </div>
          </div>
        </div>
        {/* Next Button */}
        <div className="flex justify-end items-end h-20 py-8">
          <NextRoundBtn max_rounds={max_rounds} currentRound={currentRound} />
        </div>
      </div>
    </div>
  );
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

RoundResults.propTypes = {
  wordInPlay: PropTypes.object.isRequired,
  currentRound: PropTypes.number.isRequired,
  max_rounds: PropTypes.number.isRequired,
};

export default RoundResults;
