import * as React from "react";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { gameStateAtom, GameStateEnum } from "../../recoil/gameState";
import useGameStateSet from "../utils/useGameStateSet";
import { allWordsSelector, activeCatAtom } from "../../recoil/activeCat";
import { settingsAtom } from "../../recoil/settings";
import { chatAtom } from "../../recoil/chat";
import { userGuessAtom } from "../../recoil/userGuess";
import { shuffleArray, dataLayerPush } from "../utils/utils";
import { errorStateAtom, ErrorLevelEnum } from "../../recoil/errorState";
import { skipShuffle, timerOverride } from "../../api";
import { setupAsset, setupWord, pickWord } from "./playPanelUtils";
import { DailyCategory } from "../../game/daily_category";

import Guess from "./Guess";
import RoundResults from "./RoundResults";
import LoadingLogo from "./LoadingLogo";

function PlayPanel() {
  const [gameStateRecoil] = useRecoilState(gameStateAtom);
  const gameStateSet = useGameStateSet();
  const [userGuess, setUserGuess] = useRecoilState(userGuessAtom);
  const [chat, setChat] = useRecoilState(chatAtom);
  const settings = useRecoilValue(settingsAtom);
  const wordList = useRecoilValue(allWordsSelector);
  const activeCategory = useRecoilValue(activeCatAtom);
  const setErrorState = useSetRecoilState(errorStateAtom);

  const [gameSettings, setGameSettings] = React.useState({
    max_rounds: 0,
    timer_secs: 0,
  });
  const [wordInPlay, setWordInPlay] = React.useState();
  const [gameState, setGameState_UNSAFE] = React.useState(); //Note: do not use setGameState_UNSAFE, use gameStateSet
  const [randomziedWordList, setRandomziedWordList] = React.useState(null);
  const [pickingIndex, setPickingIndex] = React.useState(0);
  const [assetData, setAssetData] = React.useState({
    data: null,
    contentType: null,
  });
  const [loadingAsset, setLoadingAsset] = React.useState(false);
  const [currentRound, setCurrentRound] = React.useState(0);

  React.useEffect(() => {
    gameStart();
  }, []);

  // makes the first render effect complete before the gameState effect
  React.useEffect(() => {
    if (gameStateRecoil !== gameState) {
      setGameState_UNSAFE(gameStateRecoil);
    }
  }, [gameStateRecoil]);

  React.useEffect(() => {
    if (gameState === GameStateEnum.RoundStart && randomziedWordList) {
      if (currentRound < gameSettings.max_rounds) {
        setupRound();
      }
    }
  }, [gameState]);

  const randomizeWordList = () => {
    if (!skipShuffle) {
      setRandomziedWordList(shuffleArray(JSON.parse(JSON.stringify(wordList))));
    } else {
      setRandomziedWordList(JSON.parse(JSON.stringify(wordList)));
    }
  };

  const gameStart = () => {
    if (activeCategory instanceof DailyCategory) {
      activeCategory.saveLastPlayed();
    }
    setGameSettings({
      max_rounds:
        activeCategory instanceof DailyCategory
          ? activeCategory.rounds
          : settings.max_rounds,
      timer_secs:
        activeCategory instanceof DailyCategory
          ? activeCategory.timer_secs
          : settings.timer_secs,
    });
    //reset user's score
    setUserGuess({ ...userGuess, guessCommandString: "" });
    randomizeWordList();
  };

  const setupRound = () => {
    //increase round counter
    setCurrentRound(currentRound + 1);
    //reset chat message
    setChat({ ...chat, message: null });
    //pick next word
    let { wordPicked, indexPicked, wordSeen } = pickWord(
      pickingIndex,
      randomziedWordList,
      activeCategory.id
    );
    //if picking index  goes over, randomized wordlist and reset index
    if (indexPicked + 1 > randomziedWordList.length - 1) {
      randomizeWordList();
      setPickingIndex(0);
    } else {
      setPickingIndex(indexPicked + 1);
    }
    //setup wordInPlay
    const newWordInPlay = setupWord(
      activeCategory.id,
      wordPicked,
      randomziedWordList,
      settings.commandPrefix,
      settings.options_limt
    );
    newWordInPlay.wordSeen = wordSeen;
    //revoke preivous asset's object url, if exists
    if (
      assetData.contentType === "audio/mpeg" ||
      assetData.contentType === "image/webp"
    ) {
      URL.revokeObjectURL(assetData.data);
    }
    //setup chosen asset for Guess.js
    setLoadingAsset(true);
    setupAsset(newWordInPlay)
      .then((res) => {
        setAssetData(res);
        setLoadingAsset(false);
      })
      .catch((err) => {
        setErrorState({
          show: true,
          message: `Failed to fetch asset. ${err}`,
          errorLevelEnum: ErrorLevelEnum.FATAL,
        });
        gameStateSet(GameStateEnum.Selection);
      });

    //set word in play
    if (process.env.NODE_ENV !== "production") {
      console.log("WordInPlay");
      console.log(newWordInPlay);
    }
    setWordInPlay(newWordInPlay);
    //set wordInPlay for user guess window
    setUserGuess({
      ...userGuess,
      guessCommandString: "",
      wordInPlay: newWordInPlay,
    });
    //datalayer
    let dataPush = {
      word_id: newWordInPlay.id,
      word: newWordInPlay.word,
      command_string: newWordInPlay.commandString,
      asset_data: newWordInPlay.chosenAsset.data,
    };
    newWordInPlay.tileOptions.map((option, index) => {
      dataPush[`word_option_${index}`] = option.word;
      dataPush[`commandString_option_${index}`] = option.commandString;
    });
    dataLayerPush("round_start", dataPush);
  };

  const startRound = () => {
    gameStateSet(GameStateEnum.Playing);
  };

  return (
    <div className="w-full h-full">
      {gameState === GameStateEnum.RoundStart && (
        <div className="h-full w-full flex flex-col justify-center items-center opacity-div--spawn">
          <LoadingLogo
            round={currentRound + 1}
            maxRounds={gameSettings.max_rounds}
            delay={2500}
            minTimeMills={3000}
            isReady={!loadingAsset}
            onComplete={() => {
              startRound();
            }}
            roundIncrDelay={1000}
          />
        </div>
      )}

      {gameState === GameStateEnum.Playing && (
        <Guess
          wordInPlay={wordInPlay}
          assetData={assetData}
          currentRound={currentRound}
          max_rounds={gameSettings.max_rounds}
          timer_secs={timerOverride ? timerOverride : gameSettings.timer_secs}
          onEnd={(skipped) => {
            //go back a round if user skipped
            if (skipped && wordInPlay.wordSeen) {
              setCurrentRound(currentRound - 1);
            }
            gameStateSet(GameStateEnum.Results);
          }}
        />
      )}
      {gameState === GameStateEnum.Results && (
        <RoundResults
          max_rounds={gameSettings.max_rounds}
          wordInPlay={wordInPlay}
          currentRound={currentRound}
        />
      )}
    </div>
  );
}

export default PlayPanel;
