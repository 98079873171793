import { atom } from "recoil";
import BGTransitionStateEnum from "./BGTransitionStateEnum";

const bGStateAtom = atom({
  key: "bGStateAtom",
  default: {
    state: BGTransitionStateEnum.idle,
    backgroundClass: "bg-BOTC_DarkBlue",
  },
});

export default bGStateAtom;
