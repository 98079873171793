import * as React from "react";
import { Canvas } from "@react-three/fiber";
import EntityComposer from "./EntityComposer";

//https://codesandbox.io/s/jflps?file=/src/App.js:305-319
//https://vanruesc.github.io/postprocessing/public/docs/variable/index.html
//https://docs.pmnd.rs/react-postprocessing/effects/bloom

import { bGStateAtom } from "../../recoil/BGState";
import { useRecoilState, useRecoilValue } from "recoil";
import useWindowDimensions from "../utils/useWindowDimensions";
import { settingsAtom } from "../../recoil/settings";

import PinWheel from "./PinWheel";

const BGCanvas = () => {
  const canvasRef = React.useRef();
  const [bGState, setBGState] = useRecoilState(bGStateAtom);
  const settings = useRecoilValue(settingsAtom);
  const { height: window_height } = useWindowDimensions();

  return (
    <div
      className={`absolute w-full ${
        window_height < 675 ? "h-170" : "h-screen"
      } z-bg`}
    >
      <PinWheel bgState={bGState} />
      <Canvas ref={canvasRef}>
        <ambientLight />
        <EntityComposer
          bGState={bGState}
          setBGState={setBGState}
          settings={settings}
        />
      </Canvas>
    </div>
  );
};

export default BGCanvas;
