import React from "react";
import PropTypes from "prop-types";
import PinWheelSVG from "../../images/pin_wheel.inline.svg";
import { BGTransitionStateEnum } from "../../recoil/BGState";
import { gameStateAtom, GameStateEnum } from "../../recoil/gameState";
import { useRecoilValue } from "recoil";

const PinWheel = ({ bgState }) => {
  const [classes, setClasses] = React.useState("");
  const gameState = useRecoilValue(gameStateAtom);
  const [show, setShow] = React.useState(false);

  if (typeof window !== "undefined") {
    window.addEventListener("fullyLoaded", () => {
      setShow(true);
    });
  }

  React.useEffect(() => {
    if (window.hasFullyLoaded) {
      setShow(true);
    }
  }, []);

  React.useEffect(() => {
    switch (bgState.state) {
      case BGTransitionStateEnum.Start:
        setClasses("fade-pin-wheel--fast opacity-0");
        break;
      case BGTransitionStateEnum.NextRound:
        setClasses("opacity-0");
        break;

      default:
        if (gameState > GameStateEnum.Selection) {
          setClasses("opacity-0");
        } else {
          setClasses("fade-pin-wheel--fast opacity-100");
        }

        break;
    }
  }, [bgState, gameState]);

  return (
    show && (
      <PinWheelSVG
        className={`rotate-pin-wheel  ${classes}`}
        style={{
          width: "300vw",
          height: "300vh",
          position: "fixed",
        }}
      />
    )
  );
};

PinWheel.propTypes = {
  bgState: PropTypes.string.isRequired,
};

export default PinWheel;
