import * as React from "react";

import { client_id_twitch, debugChannel } from "../../api";
import tmi from "tmi.js";
import DOMPurify from "dompurify";

import { useRecoilState, useRecoilValue } from "recoil";
import { chatAtom } from "../../recoil/chat";
import { gameStateAtom, GameStateEnum } from "../../recoil/gameState";
import useGameStateSet from "../utils/useGameStateSet";
import { userAtom, AuthTypeEnum } from "../../recoil/user";
import {
  errorStateAtom,
  ErrorLevelEnum,
  ErrorTypeEnum,
} from "../../recoil/errorState";

//https://github.com/tmijs/docs/blob/gh-pages/_posts/v1.4.2

function useChat() {
  const [chat, setChat] = useRecoilState(chatAtom);
  const gameState = useRecoilValue(gameStateAtom);
  const gameStateSet = useGameStateSet();
  const user = useRecoilValue(userAtom);
  const [msgState, setMsgState] = React.useState(null);
  const [errorState, setErrorState] = useRecoilState(errorStateAtom);
  const errorStateRef = React.useRef();
  errorStateRef.current = errorState;

  React.useEffect(() => {
    if (gameState === GameStateEnum.Authed) {
      if (
        user.authType === AuthTypeEnum.Twitch ||
        user.authType === AuthTypeEnum.TwitchChannelName
      ) {
        connectTwtichChat(
          debugChannel.length > 0 ? debugChannel : user.display_name
        );
      }
    }
  }, [gameState, user]);

  React.useEffect(() => {
    if (msgState) {
      setChat({
        ...chat,
        message: {
          msg: msgState.msg,
          display_name: msgState.display_name,
          colour: msgState.colour,
        },
      });
    }
  }, [msgState]);

  const connectTwtichChat = (channelName) => {
    console.log("Ready for connection to chat");

    const tmi_client = new tmi.Client({
      options: {
        debug: false,
        skipUpdatingEmotesets: true,
        skipMembership: true,
      },
      client_id: client_id_twitch,
    });

    tmi_client.connect().catch((err) => {
      setErrorState({
        show: true,
        message: `Could not connect to Twitch servers ${err}`,
        errorLevelEnum: ErrorLevelEnum.FATAL,
      });
    });

    tmi_client.on("connected", () => {
      console.log("Twitch Chat Servers Connected");
      tmi_client
        .join(channelName)
        .then(() => {
          console.log(`Channel Joined to ${channelName}`);
          setChat({
            chatConnected: true,
            interface: tmi,
            authType: AuthTypeEnum.Twitch,
          });

          if (
            errorStateRef.current.show &&
            errorStateRef.current.errorTypeEnum === ErrorTypeEnum.chatReconnect
          ) {
            setErrorState({
              show: false,
            });
            if (gameState === GameStateEnum.Authed) {
              gameStateSet(GameStateEnum.ChatConnected);
            }
          } else {
            gameStateSet(GameStateEnum.ChatConnected);
          }
        })
        .catch((err) => {
          setErrorState({
            show: true,
            message: `Could not connect to ${user.display_name} channel. ${err}`,
            errorLevelEnum: ErrorLevelEnum.FATAL,
          });
        });
    });

    tmi_client.on("disconnected", () => {
      setErrorState({
        show: true,
        message: "Chat disconnected trying to reconnect...",
        errorLevelEnum: ErrorLevelEnum.INFO,
        errorTypeEnum: ErrorTypeEnum.chatReconnect,
      });
      setChat({ chatConnected: false, ...chat });
    });

    tmi_client.on("message", (channel, tags, message) => {
      if (tags["message-type"] == "chat") {
        let cleanMsg = DOMPurify.sanitize(message);
        setMsgState({
          msg: cleanMsg,
          display_name: tags["display-name"],
          colour: tags.color,
        });
      }
    });
  };

  return null;
}

export default useChat;
