import * as React from "react";
import PropTypes from "prop-types";

import { useRecoilValue } from "recoil";
import { gameScoresAtom } from "../../recoil/roundState";
import { scoreCountLimitSelector } from "../../recoil/settings";
import ChannelName from "./ChannelName";

const ChatLeaderboard = ({ showAll = false }) => {
  const gameScores = useRecoilValue(gameScoresAtom);
  const scoreCountLimit = useRecoilValue(scoreCountLimitSelector);

  const displayScoreBoard = () => {
    const entryJSX = (entry, index, isUser) => {
      return (
        <div translate="no" key={`${entry.display_name}-${index}`}>
          <span translate="no">{index + 1}. </span>
          {isUser ? (
            <ChannelName size={"md"} minimal={true} imgSize={25} />
          ) : (
            <span
              translate="no"
              style={{ color: entry.colour }}
              className="font-bold"
            >
              {entry.display_name}
            </span>
          )}
          :
          <span className="font-bold text-yellow-600" translate="no">
            {" "}
            {entry.score}
          </span>
        </div>
      );
    };
    if (!showAll) {
      let ammount =
        gameScores.chatLeaderboard.length < scoreCountLimit
          ? gameScores.chatLeaderboard.length
          : scoreCountLimit;
      let entries = [];
      for (var ctr = 0; ctr < ammount; ctr++) {
        entries.push(
          entryJSX(
            gameScores.chatLeaderboard[ctr],
            ctr,
            gameScores.chatLeaderboard[ctr].isUser
          )
        );
      }
      return entries;
    } else {
      return gameScores.chatLeaderboard.map((entry, index) => {
        return entryJSX(entry, index, entry.isUser);
      });
    }
  };

  return displayScoreBoard();
};

ChatLeaderboard.propTypes = {
  showAll: PropTypes.bool,
};

export default ChatLeaderboard;
