import * as React from "react";
import PropTypes from "prop-types";

import { useRecoilValue } from "recoil";
import { settingsAtom } from "../../../recoil/settings";

import { setupAsset, setupWord } from "../../playPanel/playPanelUtils";

import Guess from "../../playPanel/Guess";
import RoundResults from "../../playPanel/RoundResults";
import ListTagScores from "./ListTagScores";
import AssetEditor from "./AssetEditor";
import { timerOverride } from "../../../api";

const DebugWordView = ({ wordObj, wordList, assetIndex, categoryId }) => {
  const settings = useRecoilValue(settingsAtom);
  const [wordInPlay, setWordInPlay] = React.useState();
  const [assetData, setAssetData] = React.useState({
    data: null,
    contentType: null,
  });
  const [loadingAsset, setLoadingAsset] = React.useState(false);
  const [errorInfo, setErrorInfo] = React.useState({
    hasError: false,
    message: "",
  });
  const [onResults, setOnResults] = React.useState(false);

  React.useEffect(() => {
    let newWordInPlay = setupWord(
      -1,
      structuredClone(wordObj),
      wordList,
      settings.commandPrefix,
      settings.options_limt,
      assetIndex
    );
    setLoadingAsset(false);
    setWordInPlay(newWordInPlay);
    // console.log(newWordInPlay);
    setupAsset(newWordInPlay)
      .then((res) => {
        setLoadingAsset(false);
        setAssetData(res);
      })
      .catch((err) => {
        setLoadingAsset(false);
        setErrorInfo({
          hasError: true,
          message: `Word: "${wordObj.word}" ${err}`,
        });
      });
  }, []);

  return (
    <div className="w-full h-full flex ">
      <div className="my-3 bg-BOTOC_DarkOrange p-2 h-250 w-8/12">
        {!loadingAsset && wordInPlay ? (
          <>
            {!errorInfo.hasError ? (
              <div className="text-white h-full w-full relative">
                <div className="h-7">
                  <div className="text-black ">
                    <span className="font-bold text-xl">
                      Word: {wordInPlay.word}
                    </span>
                    {wordInPlay.unlockAt && (
                      <span className="ml-3">
                        Unlock At: {wordInPlay.unlockAt.toUTCString()} UTC
                      </span>
                    )}
                  </div>
                  <div className="ml-2 text-sm font-bold right-0 top-0 absolute">
                    <span>Data: {wordInPlay.chosenAsset.data}</span>
                    {wordInPlay.chosenAsset.source && (
                      <a
                        className="text-black hover:underline ml-2"
                        href={wordInPlay.chosenAsset.source}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Source
                      </a>
                    )}
                  </div>
                </div>

                {!onResults ? (
                  <Guess
                    max_rounds={settings.max_rounds}
                    timer_secs={
                      timerOverride ? timerOverride : settings.timer_secs
                    }
                    currentRound={0}
                    wordInPlay={wordInPlay}
                    assetData={assetData}
                    onEnd={() => {
                      setOnResults(true);
                    }}
                    debug={true}
                  />
                ) : (
                  <RoundResults wordInPlay={wordInPlay} currentRound={0} />
                )}
              </div>
            ) : (
              <h1>{errorInfo.message}</h1>
            )}
          </>
        ) : (
          <p>Loading {wordObj.word}...</p>
        )}
      </div>
      <div className="inline h-200 w-4/12">
        {wordInPlay && (
          <>
            <AssetEditor wordID={wordInPlay.id} categoryID={categoryId} />
            <ListTagScores assetobj={wordInPlay.chosenAsset} limit={6} />
          </>
        )}
      </div>
    </div>
  );
};

DebugWordView.propTypes = {
  wordObj: PropTypes.object.isRequired,
  wordList: PropTypes.array.isRequired,
  assetIndex: PropTypes.number.isRequired,
  categoryId: PropTypes.number.isRequired,
};

export default DebugWordView;
