import * as React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { client_id_twitch } from "../../api";

import DTCSlider from "../utils/DTCSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { useRecoilState, useRecoilValue } from "recoil";
import { userAtom, AuthTypeEnum } from "../../recoil/user";
import { settingsAtom } from "../../recoil/settings";
import { gameStateAtom, GameStateEnum } from "../../recoil/gameState";
import { dataLayerPush } from "../utils/utils";
import CheckBox from "../utils/Checkbox";
import EndBtn from "../playPanel/EndBtn";
import NumberInput from "../utils/NumberInput";

const Settings = ({ setPanel }) => {
  const user = useRecoilValue(userAtom);
  const gameState = useRecoilValue(gameStateAtom);
  const [settingsState, setSettingsState] = useRecoilState(settingsAtom);
  const [defualts, setDefualts] = React.useState(null);

  //init
  React.useEffect(() => {
    let defualts = {
      alwaysSkipTutorial: settingsState.alwaysSkipTutorial,
      volume: settingsState.volume,
      raw_volume: settingsState.raw_volume,
      stream_delay_secs_end: settingsState.stream_delay_secs_end,
    };
    setDefualts(defualts);
  }, []);

  const OnBack = () => {
    //save
    let saveObj = {
      alwaysSkipTutorial: settingsState.alwaysSkipTutorial,
      volume: settingsState.volume,
      raw_volume: settingsState.raw_volume,
      stream_delay_secs_end: settingsState.stream_delay_secs_end,
    };
    localStorage.setItem("Settings_Save", JSON.stringify(saveObj));
    dataLayerPush("settings_save", {
      settings_volume: saveObj.raw_volume,
      settings_timer_set: saveObj.timer_secs,
      settings_always_skip_tutorial: saveObj.alwaysSkipTutorial,
      settings_rounds: saveObj.max_rounds,
    });
    setPanel("MainMenu");
  };

  const Reset = () => {
    localStorage.clear();

    axios({
      method: "post",
      url: `https://id.twitch.tv/oauth2/revoke?client_id=${client_id_twitch}&token=${user.access_token}`,
    })
      .then(() => {
        location.reload();
      })
      .catch(() => {
        location.reload();
      });
  };

  return (
    <div className="px-3 h-full overflow-y-scroll">
      <button onClick={OnBack} className="py-2">
        <div className="border text-xl px-4 py-2 hover:bg-DTC_LightBlue hover:bg-white hover:text-black transition-colors duration-300">
          <FontAwesomeIcon icon={faArrowLeft} />
          <span className="ml-2">Save</span>
        </div>
      </button>{" "}
      <h3 className="font-bold mt-1 text-4xl text-center self-center align-middle">
        Settings
      </h3>
      {defualts && (
        <div className="mt-3 p-4 sm:p-8">
          <div className="my-3">
            <CheckBox
              label={"Always Skip Tutorial"}
              defualtValue={defualts.alwaysSkipTutorial}
              onChange={(value) => {
                setSettingsState({
                  ...settingsState,
                  skipTutorial: value,
                  alwaysSkipTutorial: value,
                });
              }}
            />
          </div>
          <div className="my-2">
            <span className="select-none mr-5" htmlFor="timerInput">
              Stream Delay Timer:
            </span>
            <NumberInput
              minValue={5}
              defualtValue={defualts.stream_delay_secs_end}
              onChange={(value) => {
                setSettingsState({
                  ...settingsState,
                  stream_delay_secs_end: value,
                });
              }}
            />
          </div>

          <div className="my-2">
            <span className="select-none">Volume:</span>
            <DTCSlider
              ariaLabel="Volume Slider"
              defaultValue={defualts.raw_volume * 100}
              onChange={(v) => {
                let volume = Math.floor(Math.log10(v) * 50) / 100;
                if (volume === -Infinity) {
                  volume = 0;
                }
                setSettingsState({
                  ...settingsState,
                  volume: volume,
                  raw_volume: v / 100,
                });
              }}
            />
          </div>
          {gameState > GameStateEnum.Tutorial && (
            <div className="mt-12 flex items-center justify-center">
              <EndBtn useText={true} />
            </div>
          )}
          {gameState === GameStateEnum.Selection && (
            <div className="mt-12 flex flex-col gap-4 items-center justify-center">
              <div>
                <button label="Reset Game Settings" onClick={Reset}>
                  <span className="p-2 text-red-400">Clear Save</span>
                </button>
              </div>
              {user.authType === AuthTypeEnum.Twitch && (
                <div>
                  <a
                    href="https://www.twitch.tv/settings/connections"
                    label="Disconnect"
                    aria-label="Disconnect"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="p-2 text-red-400">Disconnect</span>
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

Settings.propTypes = {
  setPanel: PropTypes.func.isRequired,
};

export default Settings;
