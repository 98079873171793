import * as React from "react";
import PropTypes from "prop-types";

import LogoSVG from "../../images/logo.svg";

const Logo = ({ size = 100 }) => {
  return (
    <img
      className="mx-auto select-none"
      alt="Battle Of The Chat"
      src={LogoSVG}
      width={size}
    />
  );
};

Logo.propTypes = {
  size: PropTypes.number,
};

export default Logo;
