import * as React from "react";

import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { GameStateEnum } from "../recoil/gameState";
import { settingsAtom } from "../recoil/settings";
import { scoreCountLimitSelector } from "../recoil/settings";
import { guessWindowAtom } from "../recoil/userGuess";

import ChannelName from "./utils/ChannelName";

import useGameStateSet from "./utils/useGameStateSet";
import { Transition } from "@headlessui/react";
import { dataLayerPush } from "./utils/utils";

const Tutorial = () => {
  const gameStateSet = useGameStateSet();
  const scoreCoutLimit = useRecoilValue(scoreCountLimitSelector);
  const setGuessWinow = useSetRecoilState(guessWindowAtom);
  const [settings, setSettingsState] = useRecoilState(settingsAtom);

  const [slides, setSlides] = React.useState(null);
  const [currentSlide, setCurrentSlide] = React.useState({
    count: -1,
    slide: null,
  });
  const [showSlide, setShowSlide] = React.useState([]);
  const [showContent, setShowContent] = React.useState(false);

  React.useEffect(() => {
    if (settings.skipTutorial) {
      startRound();
    } else {
      setSlides([slide1, slide2, slide3, slide4, slide5]);
      setCurrentSlide({
        count: 0,
        slide: slide1,
      });
      setShowSlide(true);
      setTimeout(() => {
        setShowContent(true);
      }, 150);
    }
  }, []);

  React.useEffect(() => {
    if (currentSlide.count + 1 === 4) {
      setGuessWinow({ opening: true });
    }
  }, [currentSlide]);

  const startRound = () => {
    setSettingsState({
      ...settings,
      skipTutorial: true,
    });
    gameStateSet(GameStateEnum.RoundStart);
  };

  const nextSlide = () => {
    let newCount = currentSlide.count + 1;
    if (newCount + 1 > slides.length) {
      setShowContent(false);
      setTimeout(() => {
        startRound();
      }, 800);
    } else {
      setCurrentSlide({ ...currentSlide, count: newCount });
      setShowSlide(false);
    }
  };

  const slide1 = () => {
    return (
      <div className="flex flex-col items-center justify-center gap-y-6 text-white">
        <div className="text-4xl font-bold text-center">
          <span className="block mb-2"> Make a guess by typing</span>

          <span className="font-bold bg-gray-800 px-3">{"!"}</span>

          <span> followed by a number</span>
        </div>

        <div className="bg-gray-900 p-3 w-2/3 text-lg">
          <span className="block">
            <span className="text-Twitch_Red font-bold">hawkdakidd</span>
            <span>: !2</span>
          </span>

          <span className="block">
            <span className="text-Twitch_Green font-bold">Nninija433</span>
            <span>: !4</span>
          </span>

          <span className="block">
            <span className="text-green-400 font-bold">Canuck231</span>
            <span>: !3</span>
          </span>

          <span className="block">
            <span className="text-yellow-300 font-bold">detox36</span>
            <span>: !2</span>
          </span>

          <span className="block">
            <span className="text-Twitch_Aqua font-bold">its_RyanV</span>
            <span>: !1</span>
          </span>

          <span className="block">
            <span className="text-Twitch_Red font-bold">lambylam478</span>
            <span>: !2</span>
          </span>

          <span className="block">
            <span className="text-Twitch_Purple font-bold">yolebol</span>
            <span>: !6</span>
          </span>
        </div>
        <span className="font-bold text-lg mt-2">
          You Only Get One Guess Per Round
        </span>
      </div>
    );
  };

  const slide2 = () => {
    return (
      <div className="flex flex-col items-center justify-center gap-y-12 text-white">
        <span className="text-3xl font-bold text-center">
          A Correct Guess Gains You Points!
        </span>
        <span className="font-bold text-center text-2xl">
          Small bonus for how fast!
        </span>
      </div>
    );
  };

  const slide3 = () => {
    return (
      <div className="flex flex-col items-center justify-center gap-y-12 text-white">
        <span className="text-3xl font-bold ">
          A Incorrect Guess Loses You Some Points.
        </span>
        <span className="font-bold text-2xl">Little more for how fast.</span>
      </div>
    );
  };

  const slide4 = () => {
    return (
      <div className="flex flex-col items-center justify-center text-4xl font-bold text-white">
        <span>
          <span translate="no" className="font-bold bg-gray-800 px-3 pb-2 mb-2">
            <ChannelName size={"md"} minimal={true} imgSize={35} />
          </span>
          <span> You Have Your Own </span>
        </span>

        <span className="mt-2">Window To Guess With</span>
      </div>
    );
  };

  const slide5 = () => {
    return (
      <div className="flex flex-col items-center justify-center gap-y-10 text-white">
        <span className="text-4xl font-bold text-center">
          Top {scoreCoutLimit} Chatters Will Be Accounted To <br /> Total Score
        </span>
      </div>
    );
  };

  return (
    <Transition
      className="w-full h-170 lg:h-full"
      show={showContent}
      enter="transition-opacity duration-700 delay-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-700"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="w-full h-full relative">
        <button
          onClick={() => {
            startRound();
            dataLayerPush("tutorial_skipped_click");
          }}
          className=" absolute right-0 px-2 text-2xl font-bold hover:underline"
        >
          Skip
        </button>
        <div style={{ height: "80%" }} className="w-full h-full">
          <Transition
            className="w-full h-full flex items-center justify-center "
            show={showSlide}
            enter="transition-opacity duration-700"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-700"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => {
              setCurrentSlide({
                ...currentSlide,
                slide: slides[currentSlide.count],
              });
              setShowSlide(true);
            }}
          >
            {currentSlide.slide && currentSlide.slide()}
          </Transition>
        </div>
        <div style={{ height: "20%" }} className="w-max mx-auto">
          <button
            className="border-2 hover:border-black px-12 py-2 bg-white text-black font-bold"
            onClick={nextSlide}
          >
            {currentSlide.count === 4 ? "Begin" : "Next"}
          </button>
        </div>
      </div>
    </Transition>
  );
};

export default Tutorial;
