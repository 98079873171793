// import { AuthTypeEnum } from "../game/gameEnums.mjs";
import axios from "axios";
import { DifficultyEnum } from "../../recoil/activeCat";
import { AuthTypeEnum } from "../../recoil/user";

const wordRegEx = (word) => {
  let prepWord = RegExpEscape(word).toLowerCase();

  if (prepWord.includes("'s") || prepWord.includes("’s")) {
    prepWord = prepWord.replace(/'s/g, "(’s|'s|s|)");
    prepWord = prepWord.replace(/’s/g, "(’s|'s|s|)");
  } else {
    prepWord = prepWord.replace(/'/g, "('|)");
    prepWord = prepWord.replace(/’/g, "(’|)");
  }

  prepWord = prepWord.replace(/-/g, "(-| )");

  if (!(prepWord.includes(" of the ") || prepWord.includes(" and the "))) {
    prepWord = prepWord.replace(/\bthe /g, "(the |)");
  } else {
    prepWord = prepWord.replace(/^the /, "(the |)");
  }

  if (prepWord.includes(" & ")) {
    prepWord = prepWord.replace(/ & /g, "( & | and )");
  } else {
    prepWord = prepWord.replace(/&/g, "(&|and)");
  }

  return new RegExp(`^${prepWord}$`, "u");
};

const RegExpEscape = (s) => {
  //Removed "-". Fine since we are not using []
  // eslint-disable-next-line no-useless-escape
  return s.replace(/[\/\\^$*+?.()|[\]{}]/g, "\\$&");
};

const setInputFilter = (textbox, inputFilter, defualtValue) => {
  textbox.value = defualtValue;
  [
    "input",
    "keydown",
    "keyup",
    "mousedown",
    "mouseup",
    "select",
    "contextmenu",
    "drop",
  ].forEach(function (event) {
    textbox.addEventListener(event, function () {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (Object.prototype.hasOwnProperty.call(this, "oldValue")) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = "";
      }
    });
  });
};

const calcVolume = (masterVolume, sub = 0) => {
  let volume = masterVolume - sub;
  return volume < 0 ? 0 : volume;
};

const dataLayerPush = (eventName, data = {}) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({ event: eventName, ...data });
  } else if (process.env.NODE_ENV === "production") {
    console.error("dataLayer undefined");
  }
};

const getAuthTypeString = (authType) => {
  switch (authType) {
    case AuthTypeEnum.Twitch:
      return "Twitch";
    case AuthTypeEnum.Youtube:
      return "Youtube";
    case AuthTypeEnum.TwitchChannelName:
      return "Twitch Channel Name";
    default:
      console.warn("Failed to get auth type string");
      return "Unknown";
  }
};

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const getTwitchChannelData = (token, client_id, channelName) => {
  return new Promise((reslove, reject) => {
    let url = "";
    if (typeof channelName === "string") {
      if (channelName.length > 0) {
        url = `https://api.twitch.tv/helix/users?login=${channelName}`;
      } else {
        return reject("channel name empty");
      }
    } else {
      url = "https://api.twitch.tv/helix/users";
    }

    axios({
      method: "get",
      url: url,
      headers: {
        "Client-Id": client_id,
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        let userData = res.data.data[0];
        //Get Stream data
        axios({
          method: "get",
          url: `https://api.twitch.tv/helix/streams?user_id=${userData.id}`,
          headers: {
            "Client-Id": client_id,
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res2) => {
            let streamData = res2.data.data[0];
            reslove({ ...userData, ...streamData });
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

function generateRandomIntExclude(min, max, exclude = []) {
  var num = Math.floor(Math.random() * (max - min + 1)) + min;
  let wrongNum = false;
  for (const numberExlude of exclude) {
    if (numberExlude === num) {
      wrongNum = true;
    }
  }
  return wrongNum ? generateRandomIntExclude(min, max, exclude) : num;
}

const shuffleArray = (a) => {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
};

const toStringDifficultyEnum = (difficultyEnum) => {
  switch (difficultyEnum) {
    case DifficultyEnum.easy:
      return "Easy";
    case DifficultyEnum.medium:
      return "Medium";
    case DifficultyEnum.hard:
      return "Hard";
  }
};

const getCategoryCompletedPrecent = (category) => {
  let store = localStorage.getItem(`Seen_Words_${category.id}`);
  if (store) {
    let storeCat = JSON.parse(store);
    let allWordsInCat = category.getWords([1, 2, 3]);
    let allSeenWordsCat = storeCat.reduce(
      (acc, seenWords) => (acc = acc.concat(seenWords)),
      []
    );
    return allSeenWordsCat.length / allWordsInCat.length;
  } else {
    return 0;
  }
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export {
  setInputFilter,
  calcVolume,
  dataLayerPush,
  getRandomInt,
  getTwitchChannelData,
  generateRandomIntExclude,
  shuffleArray,
  wordRegEx,
  toStringDifficultyEnum,
  RegExpEscape,
  numberWithCommas,
  getKeyByValue,
  getCategoryCompletedPrecent,
  getAuthTypeString,
};
