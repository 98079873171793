import { atom } from "recoil";

const settingsAtom = atom({
  key: "settingsAtom",
  default: {
    stream_delay_secs_start: 3,
    stream_delay_secs_end: 8,
    timer_secs: 25,
    volume: 0.7, //volume to be used
    raw_volume: 0.7, //volume for slider only
    options_limt: 6,
    commandPrefix: "!",
    max_rounds: 10,
    skipTutorial: false,
    alwaysSkipTutorial: false,

    imageZoomAmmount: 15,

    round_start_delay: 3000,
    round_next_delay: 2500,
    game_end_delay: 6100,
  },
});

export default settingsAtom;
