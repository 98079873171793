import React from "react";
import PropTypes from "prop-types";
import { SetInputFilter } from "../../utils/browser_utils";

const NumberInput = ({ onChange, defualtValue, minValue }) => {
  const timeInputRef = React.useRef();

  React.useEffect(() => {
    if (defualtValue) {
      SetInputFilter(
        timeInputRef.current,
        (value) => {
          return /^-?\d*$/.test(value);
        },
        defualtValue
      );
    }
  }, [defualtValue]);

  return (
    <input
      className="p-1 w-12 bg-DTC_Yellow text-black font-bold"
      aria-label="Number Input"
      id="timerInput"
      ref={timeInputRef}
      onBlur={(e) => {
        let value = parseInt(e.target.value);
        if (!value || value < minValue) {
          value = minValue;
          e.target.value = minValue;
        }
        onChange(value);
      }}
      onChange={(e) => {
        const value = parseInt(e.target.value);
        if (!value || value > minValue) {
          return;
        }
        onChange(value);
      }}
    />
  );
};

NumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  defualtValue: PropTypes.any,
  minValue: PropTypes.any,
};

export default NumberInput;
