import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";

import { client_id_twitch } from "../api";
import { errorStateAtom, ErrorLevelEnum } from "../recoil/errorState";
import { useSetRecoilState } from "recoil";
import { AuthTypeEnum } from "../recoil/user";
import tmi from "tmi.js";

const TwitchChannelNamePanel = ({
  onEnter,
  onExit,
  setIsLoading,
  userAuthed,
  hidePanel,
  hideBtn,
}) => {
  const [panelActive, setPanelActive] = React.useState(false);
  const [channelName, setChannelName] = React.useState("");
  const setErrorState = useSetRecoilState(errorStateAtom);

  const onTwitchChannelName = (channel_name) => {
    setIsLoading(true);
    const tmi_client = new tmi.Client({
      options: {
        debug: false,
        skipUpdatingEmotesets: true,
        skipMembership: true,
      },
      client_id: client_id_twitch,
    });

    tmi_client.connect().catch((err) => {
      setErrorState({
        show: true,
        message: `Was not able to sign you in. ${err}`,
        errorLevelEnum: ErrorLevelEnum.FATAL,
      });
      setIsLoading(false);
    });

    tmi_client.on("connected", () => {
      tmi_client
        .join(channel_name)
        .then(() => {
          userAuthed({
            id: null,
            access_token: null,
            login: channel_name,
            display_name: channel_name,
            authType: AuthTypeEnum.TwitchChannelName,
          });
          tmi_client.disconnect();
        })
        .catch((err) => {
          setErrorState({
            show: true,
            message: `Was not able to sign you in. ${err}`,
            errorLevelEnum: ErrorLevelEnum.FATAL,
          });
          setIsLoading(false);
        });
    });
  };

  const openBtn = () => {
    return (
      !hideBtn && (
        <button
          label="Connect with Twitch Using Channel Name"
          onClick={() => {
            onEnter();
            setPanelActive(true);
          }}
        >
          <div className="bg-gray-500 py-2 px-2 flex gap-2 items-center justify-center text-sm">
            <div style={{ fontSize: "1.3rem" }}>
              <FontAwesomeIcon icon={faTwitch} />
            </div>
            <div className="font-bold">Channel Name</div>
          </div>
        </button>
      )
    );
  };

  const panel = () => {
    return (
      !hidePanel && (
        <div className="p-3 text-center bg-opacity-90 rounded-lg">
          <h3 className="text-xl font-bold tracking-wider bg-gray-900 rounded-md  p-2">
            Connecting using a channel name
            <br />
            will prevent certain features from working.
          </h3>

          <input
            className="h-8 p-1 pl-2 py-5 border border-white bg-gray-600 max-w-md mt-4 text-center text-lg font-bold"
            type="text"
            name="channel name"
            aria-label="channel name"
            placeholder="Channel Name"
            spellCheck={false}
            onChange={(e) => {
              setChannelName(e.target.value);
            }}
          />
          <button
            className="bg-TwitchPurple py-2 px-2 block mt-5 mx-auto font-bold"
            aria-label="Enter Channel Name"
            onClick={() => {
              onTwitchChannelName(channelName);
            }}
          >
            Ok, Enter
          </button>
          <button
            className="bg-gray-400 text-black py-2 px-2 block mt-5 mx-auto font-bold"
            aria-label="Enter Channel Name"
            onClick={() => {
              onExit();
              setPanelActive(false);
            }}
          >
            Back
          </button>
        </div>
      )
    );
  };

  return panelActive ? panel() : openBtn();
};

export default TwitchChannelNamePanel;
