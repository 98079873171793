import * as React from "react";
import PropTypes from "prop-types";
import { useRecoilValue } from "recoil";
import { userAtom, AuthTypeEnum } from "../../recoil/user";

import TwitchSVG from "../../images/twitch.svg";

const ChannelName = ({ size, minimal, imgSize = 45 }) => {
  const user = useRecoilValue(userAtom);
  return (
    <div className="inline">
      <span translate="no" className={`ml-1 font-bold text-${size} break-all `}>
        {(user.authType === AuthTypeEnum.Twitch ||
          user.authType === AuthTypeEnum.TwitchChannelName ||
          user.authType === AuthTypeEnum.None) && (
          <img
            className="mx-auto select-none inline"
            src={TwitchSVG}
            width={imgSize}
          />
        )}{" "}
        {user.display_name.length != 0 ? user.display_name : "TESTNAME"}
        {user.authType === AuthTypeEnum.TwitchChannelName && !minimal && (
          <span className=" opacity-75 text-sm ml-1">{"(Channel Name)"}</span>
        )}
      </span>
    </div>
  );
};

ChannelName.propTypes = {
  size: PropTypes.string,
  imgSize: PropTypes.number,
  minimal: PropTypes.bool,
};

export default ChannelName;
