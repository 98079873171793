import * as React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";

const LinkBar = ({ onPageChange }) => {
  return (
    <div className="absolute text-center w-full bottom-0 z-10 opacity-80">
      <div>
        <span className="block">Created by: Leption</span>
        <a
          aria-label="inline Twitter"
          href="https://github.com/lejara"
          target="_blank"
          rel="noopener noreferrer"
          className="inline m-1"
        >
          <FontAwesomeIcon icon={faGithub} size="lg" />
        </a>
        <a
          aria-label="Github"
          href="https://twitter.com/Leption_LJ"
          target="_blank"
          rel="noopener noreferrer"
          className="inline m-1"
        >
          <FontAwesomeIcon icon={faTwitter} size="lg" />
        </a>
      </div>
      <div className="absolute right-0 flex flex-col md:flex-row items-end h-0 text-lg">
        <button
          onClick={() => {
            onPageChange("patchNotes");
          }}
          className="underline mx-2"
        >
          Patch Notes
        </button>
        <button
          onClick={() => {
            onPageChange("bugs/Ideas");
          }}
          className="underline mx-2"
        >
          Bugs/Ideas
        </button>
        <a
          aria-label="Link to donation page"
          href="https://ko-fi.com/leption"
          target="_blank"
          rel="noopener noreferrer"
          className="underline mx-2"
        >
          Donate
        </a>
      </div>
    </div>
  );
};

LinkBar.propTypes = {
  onPageChange: PropTypes.func.isRequired,
};

export default LinkBar;
