import * as React from "react";

import CategorySelection from "./CategorySelection";
import PlayBtn from "./PlayBtn";
import { useResetRecoilState, useRecoilValue } from "recoil";
import { gameScoresAtom } from "../../recoil/roundState";
import { activeCatAtom, activeDifficultiesAtom } from "../../recoil/activeCat";

import dailyCategory from "../../game/daily_category";
import DifficultyDropdown from "./DifficultyDropdown";

function SelectionPanel() {
  const resetGameScores = useResetRecoilState(gameScoresAtom);
  const activeCategory = useRecoilValue(activeCatAtom);
  const activeDifficulties = useRecoilValue(activeDifficultiesAtom);
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    dailyCategory.init();
    resetGameScores();
    setReady(true);
  }, []);

  const getGuide = () => {
    //TODO: use this when daily category is active
    // if (
    //   !activeCategory &&
    //   dailyCategory.hasReset() &&
    //   activeDifficulties.length === 0
    // ) {
    //   return "Select Daily Or Difficulty";
    // } else if (activeDifficulties.length === 0 && !dailyCategory.hasReset()) {
    //   return "Select Difficulty";
    // } else if (activeCategory == null) {
    //   return "Select Category";
    // } else {
    //   return "";
    // }

    if (activeDifficulties.length === 0) {
      return "Select Difficulty";
    } else if (activeCategory == null) {
      return "Select Category";
    } else {
      return "";
    }
  };

  return (
    ready && (
      <div className="py-4 px-1 text-center h-full flex flex-col gap-y-12 pb-12">
        <div className="flex justify-center items-center flex-col lg:flex-row">
          <div className="w-full">
            <div className=" justify-self-center">
              <PlayBtn />
            </div>

            <h1 className="font-bold text-3xl text-BOTC_Autom_Orange text-stroke--white pt-8">
              {getGuide()}
            </h1>
          </div>

          <div className="w-full lg:w-8/12 order-1">
            <DifficultyDropdown />
          </div>
        </div>

        <CategorySelection />
      </div>
    )
  );
}

export default SelectionPanel;
