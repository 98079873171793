import React from "react";

const PageLoading = () => {
  const [loaded, setLoaded] = React.useState(true);

  if (typeof window !== "undefined") {
    window.addEventListener("fullyLoaded", () => {
      setLoaded(false);
    });
  }

  React.useEffect(() => {
    if (window.hasFullyLoaded) {
      setLoaded(false);
    }
  }, []);

  return (
    loaded && (
      <h1
        /* Inline styles, tailwind will still be loading */
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "3rem",
          fontStyle: "bold",
          zIndex: "999",
          position: "absolute",
        }}
      >
        <span
          style={{
            padding: "5px",
            marginBottom: "30px",
          }}
        >
          Loading...
        </span>
      </h1>
    )
  );
};

export default PageLoading;
