//used for initializing settings
import * as React from "react";

import { useRecoilState } from "recoil";
import { settingsAtom } from "../../recoil/settings";

const useSettingsState = () => {
  const [settingsState, setSettingsState] = useRecoilState(settingsAtom);

  React.useEffect(() => {
    let defualts = {
      alwaysSkipTutorial: settingsState.alwaysSkipTutorial,
      volume: settingsState.volume,
      raw_volume: settingsState.raw_volume,
      stream_delay_secs_end: settingsState.stream_delay_secs_end,
    };
    let d = localStorage.getItem("Settings_Save");
    if (d) {
      let defaultsObj = JSON.parse(d);
      defualts = {
        ...defualts,
        ...defaultsObj,
      };
      setSettingsState({
        ...settingsState,
        alwaysSkipTutorial: defualts.alwaysSkipTutorial,
        skipTutorial: defualts.alwaysSkipTutorial,
        volume: defualts.volume,
        raw_volume: defualts.raw_volume,
        stream_delay_secs_end: defualts.stream_delay_secs_end,
      });
    }
  }, []);

  return null;
};

export default useSettingsState;
