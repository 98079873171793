import * as React from "react";
import PropTypes from "prop-types";

import ConfirmDialog from "../utils/ConfirmDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import { dataLayerPush } from "../utils/utils";

import { GameStateEnum } from "../../recoil/gameState";
import useGameStateSet from "../utils/useGameStateSet";

const EndBtn = ({ useText = false }) => {
  const gameStateSet = useGameStateSet();
  const [showConfirm, setShowConfirm] = React.useState(false);
  return (
    <>
      <button
        className="block font-underline  px-1 text-xl"
        onClick={() => {
          setShowConfirm(true);
        }}
      >
        {useText ? (
          <span className="text-red-400">Leave Game</span>
        ) : (
          <FontAwesomeIcon icon={faXmarkCircle} />
        )}
      </button>

      <ConfirmDialog
        show={showConfirm}
        onYes={() => {
          setShowConfirm(false);
          gameStateSet(GameStateEnum.EndTransition);
          dataLayerPush("game_exit");
        }}
        onNo={() => {
          setShowConfirm(false);
        }}
      />
    </>
  );
};

EndBtn.propTypes = {
  useText: PropTypes.bool,
};

export default EndBtn;
