import { useRecoilState, useRecoilValue } from "recoil";
import { gameStateAtom, GameStateEnum } from "../../recoil/gameState";
import { bGStateAtom, BGTransitionStateEnum } from "../../recoil/BGState";
import { settingsAtom } from "../../recoil/settings";

const useGameStateSet = () => {
  const [gameState, setGameState] = useRecoilState(gameStateAtom);
  const [bGState, setBGState] = useRecoilState(bGStateAtom);
  const settings = useRecoilValue(settingsAtom);

  const gameStateSet = (newGameState) => {
    if (
      (gameState === GameStateEnum.SignIn ||
        gameState === GameStateEnum.Selection) &&
      newGameState === GameStateEnum.StartTransition
    ) {
      bgTransition(
        GameStateEnum.Tutorial,
        BGTransitionStateEnum.Start,
        settings.round_start_delay,
        "start"
      );
    } else if (
      (gameState === GameStateEnum.Results ||
        gameState === GameStateEnum.Playing) &&
      newGameState === GameStateEnum.RoundTransition
    ) {
      bgTransition(
        GameStateEnum.RoundStart,
        BGTransitionStateEnum.NextRound,
        settings.round_next_delay,
        "round"
      );
    } else if (
      (gameState === GameStateEnum.Results ||
        gameState === GameStateEnum.Playing) &&
      newGameState === GameStateEnum.EndTransition
    ) {
      bgTransition(
        GameStateEnum.FinalResults,
        BGTransitionStateEnum.End,
        settings.game_end_delay,
        "end"
      );
    } else {
      setGameState(newGameState);
    }
  };

  const bgTransition = (newGameState, newBGState, timeout, transitionType) => {
    setGameState(getTrasitionStateEnum(transitionType));
    setBGState({ ...bGState, state: newBGState });
    setTimeout(() => {
      setGameState(newGameState);
    }, timeout);
  };

  const getTrasitionStateEnum = (transitionType) => {
    switch (transitionType) {
      case "start":
        return GameStateEnum.StartTransition;
      case "round":
        return GameStateEnum.RoundTransition;
      case "end":
        return GameStateEnum.EndTransition;
    }
    return GameStateEnum.EndTransition;
  };

  return gameStateSet;
};
export default useGameStateSet;
