import * as React from "react";
import PropTypes from "prop-types";

import { useRecoilValue } from "recoil";
import { activeCatAtom, DifficultyEnum } from "../../../recoil/activeCat";

import { getAllAssetsWithTags, getScoresSimilar } from "./genOptionsUtils.mjs";

const ListTagScores = ({ assetobj, limit }) => {
  const [scoreList, setScoreList] = React.useState(null);
  const activeCatgory = useRecoilValue(activeCatAtom);

  React.useEffect(() => {
    if (!assetobj.tags) {
      return;
    }
    setScoreList(
      getScoresSimilar(
        assetobj,
        getAllAssetsWithTags(
          activeCatgory.getWords([
            DifficultyEnum.easy,
            DifficultyEnum.medium,
            DifficultyEnum.hard,
          ])
        )
      )
    );
  }, []);

  return (
    scoreList &&
    scoreList.length && (
      <div className="overflow-y-scroll h-1/5 bg-gray-600 mt-10 px-4">
        <h3 className="font-bold text-2xl">Tag Scores</h3>
        {scoreList.map((scoreData, index) => {
          if (index + 1 > limit) {
            return;
          }
          return (
            <div key={`tagScoreData-${scoreData.word}`}>
              <span>{index + 1}: </span>
              <span>Word:</span> <span className="mr-3">{scoreData.word}</span>{" "}
              <span>Score:</span> <span>{scoreData.score}</span>
            </div>
          );
        })}
      </div>
    )
  );
};

ListTagScores.propTypes = {
  assetobj: PropTypes.object.isRequired,
  limit: PropTypes.number,
};

export default ListTagScores;
