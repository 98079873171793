import React from "react";
import PropTypes from "prop-types";
import categoryModules from "../../../game/categoryModules.json";
import {
  objToCategoryMarkdown,
  parseCategoryFile,
  audioOptions,
  imageOptions,
} from "../../../../dev-utils/categoryParser";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//skip props that are in the word obj, weather its not needed or we will handle ourselves
const skipWordProps = ["fileName", "id", "options", "assets"];

const AssetEditor = ({ wordID, categoryID }) => {
  const [wordObj, setWordObj] = React.useState();
  const [wordIndex, setWordIndex] = React.useState();

  React.useEffect(() => {
    // console.log(loadCategory().words.find((wordObj) => wordObj.id === wordID));
    setWordObj(
      structuredClone(
        loadCategory().words.find((wordObj, index) => {
          setWordIndex(index);
          return wordObj.id === wordID;
        })
      )
    );
  }, []);

  const reset = () => {
    const resetWordObj = structuredClone(
      categoryModules[categoryID].words.find((wordObj, index) => {
        setWordIndex(index);
        return wordObj.id === wordID;
      })
    );
    setWordObj(resetWordObj);
    saveCategory(resetWordObj);
  };

  const copyToClip = (wordObj) => {
    navigator.clipboard.writeText(getMarkdown(wordObj));
  };

  const getMarkdown = (wordObj) => {
    const category = { ...loadCategory() };
    category.words[wordIndex] = structuredClone(wordObj);
    return objToCategoryMarkdown(category);
  };

  const loadCategory = () => {
    const loadedCategory = localStorage.getItem(
      `asset_editor_cat_${categoryID}`
    );

    if (loadedCategory) {
      const categoryParsed = parseCategoryFile(
        loadedCategory,
        categoryModules[categoryID].fileName
      );

      //by defualt the conversion to markdown removes generated fields.
      //so we repopulate it with our exisitng data from categoryModules
      categoryParsed.words = categoryModules[categoryID].words.map(
        (wordObj, index) => {
          if (!categoryParsed.words[index]) {
            return wordObj;
          }
          return { ...wordObj, ...categoryParsed.words[index] };
        }
      );

      return { ...categoryModules[categoryID], ...categoryParsed };
    }

    return categoryModules[categoryID];
  };

  const saveCategory = (wordObj) => {
    localStorage.setItem(
      `asset_editor_cat_${categoryID}`,
      getMarkdown(wordObj)
    );
  };

  const updateWordObj = (newWordObj) => {
    setWordObj(newWordObj);
    saveCategory(newWordObj);
  };

  const updateOptions = (options) => {
    updateWordObj({ ...wordObj, options: options });
  };

  const updateAsset = (asset, index) => {
    const assets = [...wordObj.assets];
    assets[index] = asset;
    updateWordObj({ ...wordObj, assets: assets });
  };

  const listAssetOptions = (asset, index) => {
    switch (asset.type) {
      case "audio":
        return audioOptions.map((option) => {
          switch (option) {
            case "segmentAudio":
              return (
                <div key={`audioOp-${wordID}-${option}`}>
                  <label htmlFor={`${wordID}-${option}-c`}>{option}: </label>
                  <input
                    id={`${wordID}-${option}-c`}
                    type="checkbox"
                    checked={asset[option] ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const newAsset = { ...asset };
                        newAsset[option] = e.target.checked;
                        updateAsset(newAsset, index);
                      } else {
                        const newAsset = { ...asset };
                        delete newAsset[option];
                        updateAsset(newAsset, index);
                      }
                    }}
                  />
                </div>
              );
            default:
              return (
                <div key={`audioOp-${wordID}-${option}`}>
                  <label htmlFor={`${wordID}-${option}-c`}>{option}: </label>
                  <input
                    className="text-black m-1"
                    id={`${wordID}-${option}-c`}
                    value={asset[option]}
                    onChange={(e) => {
                      const newAsset = { ...asset };
                      newAsset[option] = e.target.value;
                      updateAsset(newAsset, index);
                    }}
                  />
                </div>
              );
          }
        });

      case "image":
        return imageOptions.map((option) => {
          switch (option) {
            case "doBlur":
              return (
                <div key={`imageOp-${wordID}-${option}`}>
                  <label htmlFor={`${wordID}-${option}-c`}>{option}: </label>
                  <input
                    id={`${wordID}-${option}-c`}
                    type="checkbox"
                    checked={asset[option] ? true : false}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const newAsset = { ...asset };
                        newAsset[option] = e.target.checked;
                        updateAsset(newAsset, index);
                      } else {
                        const newAsset = { ...asset };
                        delete newAsset[option];
                        updateAsset(newAsset, index);
                      }
                    }}
                  />
                </div>
              );
            default:
              return (
                <div key={`imageOp-${wordID}-${option}`}>
                  <label htmlFor={`${wordID}-${option}-c`}>{option}: </label>
                  <input
                    className="text-black m-1"
                    id={`${wordID}-${option}-c`}
                    value={asset[option]}
                    onChange={(e) => {
                      const newAsset = { ...asset };
                      newAsset[option] = e.target.value;
                      updateAsset(newAsset, index);
                    }}
                  />
                </div>
              );
          }
        });

      case "text":
        return;
    }
  };

  const listGuessOptions = (options) => {
    if (!options) {
      options = [];
    }
    return (
      <div>
        {options.map((optionObj, index) => {
          if (!optionObj.translate) {
            optionObj.translate = "no";
          }
          return (
            <div className="flex" key={`options-${wordID}-${index}`}>
              {Object.keys(optionObj).map((optionKey) => (
                <div key={`optionObj-${wordID}-${optionKey}`}>
                  <span>{optionKey}: </span>
                  <input
                    className="text-black m-1"
                    value={optionObj[optionKey]}
                    onChange={(e) => {
                      const newOptions = [...options];
                      newOptions[index][optionKey] = e.target.value;
                      updateOptions(newOptions);
                    }}
                  />
                </div>
              ))}
              <button
                className="text-red-500"
                onClick={() => {
                  const newOptions = [...options];
                  newOptions.splice(index, 1);
                  updateOptions(newOptions);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          );
        })}

        <button
          className="border p-2"
          onClick={() => {
            const newOptions = [...options];
            newOptions.push({ word: "", translate: "no" });
            updateOptions(newOptions);
          }}
        >
          +
        </button>
      </div>
    );
  };

  const listProps = () => {
    if (!wordObj) {
      return;
    }
    return (
      <>
        <h1 className="text-lg font-bold">Word Options</h1>
        {Object.keys(wordObj)
          .filter((key) => !skipWordProps.includes(key))
          .map((key) => {
            switch (key) {
              case "difficulty":
                return (
                  <div key={`wordPorps-${wordID}-${key}`}>
                    <span>{key}: </span>
                    <input
                      type="number"
                      className="text-black m-1"
                      value={wordObj[key]}
                      onChange={(e) => {
                        const newWordObj = { ...wordObj };
                        newWordObj["difficulty"] = e.target.value
                          ? parseInt(e.target.value)
                          : "";
                        updateWordObj(newWordObj);
                      }}
                    />
                  </div>
                );
              default:
                return (
                  <div key={`wordPorps-${wordID}-${key}`}>
                    <span>{key}: </span>
                    <input
                      className="text-black m-1"
                      value={wordObj[key]}
                      onChange={(e) => {
                        const newWordObj = { ...wordObj };
                        newWordObj[key] = e.target.value;
                        updateWordObj(newWordObj);
                      }}
                    />
                  </div>
                );
            }
          })}
        <div key={`wordPorps-${wordID}-options`}>
          <h1 className="text-lg font-bold">Guess Options</h1>
          {listGuessOptions(wordObj.options)}
        </div>
        <div key={`wordPorps-${wordID}-aseets`}>
          <h1 className="text-lg font-bold">Asset Options</h1>
          {wordObj.assets.map((asset, index) => listAssetOptions(asset, index))}
        </div>
      </>
    );
  };
  AssetEditor.propTypes = {
    wordID: PropTypes.number.isRequired,
    categoryID: PropTypes.number.isRequired,
  };

  return (
    <div className="relative bg-gray-700 p-1">
      {listProps()}

      <div className="w-full h-20 flex justify-between">
        <button className="text-red-600" onClick={() => reset()}>
          Reset
        </button>
        <button className="border p-1" onClick={() => copyToClip(wordObj)}>
          Copy
        </button>
      </div>
    </div>
  );
};

export default AssetEditor;
