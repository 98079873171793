import * as React from "react";
import PropTypes from "prop-types";
import Box from "./Box";
import { useFrame } from "@react-three/fiber";
import { degToRad, radToDeg } from "three/src/math/MathUtils";
import Transition from "./Transition";
import { getRandomInt } from "../utils/utils";

const MainBoxStateEnum = Object.freeze({
  FreeForm: 0,
  NextFace: 1,
  NextFaceDelay: 2,
  Locked: 3,
});

const getRotationInRads = (x, y, z) => [degToRad(x), degToRad(y), degToRad(z)];
const getRotationInDeg = (x, y, z) => [
  radToDeg(x) % 360,
  radToDeg(y) % 360,
  radToDeg(z) % 360,
];

const MainBox = ({
  mainBoxStateEnum,
  onFaceTransitionEnd = () => null,
  nextFaceDelay = 0,
}) => {
  const boxMeshRef = React.useRef();
  const animationState = React.useRef();
  const faceTransitionDataRef = React.useRef();

  React.useEffect(() => {
    boxMeshRef.current.rotation.x = -0.2;
    boxMeshRef.current.rotation.y = 0.4;
  }, []);

  React.useEffect(() => {
    if (mainBoxStateEnum === MainBoxStateEnum.NextFace) {
      faceTransitionDataRef.current = {
        rates: [0, 0, 0],
      };
      // console.log("MainCube: Next face active");
      animationState.current = new Transition({
        durationMills: 2000,
        tickIntervalMills: 10,
        onStart: transitionStart,
        onUpdate: transitionUpdate,
        onEnd: onFaceTransitionEnd,
      });
      animationState.current.start();
    } else if (mainBoxStateEnum === MainBoxStateEnum.NextFaceDelay) {
      faceTransitionDataRef.current = {
        rates: [0, 0, 0],
      };
      // console.log("MainCube: Next face delay");
      animationState.current = new Transition({
        delay: nextFaceDelay,
        durationMills: 1000,
        tickIntervalMills: 10,
        onStart: transitionStart,
        onUpdate: transitionUpdate,
        onEnd: onFaceTransitionEnd,
      });
      animationState.current.start();
    } else if (mainBoxStateEnum === MainBoxStateEnum.FreeForm) {
      boxMeshRef.current.rotation.x = 0.2;
    }
  }, [mainBoxStateEnum]);

  useFrame((state, delta) => {
    if (animationState.current) {
      animationState.current.onFrameUpdate(state);
    }
    if (mainBoxStateEnum === MainBoxStateEnum.FreeForm) {
      freeRotateUpdate(delta);
    }
  });

  const freeRotateUpdate = (delta) => {
    let yInDegs = getRotationInDeg(0, boxMeshRef.current.rotation.y, 0)[1];

    boxMeshRef.current.rotation.y = getRotationInRads(
      0,
      yInDegs + 7 * delta,
      0
    )[1];
  };

  //-------------------------Face Transition----------------------------------------

  const facesRotLocationDeg = {
    front: [0, 0, 0],
    left: [0, 90, 0],
    back: [0, 180, 0],
    right: [0, 270, 0],
    top: [90, 0, 0],
    bottom: [-90, 0, 0],
  };
  const getProjectedAmmount = (current, target) => {
    if ((current < 0 && target < 0) || (current > 0 && target > 0)) {
      return Math.abs(Math.abs(current) - Math.abs(target));
    } else {
      return Math.abs(Math.abs(current) + Math.abs(target));
    }
  };

  const transitionStart = () => {
    const boxRef = boxMeshRef.current;
    const faceTransData = faceTransitionDataRef.current;
    let boxRotationDeg = getRotationInDeg(
      boxRef.rotation.x,
      boxRef.rotation.y,
      boxRef.rotation.z
    );

    let targetFaces = [];
    Object.keys(facesRotLocationDeg).forEach((key) => {
      let xAmmount = getProjectedAmmount(
        boxRotationDeg[0],
        facesRotLocationDeg[key][0]
      ); //x
      let yAmmount = getProjectedAmmount(
        boxRotationDeg[1],
        facesRotLocationDeg[key][1]
      ); //y
      let zAmmount = getProjectedAmmount(
        boxRotationDeg[2],
        facesRotLocationDeg[key][2]
      ); //z
      targetFaces.push({
        xAmmount: xAmmount,
        xSign: getSign(boxRotationDeg[0], facesRotLocationDeg[key][0]),
        yAmmount: yAmmount,
        ySign: getSign(boxRotationDeg[1], facesRotLocationDeg[key][1]),
        zAmmount: zAmmount,
        zSign: getSign(boxRotationDeg[2], facesRotLocationDeg[key][2]),
        total: xAmmount + yAmmount + zAmmount,
      });
    });
    targetFaces = targetFaces.sort((e1, e2) => {
      if (e1.total > e2.total) {
        return 1;
      }
      return -1;
    });
    // console.log(targetFaces);
    let pick = getRandomInt(1, 5);
    let targetFace = targetFaces[pick];
    // console.log(`pick: ${pick}`);
    //set rates
    faceTransData.rates[0] =
      (targetFace.xAmmount / animationState.current.ticksNeeded) *
      targetFace.xSign; // x
    faceTransData.rates[1] =
      (targetFace.yAmmount / animationState.current.ticksNeeded) *
      targetFace.ySign; //y
    faceTransData.rates[2] =
      (targetFace.zAmmount / animationState.current.ticksNeeded) *
      targetFace.zSign; // z
    // console.log(faceTransData);
  };

  const getSign = (current, target) => {
    return target > current ? 1 : -1;
  };

  const transitionUpdate = () => {
    const boxRef = boxMeshRef.current;
    const faceTransData = faceTransitionDataRef.current;
    let boxRotationDeg = getRotationInDeg(
      boxRef.rotation.x,
      boxRef.rotation.y,
      boxRef.rotation.z
    );
    let newRotRads = getRotationInRads(
      (boxRotationDeg[0] + faceTransData.rates[0]) % 360,
      (boxRotationDeg[1] + faceTransData.rates[1]) % 360,
      (boxRotationDeg[2] + faceTransData.rates[2]) % 360
    );
    boxRef.rotation.x = newRotRads[0];
    boxRef.rotation.y = newRotRads[1];
    boxRef.rotation.z = newRotRads[2];

    // console.log(animationState.current.elapsedTicks);
  };

  return (
    <Box
      ref={boxMeshRef}
      position={[0, 0, 0]}
      faceColor={"#cb8600"}
      frameColor={"white"}
      fill={true}
    />
  );
};

MainBox.propTypes = {
  mainBoxStateEnum: PropTypes.number.isRequired,
  onFaceTransitionEnd: PropTypes.func.isRequired,
  nextFaceDelay: PropTypes.number,
};

export { MainBox, MainBoxStateEnum };
