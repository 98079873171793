import * as React from "react";
import PropTypes from "prop-types";
import { useRecoilValue } from "recoil";
import { activeDifficultiesAtom, DifficultyEnum } from "../../recoil/activeCat";
import { dataLayerPush } from "../../components/utils/utils";
import hover from "../../audio/hover.mp3";
import start from "../../audio/start.mp3";
import { settingsAtom } from "../../recoil/settings";

function CategoryButtonTile({
  id,
  label,
  isChecked,
  onClick,
  supportedDifficulties,
  translate,
  complete,
  isDisabled,
  dailyCategory = null,
}) {
  const settings = useRecoilValue(settingsAtom);
  const [disable, setDisable] = React.useState(false);
  const activeDifficulties = useRecoilValue(activeDifficultiesAtom);

  const [unlockTime, setUnlockTime] = React.useState({
    hours: 0,
    mins: 0,
    secs: 0,
  });

  //init daily category, if any
  React.useEffect(() => {
    if (dailyCategory) {
      dailyCategory.init();

      if (!dailyCategory.hasReset()) {
        setDisable(true);
        const onInterval = () => {
          if (!dailyCategory.hasReset()) {
            let timeLeftMills = dailyCategory.getTimeLeft();
            let secs = parseInt((timeLeftMills / 1000) % 60);
            let mins = parseInt((timeLeftMills / 60000) % 60);
            setUnlockTime({
              hours: parseInt((timeLeftMills / (1000 * 60 * 60)) % 24),
              mins: mins < 10 ? `0${mins}` : mins,
              secs: secs < 10 ? `0${secs}` : secs,
            });
          } else {
            setDisable(false);
            clearInterval(dailyInterval);
          }
        };
        onInterval();
        let dailyInterval = setInterval(onInterval, 1000);
        return () => {
          clearInterval(dailyInterval);
        };
      }
    }
  }, []);

  React.useEffect(() => {
    setDisable(isDisabled);
  }, [isDisabled]);

  React.useEffect(() => {
    if (isDisabled) {
      return;
    }

    if (!dailyCategory) {
      let doDisable = true;
      activeDifficulties.forEach((e) => {
        if (supportedDifficulties.includes(e)) {
          doDisable = false;
        }
      });
      setDisable(doDisable);
    }
  }, [activeDifficulties]);

  const getDifficultyName = (difficultyEnum) => {
    switch (difficultyEnum) {
      case DifficultyEnum.easy:
        return "Easy";
      case DifficultyEnum.medium:
        return "Medium";
      case DifficultyEnum.hard:
        return "Hard";
    }
    return "";
  };

  const getDifficultyList = () => {
    return supportedDifficulties.map((e, index) => {
      return (
        <span
          key={e + index}
          className={`${
            activeDifficulties.includes(e)
              ? "opacity-100"
              : disable
              ? "opacity-100"
              : "opacity-30"
          }`}
        >
          {index != 0 ? "," : ""} {getDifficultyName(e)}
        </span>
      );
    });
  };

  return (
    <button
      onMouseEnter={() => {
        if (disable) {
          return;
        }
        if (isChecked) {
          return;
        }
        const sfx = new Audio(hover);
        sfx.volume = settings.volume;
        sfx.play();
      }}
      className={`
  block h-full transition-all duration-300  ${
    isChecked
      ? "bg-BOTC_LightBlue w-full text-white"
      : ` ${
          disable
            ? "cursor-default border-2 text-white"
            : "hover:w-full bg-white"
        }   w-full xl:w-4/5 text-black`
  }`}
      onClick={() => {
        if (!disable) {
          onClick();
          dataLayerPush("category_click", {
            category_name: label,
            category_id: id,
            checked: !isChecked,
            difficulty: activeDifficulties,
          });

          if (isChecked) {
            return;
          }
          const sfx = new Audio(start);
          sfx.volume = settings.volume;
          sfx.play();
        }
      }}
    >
      <div className="w-full h-full relative mb-1">
        <div
          className={`absolute h-full w-1  ${
            isChecked ? "bg-BOTC_Green" : `${disable ? "" : "bg-gray-600"}`
          }`}
        ></div>
        <div className=" h-full text-2xl font-bold pb-1 flex justify-center items-center">
          {disable && dailyCategory ? (
            <span>
              Next Daily:{" "}
              {`${unlockTime.hours}:${unlockTime.mins}:${unlockTime.secs}`}
            </span>
          ) : (
            <span translate={translate}>{label}</span>
          )}
        </div>
        <div
          className={`absolute font-bold bottom-0  text-sm ${
            isChecked ? "opacity-80" : "opacity-70"
          }`}
        >
          <span className=" ml-3 left-0">
            {/* <span className="hidden xl:inline">Difficulties: </span> */}
            {!dailyCategory && !isDisabled ? getDifficultyList() : "Any"}
          </span>
        </div>

        <div
          className={`absolute font-bold mr-3 bottom-0 right-0 text-sm ${
            isChecked ? "opacity-80" : "opacity-70"
          }`}
        >
          {complete < 1 && !dailyCategory && !isDisabled && (
            <span>Completed: {Math.round(complete * 100)}%</span>
          )}
        </div>
      </div>
    </button>
  );
}

CategoryButtonTile.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func,
  supportedDifficulties: PropTypes.array,
  translate: PropTypes.string,
  complete: PropTypes.number,
  isDisabled: PropTypes.bool,
  dailyCategory: PropTypes.object,
};

export default CategoryButtonTile;
