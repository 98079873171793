import * as React from "react";
import { useSetRecoilState } from "recoil";
import { GameStateEnum } from "../recoil/gameState";
import useGameStateSet from "./utils/useGameStateSet";
import { userAtom } from "../recoil/user";
import { getAuthTypeString } from "./utils/utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { dataLayerPush } from "./utils/utils";

import { Disclosure } from "@headlessui/react";
import TwitchChannelNamePanel from "./TwitchChannelNamePanel";
import TwitchAuthBtn from "./TwitchAuthBtn";
import Spinner from "./utils/Spinner";

function SignIn() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [panelActive, setPanelActive] = React.useState(false);

  const setUser = useSetRecoilState(userAtom);

  const gameStateSet = useGameStateSet();

  const userAuthed = (userData, token) => {
    console.log("User Authed and Verified");
    setUser({
      id: userData.id,
      access_token: token,
      display_name: userData.display_name,
      login: userData.login,
      viewer_count: userData.viewer_count,
      authType: userData.authType,
    });
    dataLayerPush("auth", {
      display_name: userData.display_name,
      viewer_count: `${userData.viewer_count}`,
      auth_type: getAuthTypeString(userData.authType),
    });
    gameStateSet(GameStateEnum.Authed);
  };

  return (
    <div>
      {isLoading && <Spinner size="3rem" />}

      <div className="w-13">
        <TwitchAuthBtn
          userAuthed={userAuthed}
          setIsLoading={setIsLoading}
          hidden={isLoading || panelActive}
        />

        <div className="my-1 flex flex-col justify-center">
          <Disclosure defaultOpen={true}>
            {({ open }) => (
              <>
                {!panelActive && !isLoading && (
                  <Disclosure.Button className="py-2">
                    <span className="text-stroke--white font-bold text-lg">
                      Other Options{" "}
                    </span>
                    {open ? (
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        style={{ fontSize: "1rem" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faChevronUp}
                        style={{ fontSize: "1rem" }}
                      />
                    )}
                  </Disclosure.Button>
                )}

                <Disclosure.Panel>
                  <div className="flex flex-col justify-center px-2">
                    <TwitchChannelNamePanel
                      onEnter={() => {
                        setPanelActive(true);
                      }}
                      onExit={() => {
                        setPanelActive(false);
                      }}
                      userAuthed={userAuthed}
                      setIsLoading={setIsLoading}
                      hideBtn={isLoading}
                      hidePanel={isLoading}
                    />
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
