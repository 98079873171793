//https://commonmark.org/help/
//https://www.npmjs.com/package/react-markdown#syntax
import * as React from "react";
import PropTypes from "prop-types";

import { Transition } from "@headlessui/react";
import ReactMarkdown from "react-markdown";

const Pages = ({ onBack, pageName }) => {
  const [displayContent, setDisplayContent] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setDisplayContent(true);
    }, 150);
  }, []);

  const getPage = () => {
    switch (pageName) {
      case "patchNotes":
        return <PatchNotes />;
      case "bugs/Ideas":
        return <BugsIdeas />;
      case "donate":
        break;
    }
  };

  return (
    <Transition
      show={displayContent}
      appear={true}
      className="w-full h-full"
      enter="transition-opacity duration-700"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={() => {
        onBack();
      }}
    >
      <div className="w-full h-full bg-BOTC_DarkBlue">
        {displayContent && (
          <>
            <button
              className="absolute border-2 hover:border-black bg-white text-black px-5 font-bold  mt-5 ml-2 text-lg"
              onClick={() => {
                setDisplayContent(false);
              }}
            >
              Back
            </button>
            {getPage()}
          </>
        )}
      </div>
    </Transition>
  );
};

Pages.propTypes = {
  onBack: PropTypes.func.isRequired,
  pageName: PropTypes.string,
};

const PatchNotes = () => {
  const markdown = `
# 1.2.0 (2023-04-23)
- Lowered time bonus
- Top 15 will accounted for total score for all channels
- Added a minority bonus. If less than half of the guesses are correct (48%) everyone get a bonus. Even more if lower. (Thanks, NymN)
- Added penalty scoring. If a guess is incorrect, you lose some points. Little more for how fast you guessed. Your score will never go less than zero.
- Adjusted Live Streamers (Hard)
- Adjusted Brand Logos Guess options
- Added a little more words to Video Game OST and Country Flags
- Adjusted End Animation

# 1.1.2 (2023-04-20)
- Lowered audio segments. Now there is only 3 segments for an audio clip.
- Adjusted stream delay countdown sfx
- Music now keeps playing during stream delay countdown

# 1.1.1 (2023-04-19)
- Lowered Round Timer
- Lowered End Delay Timer
- Fixed clear save not clearing everything
- Minor Adjustments to looks

# 1.1.0 (2022-12-04)
- Added 2000's Music category
- Added panel of precentages for each option in round results
- Streamers score is now a part of the leaderboard
- Added/Moved a streamer's current placement and score to guess window
- Moved exit button to settings during gameplay (A lot of you like looking there, so there it is!)
- Fixed rounds and timer being loaded from saves (This fix is for people who played the beta, sorry about that)
- Couple of Style Adjustments
- Fixed layout issues on small and big window sizes
- Fixed total score calculations being incorrect

# 1.0.1 (2022-11-09)
- Adjusted score calculation
- Fixed streamer guess not resetting on a new round
- Minor Style adjustments
- Made certain text and buttons more clearer

# 1.0.0 (2022-10-25)
- Full Release!

# Beta 1.0 (2022-06-15)
- Public Beta Release

`;
  return (
    <div className="w-full h-full flex flex-col justify-start items-center">
      <h1 className="text-4xl font-bold mb-5 mt-5">Patch Notes</h1>
      <ReactMarkdown className="markdown overflow-y-scroll px-12">
        {markdown}
      </ReactMarkdown>
      <div className="h-20"></div>
    </div>
  );
};

const BugsIdeas = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center pt-5 overflow-y-scroll">
      <h2 className="text-4xl font-bold">Have a bug or an idea?</h2>
      <span>
        You can summit a github issue{" "}
        <a
          aria-label="Link to github issues"
          href="https://github.com/lejara/BattleOfTheChat/issues"
          target="_blank"
          rel="noopener noreferrer"
          className="inline underline"
        >
          here
        </a>
      </span>
    </div>
  );
};

export default Pages;
