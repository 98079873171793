import { category_modules, WordObject } from "./category_modules";
import { DifficultyEnum } from "../recoil/activeCat";
import { Category, CategoryModObj } from "./categories";
import { dailyResetHourUTC, bypassDailyTrivaTimer } from "../api";

const fullDayInMills = 86400000;

class DailyCategory extends Category {
  rounds: number;
  timer_secs: number;
  nextResetDate: Date;
  lastPlayed: Date;

  resetPassed: boolean;
  constructor(
    id: number,
    name: string,
    categoryModules: Array<CategoryModObj>,
    rounds: number,
    timer_secs: number,
    overrideQuestion: string = null,
    translateTitle = "yes"
  ) {
    super(
      id,
      name,
      categoryModules,
      [DifficultyEnum.easy, DifficultyEnum.medium, DifficultyEnum.hard],
      overrideQuestion,
      translateTitle
    );
    this.rounds = rounds;
    this.timer_secs = timer_secs;
  }

  init() {
    //Calc coming reset
    const nextResetDate = new Date();
    nextResetDate.setUTCMinutes(0);
    nextResetDate.setUTCSeconds(0);
    nextResetDate.setUTCMilliseconds(0);
    nextResetDate.setUTCHours(dailyResetHourUTC);
    if (new Date().getTime() >= nextResetDate.getTime()) {
      nextResetDate.setDate(nextResetDate.getDate() + 1);
    }
    this.nextResetDate = nextResetDate;
    //check if last played has passed the reset timer
    this.getLastPlayed();
    if (this.lastPlayed && !bypassDailyTrivaTimer) {
      this.resetPassed =
        nextResetDate.getTime() - this.lastPlayed.getTime() >= fullDayInMills;
    } else {
      this.resetPassed = true;
    }
  }

  getTimeLeft(): number {
    return this.nextResetDate.getTime() - new Date().getTime();
  }

  hasReset(): boolean {
    return (
      this.resetPassed ||
      this.nextResetDate.getTime() - new Date().getTime() <= 0
    );
  }

  getLastPlayed(): Date {
    const store = localStorage.getItem(`daily_${this.id}_lastPlayed`);
    if (store) {
      this.lastPlayed = new Date(JSON.parse(store));
    } else {
      this.lastPlayed = null;
    }

    return this.lastPlayed;
  }

  saveLastPlayed(): void {
    const newDate = new Date();
    localStorage.setItem(
      `daily_${this.id}_lastPlayed`,
      JSON.stringify(newDate)
    );
  }

  getWords(): WordObject[] {
    return super.getWords([
      DifficultyEnum.easy,
      DifficultyEnum.medium,
      DifficultyEnum.hard,
    ]);
  }
}

const dailyCategory = new DailyCategory(
  100,
  "Daily Triva",
  [
    {
      supportedDifficulties: [
        DifficultyEnum.easy,
        DifficultyEnum.medium,
        DifficultyEnum.hard,
      ],
      categoryModule: category_modules[0],
    },
  ],
  1,
  50
);

export { DailyCategory };
export default dailyCategory;
