import React from "react";
import BOTCDialog from "./utils/BOTCDialog";

import { useResetRecoilState, useRecoilState } from "recoil";
import {
  ErrorLevelEnum,
  errorStateAtom,
  ErrorTypeEnum,
} from "../recoil/errorState";
import { dataLayerPush } from "./utils/utils";

//report all js errors
if (typeof window !== "undefined" && typeof window.onerror === "object") {
  window.onerror = function (err, url, line) {
    dataLayerPush("js_errors", { error_msg: line + " " + err });
  };
}

const ErrorDialog = () => {
  const [errorState, setErrorState] = useRecoilState(errorStateAtom);
  const resetErrorState = useResetRecoilState(errorStateAtom);

  React.useEffect(() => {
    if (errorState.show) {
      dataLayerPush("error_displayed", {
        error_msg: errorState.message,
        error_level: errorState.errorLevelEnum,
        error_type: errorState.errorTypeEnum,
      });
    }
  }, [errorState.show]);

  const fatalDialog = () => {
    return (
      <BOTCDialog
        show={errorState.show}
        title="Oh No, Big Problem"
        divClass="bg-red-900"
      >
        <div className="mt-2">
          <p className="text-base text-zinc-200">{errorState.message}</p>
        </div>

        <div className="mt-4">
          <button
            type="button"
            className="inline-flex justify-center px-4 py-2 font-medium bg-glass rounded-sm"
            onClick={() => {
              setErrorState({ ...errorState, show: false, message: "" });
            }}
          >
            Close
          </button>
        </div>
      </BOTCDialog>
    );
  };

  const infoDialog = () => {
    return (
      <BOTCDialog
        show={errorState.show}
        title="Small..hiccup"
        divClass="bg-blue-900"
      >
        <div className="mt-2">
          <p className="text-base text-zinc-200">{errorState.message}</p>
        </div>

        <div className="mt-4">
          {errorState.errorTypeEnum !== ErrorTypeEnum.chatReconnect && (
            <button
              type="button"
              className="inline-flex justify-center px-4 py-2 font-medium bg-glass rounded-sm"
              onClick={() => {
                resetErrorState();
              }}
            >
              Close
            </button>
          )}
        </div>
      </BOTCDialog>
    );
  };

  const getDialogType = () => {
    if (errorState.errorLevelEnum === ErrorLevelEnum.FATAL) {
      return fatalDialog();
    } else {
      return infoDialog();
    }
  };

  return getDialogType();
};
export default ErrorDialog;
