import * as React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { activeDifficultiesAtom, DifficultyEnum } from "../../recoil/activeCat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { settingsAtom } from "../../recoil/settings";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { dataLayerPush } from "../utils/utils";
import hover from "../../audio/hover.mp3";

const DifficultyDropdown = () => {
  const [activeDifficulties, setActiveDifficulties] = useRecoilState(
    activeDifficultiesAtom
  );
  const settings = useRecoilValue(settingsAtom);

  // const toggle = (difficultyEnum) => {
  //   let newAD = [];
  //   let doRemove = activeDifficulties.includes(difficultyEnum);
  //   if (!doRemove) {
  //     newAD.push(difficultyEnum);
  //   }
  //   activeDifficulties.forEach((e) => {
  //     if (!doRemove) {
  //       newAD.push(e);
  //     } else if (difficultyEnum !== e) {
  //       newAD.push(e);
  //     }
  //   });
  //   setActiveDifficulties(newAD);
  // };

  const select = (difficultyEnum) => {
    if (activeDifficulties.includes(difficultyEnum)) {
      setActiveDifficulties([]);
      dataLayerPush("difficulty_click", {
        difficulty: [difficultyEnum],
        checked: false,
      });
    } else {
      setActiveDifficulties([difficultyEnum]);
      dataLayerPush("difficulty_click", {
        difficulty: [difficultyEnum],
        checked: true,
      });
    }
  };

  const tileButton = (name, difficultyEnum) => {
    return (
      <button
        onMouseEnter={() => {
          if (activeDifficulties.includes(difficultyEnum)) {
            return;
          }
          const sfx = new Audio(hover);
          sfx.volume = settings.volume;
          sfx.play();
        }}
        onClick={() => {
          select(difficultyEnum);
        }}
        className={`font-bold p-2 transition-all duration-300  ${
          activeDifficulties.includes(difficultyEnum)
            ? "bg-BOTC_LightBlue text-white w-4/5"
            : "bg-white text-black w-3/5 hover:w-4/5"
        }`}
      >
        <div className="flex justify-between">
          <span />
          <span> {name}</span>

          <span>
            {activeDifficulties.includes(difficultyEnum) && (
              <FontAwesomeIcon icon={faCheck} />
            )}
          </span>
        </div>
      </button>
    );
  };

  return (
    <div className="mx-auto w-3/6 md:w-3/12 lg:w-3/6 xl:w-2/6 min-h-min lg:h-full pt-12">
      <div className="flex flex-col items-center lg:items-start gap-2 mt-5 lg:mt-0">
        {tileButton("Easy", DifficultyEnum.easy)}
        {tileButton("Medium", DifficultyEnum.medium)}
        {tileButton("Hard", DifficultyEnum.hard)}
      </div>
    </div>
  );
};

export default DifficultyDropdown;
