let client_id_twitch = "6lgsgjtymrs8mvmumg1j50ueuyykc5";
let redirect_uri_twitch =
  "http://localhost:8000/auth/?t=RG9yaW1lSW50ZXJpbW9hZGFwYXJlRG9yaW1lQW1lbm9BbWVub0xhdGlyZUxhdGlyZW1vRG9yaW1lQW1lbm9PbWVuYXJlaW1wZXJhdmlhbWVub0RpbWVyZWRpbWVyZW1hdGlyb01hdGlyZW1vQW1lbm9PbWVuYXJlaW1wZXJhdmllbXVsYXJpYW1lbm9PbWVuYXJlaW1wZXJhdmllbXVsYXJpYW1lbm9BbWVub2RvcmVBbWVub2RvcmltZUFtZW5vZG9yaW1lQW1lbm9kb21Eb3JpbWVyZW9BbWVub2RvcmltZUFtZW5vZG9yaW1lRG9yaW1lYW1BbWVub09tZW5hcmVpbXBlcmF2aWFtZW5vRGltZXJlZGltZXJlbWF0aXJvTWF0aXJlbW9BbWVub09tZW5hcmVpbXBlcmF2aWVtdWxhcmlhbWVub09tZW5hcmVpbXBlcmF2aWVtdWxhcmlhbWVub0FtZW5vZG9yZUFtZW5vZG9yaW1lQW1lbm9kb3JpbWVBbWVub2RvbURvcmltZXJlb0FtZW5vZG9yaW1lQW1lbm9kb3JpbWVEb3JpbWVBbWVub2RvcmltZUFtZW5vZG9yaW1lRG9yaW1lQW1lbm9BbWVub2RvcmVBbWVub2RvcmltZUFtZW5vZG9yaW1lQW1lbm9kb21Eb3JpbWVyZW9BbWVub2RvcmltZUFtZW5vZG9yaW1lRG9yaW1lZG9tQW1lbm9kb3JlQW1lbm9kb3JpbWVBbWVub2RvcmltZUFtZW5vZG9tRG9yaW1lcmVvQW1lbm9kb3JpbWVEb3JpbWVJbnRlcmltb2FkYXBhcmVEb3JpbWVBbWVub0FtZW5vTGF0aXJlTGF0aXJlbW9Eb3JpbWVBbWVub09tZW5hcmVpbXBlcmF2aWFtZW5vRGltZXJlZGltZXJlbWF0aXJvTWF0aXJlbW9BbWVub09tZW5hcmVpbXBlcmF2aWVtdWxhcmlhbWVub09tZW5hcmVpbXBlcmF2aWVtdWxhcmlhbWVub0FtZW5vZG9yZUFtZW5vZG9yaW1lQW1lbm9kb3JpbWVBbWVub2RvbURvcmltZXJlb0FtZW5vZG9yaW1lQW1lbm9kb3JpbWVEb3JpbWVhbUFtZW5vQW1lbm9kb3JlQW1lbm9kb3JpbWVBbWVub2RvcmltZUFtZW5vZG9tRG9yaW1lcmVvQW1lbm9kb3JpbWVBbWVub2RvcmltZURvcmltZWFtQW1lbm9BbWVub2RvcmVBbWVub2RvcmltZUFtZW5vZG9yaW1lQW1lbm9kb21Eb3JpbWVyZW9BbWVub2RvcmltZUFtZW5vZG9yaW1lRG9yaW1lYW1BbWVub0FtZW5vZG9yZUFtZW5vZG9yaW1lQW1lbm9kb3JpbWVBbWVub2RvbURvcmltZXJlb0FtZW5vZG9yaW1lQW1lbm9kb3JpbWVEb3JpbWVhbUFtZW5vRG9yaW1lcmVvQW1lbm9kb3JpbWVBbWVub2RvcmltZURvcmltZWFtQW1lbm9BbWVub2RvcmVBbWVub2RvcmltZUFtZW5vZG9yaW1lQW1lbm9kb21BbWVub2RvcmltZURvcmltZWFtQW1lbm9BbWVub2RvcmVBbWVub2RvcmltZQ";
/**
 * Reset hour for daily categories
 */
const dailyResetHourUTC = 12;
/**
 * Wll always assume the daily triva reset time has passed
 */
let bypassDailyTrivaTimer = false;
/**
 * Debug tools for recoil atoms
 */
let debugTools = false;
/**
 * List all words in their guess view in selection view. Shown to the select category. Beware lag.
 */
let debugCategory = false;
/**
 * Show all words, no matter the difficulty selected (Warning: Lag)
 */
let debugCategoryShowAll = true;
/**
 * If not null, will limit the ammount of words to display in debug cateogry view
 */
let debugCategoryMaxWords = null;
/**
 * Allows all guesses to be right and ignores commandPrefix
 */
let bypassPrefix = false;
/**
 * bypass the need to connect to a chat and sign in
 */
let bypassChatRequirement = false;
/**
 * Override timer to this. null for false
 */
let timerOverride = null;
/**
 * JOIN a different channel then what is signed in. Chat only.
 */
let debugChannel = "";
/**
 * use CDN for assets first
 */
let useCDNLinks = true;
/**
 * Skips word shuffling
 */
let skipShuffle = false;
/**
 * Use debug settings in a production build
 */
let useDebugInProduction = false;
/**
 * Base url to access static assets
 */
let staticAssetsPath = "/category";
/**
 * Adds a dummy category, useful for testing
 */
let dummyCategory = false;
/**
 * Force add a word skip button
 */
let skipWords = false;

/**
 * Do Not Edit
 */
if (process.env.NODE_ENV === "production" && !useDebugInProduction) {
  staticAssetsPath = "/category";
  client_id_twitch = "200uubp05p2tnr8ldrj7bq6f9dzmza";
  redirect_uri_twitch =
    "https://battleofthechat.net/auth/?t=RG9cmVub09tZW5hcmVpbXBlcmF2aWVtdWxhcmlhbWVyaW1lQW1lbm9kb3JpbWVBbWVub2RvbURvcmltZXJlb0FtZW5vZG9yaW1lQW1lbm9kb3JpbWVEb3JpbWVhbUFtZW5vQW1lbm9kb3JlQW1lbm9kb3JpbWVBbWVub2RvcmltZUFtZW5vZG9tRG9yaW1lcmVvQW1lbm9kb3JpbWVBbWVub2RvcmltZURvcmltZWFtQW1lbm9BbWVub2RvcmVBbWVub2RvcmltZUFtZW5vZG9yaW1lQW1lbm9kb21Eb3JpbWVyZW9BbWVub2RvcmltZUFtZW5vZG9yaW1lRG9yaW1lYW1BbWVub0FtZW5vZG9yZUFtZW5vZG9yaW1lQW1lbm9kb3JpbWVBbbm9kb3JpbWVBbWVub2RvcmltZURvcmltZWFtQW1lbm9BbWVub2RvcmVBbWVub2RvcmltZUFtZW5vZG9yaW1lQW1lbm9kb21BbWVub2RvcmltZURvcmltZWFtQW1lbm9BbWVub2RvcmVBbWVub2RvcmltZQ";
  debugChannel = "";
  skipShuffle = false;
  debugTools = false;
  bypassPrefix = false;
  debugCategory = false;
  bypassChatRequirement = false;
  dummyCategory = false;
  bypassDailyTrivaTimer = false;
  skipWords = false;
  debugCategoryShowAll = false;
  debugCategoryMaxWords = null;
  timerOverride = null;
}

export {
  client_id_twitch,
  redirect_uri_twitch,
  dailyResetHourUTC,
  bypassDailyTrivaTimer,
  debugChannel,
  skipShuffle,
  debugTools,
  staticAssetsPath,
  bypassPrefix,
  useCDNLinks,
  debugCategory,
  bypassChatRequirement,
  dummyCategory,
  skipWords,
  debugCategoryShowAll,
  timerOverride,
  debugCategoryMaxWords,
};
