import * as React from "react";

import { Canvas } from "@react-three/fiber";
import { useFrame, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei/core";
import Box from "./Box";

const PlaceholderCanvas = () => {
  return (
    <Canvas mode="concurrent" camera={{ zoom: 2, position: [0, -4, 3] }}>
      <ambientLight />
      <OrbitControls enableZoom={false} enablePan={false} />
      <BoxAnimation />
    </Canvas>
  );
};

const BoxAnimation = () => {
  const boxRef = React.useRef();
  const { camera, gl } = useThree();

  React.useEffect(() => {
    boxRef.current.rotation.x = 0.4;
    const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;
    gl.setPixelRatio(DPR);
    camera.lookAt(0, 0, 0);
    camera.updateProjectionMatrix();
  }, []);

  useFrame((state, delta) => {
    boxRef.current.rotation.y += 0.4 * delta;
  });

  return (
    <Box
      ref={boxRef}
      position={[0, 0, 0]}
      fill={true}
      faceColor={"#cb8600"}
      frameColor={"white"}
    />
  );
};

export default PlaceholderCanvas;
