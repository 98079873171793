import * as React from "react";
import PropTypes from "prop-types";

import { useRecoilValue } from "recoil";
import { settingsAtom } from "../../recoil/settings";

import ProgressBar from "./ProgressBar";

import Timer from "./Timer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";

const AudioStateEnum = Object.freeze({ notPlayed: 0, stopped: 1, playing: 2 });

const timeSegmentsInSecs = [0.25, 1.2, "full"]; // duration of audio clip in seconds
const timeUnlockPercentages = ["onMount", 0.8, 0.55]; // Normalized time

const AudioPlayer = ({ wordInPlay, objectURL, timer_secs, debug = false }) => {
  const settings = useRecoilValue(settingsAtom);

  const [audio, setAudio] = React.useState(new Audio());
  const [startAt, setStartAt] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const [audioState, setAudioState] = React.useState(AudioStateEnum.notPlayed);

  const [doSegments, setDoSegments] = React.useState(false);
  const [totalTime, setTotalTime] = React.useState(null);
  const [segmentsTrack, setSegmentsTrack] = React.useState([]);
  const [maxProgress, setMaxProgress] = React.useState(100);

  const [timerKey, setTimerKey] = React.useState("Timer1");

  const audioIntervalRef = React.useRef();
  const maxProgressRef = React.useRef();

  const fadeEndIntervalRef = React.useRef();
  maxProgressRef.current = maxProgress;

  //init
  React.useEffect(() => {
    let newAudio = new Audio(objectURL);
    setAudio(newAudio);

    //Check do segments
    if (wordInPlay.chosenAsset.segmentAudio === true) {
      setDoSegments(true);
      if (!debug) {
        // allow audio instance to propagate
        newAudio.onloadedmetadata = (event) => startSegments(event.target);
      }
    }

    //Check start at
    if (wordInPlay.chosenAsset.startAt) {
      setStartAt(wordInPlay.chosenAsset.startAt);
    } else {
      setStartAt(0);
    }

    return () => clear();
  }, []);

  const startSegments = (newAudio) => {
    let newSegmentData = [];

    if (totalTime === null) {
      setTotalTime(timer_secs);
    }

    timeSegmentsInSecs.forEach((segmentTime, index) => {
      let setOnMount = timeUnlockPercentages[index] === "onMount";

      newSegmentData.push({
        progressTarget:
          segmentTime !== "full"
            ? Math.round(
                (segmentTime / getAudioDurationWithTrim(newAudio)) * 100
              )
            : 100,
        timeToUnlock: timeUnlockPercentages[index],
        unlocked: setOnMount,
      });

      if (setOnMount) {
        setMaxProgress(
          Math.round((segmentTime / getAudioDurationWithTrim(newAudio)) * 100)
        );
      }
    });

    setSegmentsTrack(newSegmentData);
  };

  function audiofadeEnd(sound, startVolume) {
    if (fadeEndIntervalRef.current) {
      clearInterval(fadeEndIntervalRef.current);
    }
    // console.log("end fade tracking");
    // Set the point in playback that fadeout begins. This is for a 2 second fade out.
    var fadeDurationSecs = 0.8;
    var fadeStart = sound.duration - fadeDurationSecs;
    var decay = 0.01;
    var intervalTime = (fadeDurationSecs * 1000) / (startVolume / decay);
    var fadeAudio = setInterval(function () {
      // console.log(sound.volume);
      // Only fade if past the fade out point or not at zero already
      if (sound.currentTime >= fadeStart && sound.volume > 0.01) {
        sound.volume -= 0.01;
      }
      // When volume at zero stop all the intervalling
      if (sound.volume < 0.01) {
        sound.volume = startVolume;
        clearInterval(fadeAudio);
      }
    }, intervalTime);
    fadeEndIntervalRef.current = fadeAudio;
  }

  //volume change
  React.useEffect(() => {
    if (audio) {
      audio.volume = settings.volume;
    }
  }, [settings.volume]);

  //auto play
  React.useEffect(() => {
    if (audio.src.length > 0 && !debug) {
      setTimeout(() => {
        play();
      }, 500);
    }
  }, [audio]);

  const play = () => {
    audio.currentTime = startAt;
    audio.volume = settings.volume;

    clear();
    setProgress(0);

    setTimeout(() => {
      audio.play();
      audiofadeEnd(audio, audio.volume);
    }, 50);

    //start audio ticker
    audioIntervalRef.current = setInterval(() => {
      audioTick();
    }, 50);

    //Update audio state
    setAudioState(AudioStateEnum.playing);
  };

  const audioTick = () => {
    if (audio.currentTime >= audio.duration) {
      stopped();
    }
    let currentProgress = Math.round(
      ((audio.currentTime - startAt) / getAudioDurationWithTrim(audio)) * 100
    );
    setProgress(currentProgress);
    segmentTrack(currentProgress);
  };

  const segmentTrack = (progress) => {
    if (progress >= maxProgressRef.current) {
      stop();
    }
  };

  const stop = () => {
    audio.pause();
    stopped();
  };

  const stopped = () => {
    clear();
    setAudioState(AudioStateEnum.stopped);
  };

  const clear = () => {
    // Allow audio to play during end round delay
    // if (audio) {
    //   // audio.pause();
    // }
    if (audioIntervalRef.current) {
      clearInterval(audioIntervalRef.current);
    }

    if (fadeEndIntervalRef.current) {
      clearInterval(fadeEndIntervalRef.current);
    }
  };

  const getAudioDurationWithTrim = (audio) => {
    return audio.duration - startAt;
  };

  const debugSegmentsUI = () => {
    return (
      <div className="absolute left-0 top-0 text-left text-xs text-white px-2">
        {startAt != null && (
          <div>
            <span>Start At: </span>
            <input
              className="bg-gray-200 text-black w-12 mb-1"
              type="number"
              min="0"
              defaultValue={startAt}
              onChange={(event) => {
                setStartAt(
                  event.target.value.length !== 0
                    ? parseFloat(event.target.value)
                    : 0
                );
              }}
            />
          </div>
        )}
        <br />
        <span className="block">
          Do Segments: {doSegments ? "true" : "false"}
        </span>
        {!doSegments ? (
          <button
            onClick={() => {
              setDoSegments(true);
            }}
            className="bg-black p-1"
          >
            Force Segment
          </button>
        ) : (
          <>
            <span className="">Timer: {totalTime}</span>
            <br />
            <button
              onClick={() => {
                //Force a timer remount
                setTimerKey(timerKey === "Timer1" ? "Timer2" : "Timer1");
                startSegments(audio);
              }}
              className="bg-black p-1"
            >
              Start Segments
            </button>
            <br />
            <button
              onClick={() => {
                setTotalTime(25);
              }}
              className="bg-black p-1"
            >
              Set Segments Timer 25secs
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="relative w-full">
      {debug && debugSegmentsUI()}
      {doSegments && totalTime && (
        <Timer
          key={timerKey}
          hideContent={true}
          timeInSecs={totalTime}
          onTick={(secs) => {
            let remainingPercentage = secs / totalTime;
            let newSegmentTrack = segmentsTrack;

            segmentsTrack.forEach((segmentData, index) => {
              if (
                segmentData.timeToUnlock >= remainingPercentage &&
                !segmentData.unlocked
              ) {
                newSegmentTrack[index].unlocked = true;
                setMaxProgress(segmentData.progressTarget);

                if (debug) {
                  console.log(
                    `Unlocked New Target: ${segmentData.progressTarget}`
                  );
                }
              }
              if (debug) {
                // console.log(remainingPercentage);
              }
            });
          }}
        />
      )}

      <div className="flex justify-center items-center pb-4">
        {(audioState === AudioStateEnum.stopped ||
          audioState === AudioStateEnum.notPlayed) && (
          <button
            onClick={() => {
              play();
            }}
            className="text-8xl text-white"
          >
            <FontAwesomeIcon icon={faPlay} />
          </button>
        )}

        {audioState === AudioStateEnum.playing && (
          <button
            onClick={() => {
              stop();
            }}
            className="text-8xl text-white"
          >
            <FontAwesomeIcon icon={faStop} />
          </button>
        )}
      </div>

      <div
        style={{ transform: "translate(-50%)" }}
        className="w-4/5 sm:w-2/5 relative left-1/2 mt-3 h-8"
      >
        {segmentsTrack.map((segmentData, index) => {
          return (
            segmentData.progressTarget != 100 && (
              <div
                key={`segments-${index}-${segmentData.progressTarget}`}
                style={{
                  marginLeft: `${segmentData.progressTarget}%`,
                }}
                className="absolute bg-BOTOC_DarkOrange h-full z-20 w-1"
              ></div>
            )
          );
        })}

        <div
          style={{ width: `${maxProgress > 0 ? 100 - maxProgress : 0}%` }}
          className={`h-full bg-BOTC_Black absolute right-0 z-10 ${
            audioState !== AudioStateEnum.notPlayed && "transition-all"
          }  duration-500`}
        ></div>
        <ProgressBar
          completed={progress}
          maxCompleted={100}
          bgColor="#00520e"
          height="100%"
          width="100%"
          borderRadius="0"
          isLabelVisible={false}
          transitionTimingFunction="linear"
          transitionDuration="100ms"
        />
      </div>
    </div>
  );
};

AudioPlayer.propTypes = {
  wordInPlay: PropTypes.object.isRequired,
  objectURL: PropTypes.string.isRequired,
  timer_secs: PropTypes.number.isRequired,
  debug: PropTypes.bool,
};

export default AudioPlayer;
