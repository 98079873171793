import * as React from "react";
import SignIn from "./SignIn";

import Logo from "./utils/Logo";
import useWindowDimensions from "./utils/useWindowDimensions";

import DTC2_Logo from "../images/DTC2_Logo.png";

function Landing() {
  const { height: window_height } = useWindowDimensions();
  return (
    <div
      className={` relative ${
        window_height >= 675 ? "h-screen" : "h-170"
      } flex flex-col justify-start items-center gap-y-20`}
    >
      <span className="block text-center text-whitefont-bold text-2xl absolute bottom-1 left-2 opacity-80">
        {"v1.2.0"}
      </span>
      <div className="flex  justify-between items-center flex-col h-full">
        <div className=" justify-self-start">
          <h1 className="font-bold pt-5">
            <Logo size={280} />
          </h1>
          <span className="block text-center font-bold text-2xl text-BOTC_Autom_Orange  text-stroke--white">
            Guessing Game For Streaming
          </span>
        </div>
        <div className="justify-self-center">
          <SignIn />
        </div>
        <div className="h-44 flex-shrink">
          <div className="text-center font-bold text-lg justify-self-center  md:absolute top-4 right-4">
            <h2 className="text-gray-300 text-stroke--white text-2xl mb-2">
              Other Games
            </h2>
            <a
              className=" text-stroke--white text-white text-sm hover:underline"
              href=" https://dastreamcharades.net"
            >
              <span>Da Stream Charades</span>
              <img
                src={DTC2_Logo}
                height={22}
                width={22}
                className=" ml-1 inline"
              ></img>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
