import React from "react";
import PropTypes from "prop-types";
import cryptoRandomString from "crypto-random-string";
import { getTwitchChannelData } from "./utils/utils";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { client_id_twitch, redirect_uri_twitch, debugChannel } from "../api";

import NewWindow from "react-new-window";
import { useSetRecoilState } from "recoil";
import { errorStateAtom, ErrorLevelEnum } from "../recoil/errorState";
import { AuthTypeEnum } from "../recoil/user";

const TwitchAuthBtn = ({ userAuthed, setIsLoading, hidden }) => {
  const [hasPostedAuth, setHasPostedAuth] = React.useState(false);
  const [showWindow, setShowWindow] = React.useState(false);

  const setErrorState = useSetRecoilState(errorStateAtom);

  const windowRef = React.useRef();

  React.useEffect(() => {
    window.PostAuthData = (hash) => {
      setHasPostedAuth(true);
      setShowWindow(false);
      OnAuthTwitch(hash);
    };
  }, []);

  //close sign in window on site exit
  if (typeof window !== "undefined") {
    window.onbeforeunload = function () {
      if (showWindow) {
        windowRef.current.window.close();
      }
    };
  }

  const onWindowUnload = () => {
    if (!hasPostedAuth) {
      setIsLoading(false);
    }
    setShowWindow(false);
  };
  const onWindowBlock = () => {
    setErrorState({
      show: true,
      message:
        "Could not open sign in window. Make sure to allow popups for this site.",
      errorLevelEnum: ErrorLevelEnum.FATAL,
    });
  };
  //convert hash to a useable object structure
  const parseHash = (hash) => {
    hash = hash.substr(1);
    let hashData = hash
      .split("&")
      .map((v) => v.split("="))
      .reduce(
        (pre, [key, value]) => ({
          ...pre,
          [key]: value,
        }),
        {}
      );
    return hashData;
  };

  const checkState = (state) => {
    let local_state = null;
    try {
      local_state = localStorage.getItem("state");
    } catch (err) {
      console.warn(err);
    }

    if (local_state && state) {
      if (local_state == state) {
        return true;
      } else {
        console.error("mismatch states");
      }
    } else {
      console.error("state null");
    }
    return false;
  };

  const OnAuthTwitch = (hash) => {
    let hashData = parseHash(hash);
    if (hashData && checkState(hashData.state) && hashData.access_token) {
      //Get user data
      getTwitchChannelData(
        hashData.access_token,
        client_id_twitch,
        debugChannel ? debugChannel : null
      )
        .then((res) => {
          userAuthed(
            { ...res, authType: AuthTypeEnum.Twitch },
            hashData.access_token
          );
        })
        .catch((e) => {
          setHasPostedAuth(false);
          setIsLoading(false);
          setErrorState({
            show: true,
            message: `Was not able to sign in. ${e}`,
            errorLevelEnum: ErrorLevelEnum.FATAL,
          });
        });
    } else {
      setErrorState({
        show: true,
        message: "Was not able to sign you in.",
        errorLevelEnum: ErrorLevelEnum.FATAL,
      });
      setHasPostedAuth(false);
      setIsLoading(false);
    }
  };
  return (
    <>
      {showWindow && (
        <NewWindow
          ref={windowRef}
          url={`https://id.twitch.tv/oauth2/authorize?client_id=${client_id_twitch}&redirect_uri=${redirect_uri_twitch}&response_type=token&scope=chat:read&state=${localStorage.getItem(
            "state"
          )}`}
          title={"Battle Of The Chat - Sign In"}
          features={{
            width: "760px",
            height: "450px",
            menubar: "no",
            location: "no",
            directories: "no",
            status: "no",
            resizable: "no",
            popup: "yes",
          }}
          onBlock={() => {
            onWindowBlock();
          }}
          onUnload={() => {
            onWindowUnload();
          }}
        ></NewWindow>
      )}
      {!hidden && (
        <button
          label="Connect with Twitch"
          onClick={() => {
            let state = cryptoRandomString({
              length: 25,
              type: "url-safe",
            });
            try {
              localStorage.setItem("state", state);
              setIsLoading(true);
              setShowWindow(true);
            } catch (err) {
              setIsLoading(false);
              setShowWindow(false);
              setErrorState({
                show: true,
                message: `Your browser needs to allow for cookies/localstorage. Will not sign in other wise. ${err}`,
                errorLevelEnum: ErrorLevelEnum.FATAL,
              });
            }
          }}
        >
          <div className="bg-TwitchPurple h-12 flex gap-2 w-44 justify-center items-center">
            <span className="text-2xl">
              <FontAwesomeIcon icon={faTwitch} />
            </span>
            <span className="font-bold text-xl">Connect</span>
          </div>
        </button>
      )}
    </>
  );
};

TwitchAuthBtn.propTypes = {
  setIsLoading: PropTypes.func,
  userAuthed: PropTypes.func,
  hidden: PropTypes.bool,
};

export default TwitchAuthBtn;
