import * as React from "react";
import "../scss/globals.scss";

import { RecoilRoot } from "recoil";
import Layout from "../components/Layout";

import MainContent from "../components/MainContent";

const IndexPage = () => {
  return (
    <Layout>
      <div style={{ minWidth: "450px", minHeight: "800px" }}>
        <RecoilRoot>
          <MainContent />
        </RecoilRoot>
      </div>
    </Layout>
  );
};

export default IndexPage;
