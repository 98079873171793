import * as React from "react";
import PropTypes from "prop-types";

function OptionTile({ commandString, word, translate = "yes" }) {
  return (
    <div className="bg-BOTC_DarkBlue border-2 border-BOTC_Black h-full w-full rounded-md">
      <div className="relative w-full h-full flex">
        <span
          translate="no"
          className="h-full bg-BOTC_LightBlue text-white w-12 text-lg lg:text-xl xl:text-3xl font-bold flex justify-center items-center"
        >
          {commandString}
        </span>
        <span
          className="flex w-full h-full justify-center items-center text-2xl font-bold text-center break-all sm:break-normal px-1"
          translate={translate}
        >
          {word}
        </span>
      </div>
    </div>
  );
}

OptionTile.propTypes = {
  commandString: PropTypes.string.isRequired,
  word: PropTypes.string.isRequired,
  translate: PropTypes.string,
};

export default OptionTile;
