import React from "react";
import PropTypes from "prop-types";

import countdown from "../../audio/countdown.mp3";
import Timer from "../utils/Timer";

const EndDelayCountdown = ({
  settings,
  onRoundEnd,
  totalCorrect,
  totalIncorrect,
}) => {
  return (
    <div className="h-full flex justify-center items-center text-center opacity-div--spawn">
      <h1 className="text-4xl font-bold">
        Accounting for Stream Delay...
        <Timer
          timeInSecs={settings.stream_delay_secs_end}
          onEnd={onRoundEnd}
          onTick={() => {
            const sfx = new Audio(countdown);
            sfx.volume = settings.volume;
            sfx.play();
          }}
        />
        <span className="text-lg block mt-2 text-white">
          <span className="mr-1">Guesses:</span>
          <span translate="no">{`${totalCorrect + totalIncorrect}`}</span>
        </span>
      </h1>
    </div>
  );
};

EndDelayCountdown.propTypes = {
  settings: PropTypes.object.isRequired,
  onRoundEnd: PropTypes.func.isRequired,
  totalCorrect: PropTypes.number.isRequired,
  totalIncorrect: PropTypes.number.isRequired,
};

export default EndDelayCountdown;
