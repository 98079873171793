import * as React from "react";
import PropTypes from "prop-types";
import Logo from "../utils/Logo";
import { useRecoilValue } from "recoil";
import { settingsAtom } from "../../recoil/settings";
import roundStartSound from "../../audio/roundStart.mp3";

import { Transition } from "@headlessui/react";

function LoadingLogo({
  minTimeMills,
  onComplete,
  isReady,
  round,
  maxRounds,
  roundIncrDelay,
  delay = 0,
}) {
  const [displayContent, setDisplayContent] = React.useState(false);
  const [timeoutDone, setTimeoutDone] = React.useState(false);
  const settings = useRecoilValue(settingsAtom);
  const [done, setDone] = React.useState(false);

  const [roundDisplay, setRoundDisplay] = React.useState(round - 1);

  const roundDisplayRef = React.useRef(roundDisplay);
  roundDisplayRef.current = roundDisplay;

  React.useEffect(() => {
    setTimeout(() => {
      setDisplayContent(true);

      setTimeout(() => {
        setRoundDisplay(roundDisplayRef.current + 1);
      }, roundIncrDelay);

      setTimeout(() => {
        setTimeoutDone(true);
      }, minTimeMills);

      setTimeout(() => {
        const audio = new Audio(roundStartSound);
        audio.volume = settings.volume;
        audio.play();
      }, roundIncrDelay - 100);
    }, delay);
  }, []);

  React.useEffect(() => {
    if (isReady && timeoutDone) {
      setDone(true);
    }
  }, [timeoutDone]);

  React.useEffect(() => {
    if (isReady && timeoutDone) {
      setDone(true);
    }
  }, [isReady]);

  return (
    <Transition
      show={!done && displayContent}
      className="flex flex-col gap-8 justify-center"
      appear={true}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={() => {
        onComplete();
      }}
    >
      <h1 className="text-4xl font-bold ">
        <Logo size={250} />
      </h1>
      <span className="font-bold text-5xl text-white  text-center">
        <span className="mr-1">Round: </span>
        <span translate="no">{`${roundDisplay}/${maxRounds}`}</span>
      </span>
    </Transition>
  );
}

LoadingLogo.propTypes = {
  minTimeMills: PropTypes.number,
  round: PropTypes.number,
  roundIncrDelay: PropTypes.number,
  maxRounds: PropTypes.number,
  onComplete: PropTypes.func.isRequired,
  isReady: PropTypes.bool.isRequired,
  delay: PropTypes.number,
};

export default LoadingLogo;
