import * as React from "react";
import { useRecoilValue } from "recoil";
import { gameScoresAtom } from "../recoil/roundState";
import { GameStateEnum } from "../recoil/gameState";
import useGameStateSet from "./utils/useGameStateSet";
import { numberWithCommas, toStringDifficultyEnum } from "./utils/utils";
import { scoreCountLimitSelector, settingsAtom } from "../recoil/settings";
import { activeCatAtom, activeDifficultiesAtom } from "../recoil/activeCat";

import { Transition } from "@headlessui/react";
import ChatLeaderboard from "./utils/ChatLeaderboard";
import { dataLayerPush, getCategoryCompletedPrecent } from "./utils/utils";
import { DailyCategory } from "../game/daily_category";

import dingTea from "../audio/dingtea.mp3";

function FinalResults() {
  const gameScores = useRecoilValue(gameScoresAtom);
  const activeCategory = useRecoilValue(activeCatAtom);
  const activeDifficulties = useRecoilValue(activeDifficultiesAtom);
  const settings = useRecoilValue(settingsAtom);
  const gameStateSet = useGameStateSet();
  const scoreCountLimit = useRecoilValue(scoreCountLimitSelector);
  const [totalCorrectPercentage, setTotalCorrectPercentage] = React.useState(0);
  const [totalIncorrectPercentage, setTotalIncorrectPercentage] =
    React.useState(0);
  const [showContent, setShowContent] = React.useState(false);

  React.useEffect(() => {
    dataLayerPush("game_end", {
      total_guesses: gameScores.totalGuesses,
      total_correct: gameScores.totalCorrectGuesses,
      total_incorrect: gameScores.totalIncorrectGuesses,
      category_name: activeCategory.name,
      category_id: activeCategory.id,
      completed_percentage: getCategoryCompletedPrecent(activeCategory),
    });

    if (gameScores.totalGuesses === 0) {
      gameStateSet(GameStateEnum.Selection);
      return;
    }

    setTotalCorrectPercentage(
      Math.round(
        (gameScores.totalCorrectGuesses / gameScores.totalGuesses) * 100
      )
    );

    setTotalIncorrectPercentage(
      Math.round(
        (gameScores.totalIncorrectGuesses / gameScores.totalGuesses) * 100
      )
    );
    const showContentDelay = 2000;

    const sfx = new Audio(dingTea);
    sfx.volume = settings.volume;
    sfx.play();

    setTimeout(() => {
      setShowContent(true);
    }, showContentDelay);
  }, []);

  return (
    <div className="text-white bg-BOTC_Blue text-center relative h-screen lg:h-full finalResults-div--spawn">
      <div className="bg-BOTC_Green absolute w-1 h-full"></div>

      <Transition
        className="w-full h-full flex flex-col items-center justify-between p-5"
        show={showContent}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <h1 className="font-bold text-4xl underline">FinalResults</h1>
        <h2>
          <span className="block text-3xl font-bold">
            {activeCategory.name}
          </span>

          {!(activeCategory instanceof DailyCategory) && (
            <span className="text-sm font-normal ">
              <span>Difficulty: </span>
              {activeDifficulties.map((diffEnum) => (
                <span key={`diffEnum-${diffEnum}`}>
                  {toStringDifficultyEnum(diffEnum)}
                </span>
              ))}
            </span>
          )}
        </h2>

        <div>
          <span className="block text-sm text-gray-300">
            Top {scoreCountLimit} Chatters Accounted for
          </span>
          <span className="block font-bold text-3xl">
            Total Score:{" "}
            <span className="font-bold text-yellow-600">
              {numberWithCommas(gameScores.totalScore)}
            </span>
          </span>
        </div>
        <hr className=" border w-24 opacity-20" />
        <div className="text-lg font-bold">
          <span className="mr-4 text-BOTC_Orange">
            Total Correct: {totalCorrectPercentage}%
          </span>
          <span className="mr-4 text-red-500">
            Total Incorrect: {totalIncorrectPercentage}%
          </span>
          {/* <span className="mr-4">
            Correct Guess: {gameScores.totalCorrectGuesses}
          </span>
          <span className="mr-4">
            incorrect Guesses:{" "}
            {gameScores.totalGuesses - gameScores.totalCorrectGuesses}
          </span> */}
        </div>

        <div className="h-3/6 w-full flex flex-col justify-center items-center text-left">
          <h1 className="font-bold text-center text-3xl text-yellow-400">
            Final Leaderboard
          </h1>
          <div className="overflow-y-scroll h-5/6 mt-2 text-xl">
            <ChatLeaderboard showAll={true} />
          </div>
        </div>

        <button
          className="border-2 hover:border-black px-8 py-1 font-bold text-black bg-white"
          onClick={() => {
            gameStateSet(GameStateEnum.Selection);
          }}
        >
          Back
        </button>
      </Transition>
    </div>
  );
}

export default FinalResults;
