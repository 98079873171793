const GameStateEnum = Object.freeze({
  SignIn: 0,
  Authed: 1,
  ChatConnected: 2,
  Selection: 3,
  StartTransition: 4,
  Tutorial: 5,
  RoundTransition: 6,
  RoundStart: 7,
  Playing: 8,
  Results: 9,
  EndTransition: 10,
  FinalResults: 11,
});

export default GameStateEnum;
