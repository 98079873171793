function getScoresSimilar(targetAsset, allAssetsWithTags) {
  let wordsWithScores = [];
  allAssetsWithTags.forEach(({ assetObj, word }) => {
    if (targetAsset.data !== assetObj.data) {
      let scoreData = {
        score: 0,
        heightestScoreTag: 0,
      };
      targetAsset.tags.forEach((targetTag, index) => {
        let indexOfCompareTag = assetObj.tags.indexOf(targetTag);
        //if found
        if (indexOfCompareTag > -1) {
          let score = calcScore(index, indexOfCompareTag);
          if (score > scoreData.heightestScoreTag) {
            scoreData.heightestScoreTag = score;
          }
          scoreData.score += score / 4;
        }
      });

      //Add score or replace if greater than previous
      if (scoreData.score > 0) {
        //make tag dups have less of an impact
        scoreData.score =
          (scoreData.score - scoreData.heightestScoreTag) / 2 +
          scoreData.heightestScoreTag;

        let existIndex = wordsWithScores.findIndex(
          (element) => element.word === word
        );
        if (existIndex !== -1) {
          if (scoreData.score > wordsWithScores[existIndex].score) {
            wordsWithScores[existIndex].score = scoreData.score;
          }
        } else {
          wordsWithScores.push({
            score: scoreData.score,
            word: word,
          });
        }
      }
    }
  });

  wordsWithScores = wordsWithScores.sort((e1, e2) => {
    if (e1.score > e2.score) {
      return -1;
    } else {
      return 1;
    }
  });

  return wordsWithScores;
}

function calcScore(indexOfTarget, indexOfCompare) {
  const scoreTable = [15, 10, 7, 7, 6, 5, 4];
  let targetsScore =
    indexOfTarget < scoreTable.length - 1 ? scoreTable[indexOfTarget] : 0;
  let comparesScore =
    indexOfCompare < scoreTable.length - 1 ? scoreTable[indexOfCompare] : 0;
  return targetsScore + comparesScore;
}

function getAllAssetsWithTags(words) {
  let allAssetsWithTags = [];
  words.forEach((wordObj) => {
    wordObj.assets.forEach((assetObj) => {
      if (assetObj.tags) {
        allAssetsWithTags.push({ assetObj: assetObj, word: wordObj.word });
      }
    });
  });
  return allAssetsWithTags;
}

export { getScoresSimilar, calcScore, getAllAssetsWithTags };
