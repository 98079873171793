import React from "react";
import PropTypes from "prop-types";

import { roundScoresAtom } from "../../recoil/roundState";
import { useRecoilValue } from "recoil";

const GuessCounts = ({ wordInPlay }) => {
  const roundScores = useRecoilValue(roundScoresAtom);

  // const fake = {
  //   "!1": 2,
  //   "!2": 3,
  //   "!3": 4,
  //   "!4": 20,
  //   "!5": 30,
  //   "!6": 2,
  // };
  // const fakeTotal = 61;

  return wordInPlay.tileOptions.map((option, index) => {
    return (
      <React.Fragment key={`guessPrecentage-${index}`}>
        <Bar
          title={option.word}
          commandString={option.commandString}
          // precent={fake[option.commandString] / fakeTotal}
          precent={
            roundScores.optionsGuessCount[option.commandString] /
            (roundScores.correctList.length + roundScores.incorrectList.length)
          }
        />
      </React.Fragment>
    );
  });
};

GuessCounts.propTypes = {
  wordInPlay: PropTypes.object.isRequired,
};

const Bar = ({ title, precent, commandString, animationTimeMills = 1500 }) => {
  const intervalDelay = 95;
  const [precentDisplay, setPrecentDisplay] = React.useState(0);
  const [done, setDone] = React.useState(false);
  const pDRef = React.useRef();
  pDRef.current = precentDisplay;

  React.useEffect(() => {
    if (!precent) {
      return;
    }

    let timeElapsed = 0;
    const inverval = setInterval(() => {
      timeElapsed += intervalDelay;
      let progress = timeElapsed / animationTimeMills;
      if (progress >= 1) {
        clearInterval(inverval);
        setDone(true);
        progress = 1;
      }
      setPrecentDisplay(Math.round(progress * precent * 100));
    }, intervalDelay);

    return () => {
      clearInterval(inverval);
    };
  }, []);

  return (
    <div className={"w-1/2 relative flex-grow"}>
      <h3 className="font-bold text-base pb-1">
        <span
          translate="no"
          className="mr-2 bg-BOTC_LightBlue px-1 border border-black rounded-sm w-max"
        >
          {commandString}
        </span>
        <span translate="no">{title}</span>
      </h3>

      <div className="flex items-center">
        <span
          style={{
            width: `${precentDisplay}%`,
            transitionDuration: `${intervalDelay}ms`,
            transitionTimingFunction: "linear",
          }}
          className="h-3 bg-BOTC_LightBlue relative z-10 transition-all"
        ></span>

        <span style={{ width: "3rem" }} className="h-6">
          {done && (
            <span
              translate="no"
              className="text-left text-blue-400 bg-BOTC_DarkBlue relative z-10 font-bold opacity-div--spawn"
            >
              {precentDisplay}%
            </span>
          )}
        </span>

        <hr className=" z-0 absolute w-full h-1 border-t border-opacity-40 border-BOTC_LightBlue" />
      </div>
    </div>
  );
};

Bar.propTypes = {
  title: PropTypes.string.isRequired,
  precent: PropTypes.number.isRequired,
  commandString: PropTypes.string.isRequired,
  animationTimeMills: PropTypes.number,
};

export default GuessCounts;
