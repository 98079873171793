import * as React from "react";
import PropTypes from "prop-types";

function SkipBtn({ disabled = false, onSkip }) {
  return (
    <button
      className={`${disabled ? "opacity-40" : "opacity-100"} text-sm`}
      onClick={() => {
        if (!disabled) {
          onSkip();
        }
      }}
    >
      <span>Skip</span>
    </button>
  );
}

SkipBtn.propTypes = {
  onSkip: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SkipBtn;
