import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../utils/Tooltip";

import { useRecoilValue } from "recoil";
import { GameStateEnum } from "../../recoil/gameState";
import useGameStateSet from "../utils/useGameStateSet";
import { chatAtom } from "../../recoil/chat";
import { bypassChatRequirement } from "../../api";
import { dataLayerPush } from "../utils/utils";

const NextRoundBtn = ({ currentRound, max_rounds }) => {
  const [disabled, setDisabled] = React.useState(false);
  const gameStateSet = useGameStateSet();
  const chat = useRecoilValue(chatAtom);

  React.useEffect(() => {
    if (chat.chatConnected || bypassChatRequirement) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [chat.chatConnected]);

  const button = () => {
    return (
      <button
        className={`border ${
          disabled ? "opacity-40" : "opacity-100"
        } py-2 px-5 font-bold bg-white text-black hover:border-2 hover:border-black`}
        onClick={() => {
          if (!disabled) {
            if (currentRound < max_rounds) {
              gameStateSet(GameStateEnum.RoundTransition);
            } else {
              gameStateSet(GameStateEnum.EndTransition);
            }
            dataLayerPush("next_round_click");
          }
        }}
      >
        {currentRound >= max_rounds ? "Final Results" : "Next Round"}
      </button>
    );
  };

  return (
    <>
      {disabled ? (
        <Tooltip tip="Chat is disconnected" allowClick={false}>
          {button()}
        </Tooltip>
      ) : (
        button()
      )}
    </>
  );
};

NextRoundBtn.propTypes = {
  currentRound: PropTypes.number.isRequired,
  max_rounds: PropTypes.number.isRequired,
};

export default NextRoundBtn;
