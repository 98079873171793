import activeCatAtom from "./activeCatAtom";
import activeDifficultiesAtom from "./activeDifficultiesAtom";
import { gameStateAtom, GameStateEnum } from "../gameState";
import { selector, atom } from "recoil";

const allWordsOptionsAtom = atom({
  key: "allWordsAtom",
  default: { debug: false },
});

const allWordsSelector = selector({
  key: "allWordsSelector",
  get: ({ get }) => {
    const category = get(activeCatAtom);
    const activeDifficulties = get(activeDifficultiesAtom);
    const gameState = get(gameStateAtom);
    const allWordsOptions = get(allWordsOptionsAtom);

    let words = null;
    if (gameState >= GameStateEnum.StartTransition || allWordsOptions.debug) {
      if (category) {
        words = structuredClone(category.getWords(activeDifficulties));
        //check if word has a unlock time set
        let currentDate = new Date();
        for (let index = 0; index < words.length; index++) {
          const wordObj = words[index];
          if (wordObj.unlockAt && !allWordsOptions.debug) {
            if (wordObj.unlockAt.getTime() > currentDate.getTime()) {
              words.splice(index, 1);
            }
          }
        }
      }
    }

    return words;
  },
  set: ({ set }, debug) => {
    if (debug) {
      console.log("Debug all words: true");
    }
    set(allWordsOptionsAtom, { debug: debug });
  },
});

export default allWordsSelector;
