import * as React from "react";
import NewWindow from "../utils/NewWindow";
import OptionTile from "../playPanel/OptionTile";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { gameScoresAtom } from "../../recoil/roundState";
import { gameStateAtom, GameStateEnum } from "../../recoil/gameState";
import { userGuessAtom, guessWindowAtom } from "../../recoil/userGuess";
import { bGStateAtom } from "../../recoil/BGState";
import { errorStateAtom, ErrorLevelEnum } from "../../recoil/errorState";

import ChannelName from "../utils/ChannelName";

function YourGuessWindow() {
  const setErrorState = useSetRecoilState(errorStateAtom);
  const gameState = useRecoilValue(gameStateAtom);
  const gameScores = useRecoilValue(gameScoresAtom);
  const [userGuess, setUserGuess] = useRecoilState(userGuessAtom);
  const [guessWindowState, setGuessWindowState] =
    useRecoilState(guessWindowAtom);
  const bgState = useRecoilValue(bGStateAtom);

  const [showWindow, setShowWindow] = React.useState(false);
  const [madeGuess, setMadeGuess] = React.useState(false);
  const [wordGuessed, setWordGuessed] = React.useState({});
  const windowRef = React.useRef(null);

  React.useEffect(() => {
    //reopen a blocked popup
    window.reOpenBlocked = () => {
      openYourGuessWindow();
    };
  }, []);

  React.useEffect(() => {
    if (gameState === GameStateEnum.RoundStart || guessWindowState.opening) {
      if (gameState === GameStateEnum.RoundStart) {
        setWordGuessed({});
        setMadeGuess(false);
      }
      openYourGuessWindow();
      setGuessWindowState({ opening: false });
    }
  }, [gameState, guessWindowState.opening]);

  const openYourGuessWindow = () => {
    if (!showWindow) {
      setShowWindow(true);
    } else {
      windowRef.current.window.focus();
    }
  };

  if (typeof window !== "undefined") {
    //unload the window when main site closes
    window.onbeforeunload = function () {
      if (showWindow) {
        windowRef.current.window.close();
      }
    };
    //check if the main site is in a popup. This is caused when popups are blocked and later allowed
    if (window.opener && window.opener !== window && !window.menubar.visible) {
      if (window.opener) {
        window.opener.reOpenBlocked();
      }
      window.close();
    }
  }

  const listOptions = () => {
    return userGuess.wordInPlay.tileOptions.map((option, index) => (
      <React.Fragment key={index}>
        <button
          onClick={() => {
            setWordGuessed(option);
            setMadeGuess(true);
            setUserGuess({
              ...userGuess,
              guessCommandString: option.commandString,
            });
          }}
          key={`${option.word}-${option.commandString}`}
          className="w-5/12 md:w-4/12 h-1/4 hover:border-black border-2"
        >
          <OptionTile
            word={option.word}
            commandString={option.commandString}
            translate={option.translate ? option.translate : "yes"}
          />
        </button>
        {index + 1 === 3 && (
          <div key={`${index + 1}-break`} className="h-full"></div>
        )}
      </React.Fragment>
    ));
  };

  const showGuessing = () => {
    return (
      <div className="w-full h-full flex justify-center items-center relative">
        <div className="w-full h-full">
          {madeGuess ? (
            <div className="w-full h-full">
              <h1
                style={{ height: "20%" }}
                className="font-bold text-4xl text-center flex justify-center items-center"
              >
                You Picked
              </h1>
              <div style={{ height: "80%" }} className="w-full  text-center">
                <div className="w-5/12 h-24 mx-auto">
                  <OptionTile
                    word={wordGuessed.word}
                    commandString={wordGuessed.commandString}
                    translate={
                      wordGuessed.translate ? wordGuessed.translate : "yes"
                    }
                  />
                </div>
                <FontAwesomeIcon
                  style={{ fontSize: "8rem" }}
                  className="block mt-10 text-white mx-auto"
                  icon={faLock}
                />
              </div>
            </div>
          ) : (
            <div className="w-full h-full">
              <h1
                style={{ height: "20%" }}
                className="font-bold text-4xl text-center flex justify-center items-center"
              >
                Select Your Answer
              </h1>
              <div
                style={{ height: "80%" }}
                className="flex flex-wrap flex-col content-center justify-start gap-x-4 gap-y-4"
              >
                {listOptions()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const showWaitingNext = () => {
    return (
      <div className="w-full h-full flex flex-col items-center justify-between">
        <h1 className="underline font-bold text-4xl text-center">
          Your Guess Window
        </h1>
        <h2 className="font-bold text-4xl text-center pb-12">
          Waitng For Next Round...
        </h2>
        <div />
      </div>
    );
  };

  const showWaitingGame = () => {
    return (
      <div className="w-full h-full flex flex-col items-center justify-between">
        <h1 className="underline font-bold text-4xl text-center">
          Your Guess Window
        </h1>
        <h2 className="font-bold text-4xl text-center pb-12">
          Waitng For To Game Start...
        </h2>
        <div />
      </div>
    );
  };

  const displayPlacement = () => {
    return (
      <div className=" relative font-bold text-lg bg-BOTC_DarkBlue w-max">
        <div className="bg-BOTC_Green absolute w-1 h-full"></div>
        <div className="p-2">
          {gameScores.userPlacement > 0 && (
            <span>{gameScores.userPlacement}. </span>
          )}
          <ChannelName size={"md"} minimal={true} imgSize={25} />
          {gameState >= GameStateEnum.Tutorial && (
            <>
              {": "}
              <span className="font-bold text-yellow-600 text-xl">
                {gameScores.userGameScore}
              </span>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {showWindow && (
        <NewWindow
          ref={windowRef}
          center="screen"
          title={"Battle Of The Chat - Your Guess Window"}
          features={{
            width: "760px",
            height: "560px",
            menubar: "no",
            location: "no",
            directories: "no",
            status: "no",
            resizable: "no",
            popup: "yes",
          }}
          onBlock={() => {
            setErrorState({
              show: true,
              message:
                "Failed to open guess window. Make sure to allow popups.",
              errorLevelEnum: ErrorLevelEnum.FATAL,
            });
          }}
          onUnload={() => {
            setShowWindow(false);
          }}
        >
          <div
            style={{ minWidth: "580px", minHeight: "555px" }}
            className={`${bgState.backgroundClass} text-white h-full w-full background-transition--fast flex flex-col relative justify-center`}
          >
            <div
              className={`w-full ${
                gameState === GameStateEnum.Tutorial ||
                gameState === GameStateEnum.Selection ||
                gameState === GameStateEnum.Playing ||
                gameState === GameStateEnum.Results
                  ? " opacity-100"
                  : "opacity-0"
              }`}
            >
              {displayPlacement()}
            </div>

            {/* <span className="pb-2"></span> */}
            {gameState === GameStateEnum.Playing && showGuessing()}
            {gameState === GameStateEnum.Results && showWaitingNext()}
            {gameState <= GameStateEnum.Tutorial && showWaitingGame()}

            {gameState !== GameStateEnum.RoundStart &&
              gameState !== GameStateEnum.RoundTransition &&
              gameState !== GameStateEnum.StartTransition &&
              gameState !== GameStateEnum.EndTransition && (
                <div
                  className={` p-2 text-black font-bold ${
                    gameState < GameStateEnum.StartTransition
                      ? "text-white"
                      : "text-black"
                  } `}
                >
                  Don{"'"}t show this window on stream
                </div>
              )}
          </div>
        </NewWindow>
      )}
    </>
  );
}

export default YourGuessWindow;
