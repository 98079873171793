import { DifficultyEnum } from "../../recoil/activeCat";

const scoreTable = {
  easy: 75,
  medium: 90,
  hard: 100,
  timeBonus: 40, //adjusted for 25secs
  placementBonus: 10, //adjusted for first 10 people, after its 0

  wrongGuessPenalty: 25, //base ammount for a wrong guess
  timePenaltyBonus: 15, // penalise people for getting it wrong too early.

  minorityBonusThreshold: 0.48, // threshold for when to apply minority bonus
  baseMinorityBonus: 35,
  minorityMultipler: 85, // scales base on how low is the correctPrecentage
};

const getMinorityBonus = (correctPrecentage) => {
  if (correctPrecentage > scoreTable.minorityBonusThreshold) {
    return 0;
  }
  return Math.round(
    (1 - correctPrecentage) * scoreTable.minorityMultipler +
      scoreTable.minorityBonusThreshold
  );
};

const calcPenaltyScore = (timePassed) => {
  let timeBonus = (scoreTable.timePenaltyBonus - timePassed) * 3;
  timeBonus = timeBonus < 0 ? 0 : timeBonus;

  return scoreTable.wrongGuessPenalty + timeBonus;
};

const calcScore = (timePassed, difficultyEnum, correctListLength) => {
  let score = 0;
  //difficulty
  switch (difficultyEnum) {
    case DifficultyEnum.easy:
      score += scoreTable.easy;
      break;
    case DifficultyEnum.medium:
      score += scoreTable.medium;
      break;
    case DifficultyEnum.hard:
      score += scoreTable.hard;
      break;
  }
  //time
  let timeBonus = (scoreTable.timeBonus - timePassed) * 2;
  score += timeBonus > 0 ? timeBonus : 0;
  //placement
  let placementBonus = scoreTable.placementBonus - correctListLength;
  score += placementBonus > 0 ? placementBonus : 0;

  return score;
};

export { scoreTable, calcScore, calcPenaltyScore, getMinorityBonus };
