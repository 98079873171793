interface iTransition {
  durationMills: number;
  tickIntervalMills: number;
  ticksNeeded: number;
  delay: number;

  startTransition: boolean;
  transitioning: boolean;
  startTime: number;
  lastTick: number;
  elapsedTicks: number;

  onStart: voidFunc;
  onUpdate: voidFunc;
  onEnd: voidFunc;
}

type voidFunc = () => void;

interface iArgs {
  delay: number;
  durationMills: number;
  tickIntervalMills: number;
  onStart: voidFunc;
  onUpdate: voidFunc;
  onEnd: voidFunc;
}

class Transition implements iTransition {
  durationMills = 0;
  tickIntervalMills = 0;
  ticksNeeded = 0;
  delay = 0;

  startTransition = false;
  transitioning = false;
  startTime = 0;
  timePass = 0;
  lastTick = 0;
  elapsedTicks = 0;

  onStart: voidFunc;
  onUpdate: voidFunc;
  onEnd: voidFunc;

  constructor(args: iArgs) {
    this.onStart = args.onStart;
    this.onUpdate = args.onUpdate;
    this.onEnd = args.onEnd;
    this.durationMills = args.durationMills;
    this.tickIntervalMills = args.tickIntervalMills;
    this.ticksNeeded = this.durationMills / this.tickIntervalMills;
    this.delay = args.delay;
    // console.log(`ticksNeeded: ${this.ticksNeeded}`);
  }

  start(): void {
    setTimeout(() => {
      this.startTransition = true;
    }, this.delay);
  }

  reset(): void {
    this.startTransition = false;
    this.transitioning = false;
    this.startTime = 0;
    this.timePass = 0;
    this.lastTick = 0;
    this.elapsedTicks = 0;
  }

  onFrameUpdate(threeState): void {
    const currTime = threeState.clock.elapsedTime * 1000;

    if (this.transitioning) {
      this.timePass = currTime - this.startTime;
      const runTicks = (ammount) => {
        for (let ctr = 0; ctr < ammount; ctr++) {
          this.elapsedTicks += 1;
          this.onUpdate();
        }
      };

      if (this.timePass > this.durationMills) {
        //fill remaining ticks
        runTicks(this.ticksNeeded - this.elapsedTicks);
        //end animation and reset
        this.onEnd();
        this.reset();
      } else if (this.timePass - this.lastTick > this.tickIntervalMills) {
        this.lastTick = this.timePass;
        const totalTicks = Math.round(this.timePass / this.tickIntervalMills);
        const ticksDelta = totalTicks - this.elapsedTicks;

        // console.log(`TimePassed ${this.timePass}`);
        // console.log(`TicksDelta ${ticksDelta}`);
        runTicks(ticksDelta);
      }
    } else if (this.startTransition) {
      //start animation
      this.startTime = currTime;
      this.transitioning = true;
      this.startTransition = false;

      this.onStart();
    }
  }
}
export default Transition;
