// const fs = require("fs");
// let file = fs.readFileSync(`src/game/${"0_daily"}.category`, "utf-8");
// let obj = parseCategoryFile(file);
// let markdown = objToCategoryMarkdown(obj);
// console.log(obj);
// console.log(markdown);

const audioOptions = ["segmentAudio", "startAt"];
const imageOptions = ["doBlur", "zoomPoint"];

function parseCategoryFile(data, fileName) {
  let obj = {};
  obj.fileName = fileName;
  let splitTargetBlock = data.indexOf("---");
  let doubleBlock = [
    data.slice(0, splitTargetBlock),
    data.slice(splitTargetBlock + 3),
  ];

  let optionsSegments = doubleBlock[0].trim().split("??");
  let wordSegments = doubleBlock[1].trim().split("::");

  //parse category options
  optionsSegments.forEach((segment) => {
    let splitTarget = segment.trim().indexOf("=");
    let keyPair = [
      segment.trim().slice(0, splitTarget),
      segment.trim().slice(splitTarget + 1),
    ];
    switch (keyPair[0].trim()) {
      case "name":
        obj.name = keyPair[1].trim();
        break;
      case "question":
        obj.question = keyPair[1].trim();
        break;
      case "filterEnum":
        obj.filterEnum = keyPair[1].trim();
        break;
      case "translateTitle":
        obj.translateTitle = keyPair[1].trim();
        break;
      case "defualtTranslate":
        obj.defualtTranslate = keyPair[1].trim();
        break;
      default:
        if (keyPair[0].trim().length > 0) {
          console.log(
            `WARN: Category option was not found: "${keyPair[0].trim()}"`
          );
        }
    }
  });

  //parse words
  obj.words = [];
  wordSegments.forEach((segment) => {
    let wordObj = {};
    let metaSegments = segment.split(">>");
    //parse a word meta data
    let wordMeta = metaSegments[0].trim().split("&&");
    let wordOptionsSegments = wordMeta[0].trim().split("??");
    if (wordOptionsSegments[0].length === 0) {
      return;
    }
    wordObj.word = wordOptionsSegments[0].trim();
    wordOptionsSegments.shift();
    wordOptionsSegments.forEach((segment) => {
      let splitTarget = segment.indexOf("=");
      let keyPair = [
        segment.slice(0, splitTarget),
        segment.slice(splitTarget + 1),
      ];
      switch (keyPair[0].trim()) {
        case "difficulty":
          switch (keyPair[1].trim()) {
            case "easy":
              wordObj.difficulty = 1;
              break;
            case "medium":
              wordObj.difficulty = 2;
              break;
            case "hard":
              wordObj.difficulty = 3;
              break;
            default:
              console.error(
                "Difficulty given but is unknown. Supports (easy, medium, hard) strings"
              );
              process.exit(1);
          }
          break;
        case "unlockAt":
          var dateArray = keyPair[1]
            .trim()
            .split("-")
            .map((element) => parseInt(element));
          wordObj.unlockAt = new Date(
            Date.UTC(dateArray[0], dateArray[1], dateArray[2], 0, 0, 0, 0)
          );
          break;
        case "question":
          wordObj.question = keyPair[1].trim();
          break;
        case "translate":
          if (keyPair[1].trim() !== "no") {
            wordObj.translate = keyPair[1].trim();
          }
          break;
        default:
          console.warn(
            `WARN: Word option was not found: "${keyPair[0].trim()}"`
          );
      }
    });
    //pharse a word guess options
    wordMeta.shift();
    if (wordMeta.length > 0) {
      wordObj.options = [];

      wordMeta.forEach((guessOption) => {
        if (guessOption.trim().length === 0) {
          return;
        }
        let guessOptionsSegments = guessOption.trim().split("??");
        let optionObj = {};
        optionObj.word = guessOptionsSegments[0].trim();
        guessOptionsSegments.shift();
        guessOptionsSegments.forEach((segment) => {
          let splitTarget = segment.indexOf("=");
          let keyPair = [
            segment.slice(0, splitTarget),
            segment.slice(splitTarget + 1),
          ];
          switch (keyPair[0].trim()) {
            case "translate":
              if (keyPair[1].trim() !== "no") {
                optionObj.translate = keyPair[1].trim();
              }
              break;
            default:
              console.warn(
                `WARN: Guess option was not found: "${keyPair[0].trim()}"`
              );
          }
        });
        wordObj.options.push(optionObj);
      });
    }

    //parse a word assets
    wordObj.assets = [];
    let assetSegments = metaSegments[1].trim().split("&&");
    assetSegments.forEach((segment) => {
      let assetObj = {};
      let assetString = segment.trim();
      let assetMeta = assetString.split("??");
      assetMeta.forEach((metaSegment, index) => {
        let assetMetaString = metaSegment.trim();
        //the file name will always be first
        if (index === 0) {
          if (assetMetaString.includes(".mp3")) {
            assetObj.type = "audio";
            assetObj.data = assetMetaString;
          } else if (assetMetaString.includes(".webp")) {
            assetObj.type = "image";
            assetObj.data = assetMetaString;
          } else if (
            /\.(jpe?g|png|mp4|wav|m4a|flac|wma|aac)$/i.test(assetMetaString)
          ) {
            console.log("Assets Types: mp3 and webp is only supported");
            process.exit(1);
          } else {
            assetObj.type = "text";
            assetObj.data = assetMetaString;
          }
        } else {
          let splitTarget = assetMetaString.indexOf("=");
          let keyPair = [
            assetMetaString.slice(0, splitTarget),
            assetMetaString.slice(splitTarget + 1),
          ];
          switch (keyPair[0]) {
            case "zoomPoint":
              assetObj.zoomPoint = keyPair[1];
              break;
            case "startAt":
              if (!parseFloat(keyPair[1])) {
                console.warn(
                  `Unknown asset value key:${keyPair[0]} value:${keyPair[1]}`
                );
              }
              assetObj.startAt = parseFloat(keyPair[1]);
              break;
            case "segmentAudio":
              if (keyPair[1] !== "true" && keyPair[1] !== "false") {
                console.warn(
                  `Unknown asset value key:${keyPair[0]} value:${keyPair[1]}`
                );
              }
              assetObj.segmentAudio = keyPair[1] === "true" ? true : false;
              break;
            case "doBlur":
              if (keyPair[1] !== "true" && keyPair[1] !== "false") {
                console.warn(
                  `Unknown asset value key:${keyPair[0]} value:${keyPair[1]}`
                );
              }
              assetObj.doBlur = keyPair[1] === "true" ? true : false;
              break;
            case "tags":
              assetObj.tags = keyPair[1].trim().split(",");
              break;
            case "source":
              assetObj.source = keyPair[1];
              break;
            case "author":
              assetObj.author = keyPair[1];
              break;
            case "translate":
              if (keyPair[1].trim() !== "no") {
                assetObj.translate = keyPair[1];
              }
              break;
            default:
              console.warn(`Unknown asset keypair ${keyPair[0]}`);
          }
        }
      });

      wordObj.assets.push(assetObj);
    });
    if (wordObj.assets.length === 0) {
      console.log("There must be at least one asset");
      process.exit(1);
    }
    // console.log(assetSegments);
    obj.words.push(wordObj);
  });

  // console.log(JSON.stringify(obj));
  // console.log(obj);
  // console.log(data);
  return obj;
}

function objToCategoryMarkdown(obj) {
  let fileData = "";

  //category options
  fileData += Object.keys(obj)
    .filter((key) => key !== "words" && key !== "fileName" && key !== "id")
    .map((key) => `${key}=${obj[key]} ??\n`)
    .join("");

  fileData += "---\n";

  //words
  fileData += obj.words
    .map((word) => {
      //markdown words and its options
      let markdownString = "";
      markdownString += `${word.word}`;
      //markdown difficulty
      if (word.difficulty) {
        let difficultyString;
        switch (word.difficulty) {
          case 1:
            difficultyString = "easy";
            break;
          case 2:
            difficultyString = "medium";
            break;
          case 3:
            difficultyString = "hard";
            break;
          default:
            console.warn(
              "Difficulty given but is unknown. Supports (easy, medium, hard)"
            );
            difficultyString = "easy";
        }

        markdownString += ` ?? difficulty=${difficultyString}`;
      }
      markdownString += Object.keys(word)
        .filter(
          (key) =>
            key !== "assets" &&
            key !== "word" &&
            key !== "options" &&
            key !== "difficulty" &&
            key !== "id" &&
            key !== "fileName"
        )
        .map((key) => {
          switch (key) {
            case "question":
              if (obj.question !== word[key]) {
                return ` ?? ${key}=${word[key]}`;
              }
              break;
            case "translate":
              if (word[key] !== "no") {
                return ` ?? ${key}=${word[key]}`;
              }
              break;
            case "unlockAt":
              return ` ?? ${key}=${word[key].getUTCFullYear()}-${word[
                key
              ].getUTCMonth()}-${word[key].getUTCDate()}`;
            default:
              return ` ?? ${key}=${word[key]}`;
          }
        })
        .join("");
      //markdown guess options
      if (word.options) {
        markdownString += word.options
          .map((wordObj) => {
            let options = Object.keys(wordObj)
              .filter((key) => key !== "word")
              .map((key) => {
                switch (key) {
                  case "translate":
                    if (wordObj[key] !== "no") {
                      return ` ?? ${key}=${wordObj[key]}`;
                    }
                    break;
                  default:
                    return ` ?? ${key}=${wordObj[key]}`;
                }
              })
              .join("");
            return ` && ${wordObj.word}${options}`;
          })
          .join("");
      }

      //markdown assets
      markdownString += " >> ";
      markdownString += word.assets
        .map((asset, index) => {
          let baseString = `${index !== 0 ? " && " : ""}${asset.data}`;
          baseString += Object.keys(asset)
            .filter(
              (key) => key !== "type" && key !== "data" && key !== "cdn_url"
            )
            .map((key) => ` ?? ${key}=${asset[key]}`)
            .join("");
          return baseString;
        })
        .join("");

      markdownString += " ::\n";
      return markdownString;
    })
    .join("");
  return fileData;
}

module.exports = {
  parseCategoryFile,
  objToCategoryMarkdown,
  audioOptions,
  imageOptions,
};
