import * as React from "react";
import PropTypes from "prop-types";

import { Helmet } from "react-helmet";
import Logo from "../images/BOTC_icon.png";
import PageLoading from "./PageLoading";

const Layout = ({ children }) => {
  const metaData = {
    title: "Battle Of The Chat",
    description:
      "A Guessing Game For Streaming. Use your twitch account to play.", // eslint-disable-line
    url: "https://battleofthechat.net",
    icon_url:
      "https://res.cloudinary.com/dyn8wmfzr/image/upload/v1651279903/BOTC_icon_eich3s.png",
    og_image_url:
      "https://res.cloudinary.com/dyn8wmfzr/image/upload/v1666742223/BOTC_OG_sfdy3d.jpg",
    keywords:
      "Twitch, Triva, Chat, Web App, Game, Live Stream, Words On Stream, Guessing, Battle, Chat, Guess",
    lang: "en",
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang: metaData.lang }}>
        <meta charSet="utf-8" />
        <title translate="no">{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />
        <meta name="robots" content="index, follow" />
        <meta name="image" content={metaData.icon_url} />
        <meta
          name="theme-color"
          media="(prefers-color-scheme: dark)"
          content="black"
        />

        <meta property="og:title" content={metaData.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaData.url} />
        <meta property="og:image" content={metaData.og_image_url} />
        <meta property="og:description" content={metaData.description} />
        <link href={Logo} rel="shortcut icon" type="image/x-icon" />
      </Helmet>
      <PageLoading />
      {children}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
