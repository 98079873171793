import { atom } from "recoil";
import { AuthTypeEnum } from "../user";
//https://recoiljs.org/docs/guides/asynchronous-data-queries/
const chatAtom = atom({
  key: "chatAtom",
  default: {
    chatConnected: false,
    message: null,
    interface: null,
    authType: AuthTypeEnum.None,
  },
});

export default chatAtom;
