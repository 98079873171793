import * as React from "react";
import PropTypes from "prop-types";
import { Line } from "@react-three/drei";

//https://docs.pmnd.rs/react-three-fiber/getting-started/introduction
//https://threejs.org/docs/index.html#manual/en/introduction/Creating-a-scene
//https://threejs.org/examples/#webgl_camera
//https://github.com/mrdoob/three.js/blob/master/examples/webgl_camera.html
//https://github.com/pmndrs/drei

const Box = React.forwardRef(
  (
    {
      position,
      scale = [1, 1, 1],
      fill = false,
      faceColor = "orange",
      frameColor = "white",
    },
    meshRef
  ) => {
    const wireFrame = (color = "orange", lineWidth = 2) => {
      return (
        <group scale={scale} position={[-0.5, -0.5, -0.5]}>
          {/* back */}
          <Line
            points={[
              [1, 1, 1],
              [1, 0, 1],
            ]}
            color={color}
            lineWidth={lineWidth}
            dashed={false}
          />
          <Line
            points={[
              [1, 1, 1],
              [0, 1, 1],
            ]}
            color={color}
            lineWidth={lineWidth}
            dashed={false}
          />
          <Line
            points={[
              [0, 1, 1],
              [0, 0, 1],
            ]}
            color={color}
            lineWidth={lineWidth}
            dashed={false}
          />
          <Line
            points={[
              [1, 0, 1],
              [0, 0, 1],
            ]}
            color={color}
            lineWidth={lineWidth}
            dashed={false}
          />
          {/* mid */}
          <Line
            points={[
              [1, 1, 1],
              [1, 1, 0],
            ]}
            color={color}
            lineWidth={lineWidth}
            dashed={false}
          />
          <Line
            points={[
              [1, 0, 1],
              [1, 0, 0],
            ]}
            color={color}
            lineWidth={lineWidth}
            dashed={false}
          />
          <Line
            points={[
              [0, 1, 1],
              [0, 1, 0],
            ]}
            color={color}
            lineWidth={lineWidth}
            dashed={false}
          />
          <Line
            points={[
              [0, 0, 1],
              [0, 0, 0],
            ]}
            color={color}
            lineWidth={lineWidth}
            dashed={false}
          />
          {/* front */}
          <Line
            points={[
              [1, 1, 0],
              [1, 0, 0],
            ]}
            color={color}
            lineWidth={lineWidth}
            dashed={false}
          />
          <Line
            points={[
              [1, 1, 0],
              [0, 1, 0],
            ]}
            color={color}
            lineWidth={lineWidth}
            dashed={false}
          />
          <Line
            points={[
              [0, 1, 0],
              [0, 0, 0],
            ]}
            color={color}
            lineWidth={lineWidth}
            dashed={false}
          />

          <Line
            points={[
              [1, 0, 0],
              [0, 0, 0],
            ]}
            color={color}
            lineWidth={lineWidth}
            dashed={false}
          />
        </group>
      );
    };

    return (
      <mesh position={position} ref={meshRef}>
        <meshBasicMaterial color={faceColor} toneMapped={false} />
        {fill ? (
          <>
            {wireFrame(frameColor, 1.2)}
            <boxGeometry scale={scale - 0.2} />
          </>
        ) : (
          wireFrame()
        )}
      </mesh>
    );
  }
);
Box.displayName = "Box";

Box.propTypes = {
  position: PropTypes.array,
  scale: PropTypes.array,
  fill: PropTypes.bool,
  faceColor: PropTypes.string,
  frameColor: PropTypes.string,
};

export default Box;
