import categoryModules from "./categoryModules.json";
import { DifficultyEnum } from "../recoil/activeCat";
interface iCategoryModule {
  fileName: string;
  name: string;
  id: number;
  question: string;
  words: Array<WordObject>;
  translateTitle?: string;

  getEasyWords(): Array<WordObject>;
  getMediumWords(): Array<WordObject>;
  getHardWords(): Array<WordObject>;
}
type WordObject = {
  id: number;
  assets: Array<AssetObject>;
  difficulty: number;
  word: string;
  fileName?: string;
  source?: string;
  author?: string;
  translate?: string;
  options?: Array<OptionObject>;
  question?: string;
  unlockAt?: Date;
};
type AssetObject = {
  type: string;
  data: string;
  cdn_url?: string;
  source?: string;
  author?: string;
  translate?: string;
  zoomPoint?: string;
  tags?: Array<string>;
};
type OptionObject = {
  word: string;
  translate?: string;
};

class CategoryModule implements iCategoryModule {
  fileName: string;
  name: string;
  id: number;
  question: string;
  words: WordObject[];

  constructor(
    fileName: string,
    name: string,
    id: number,
    question: string,
    words: WordObject[]
  ) {
    this.fileName = fileName;
    this.name = name;
    this.id = id;
    this.question = question;
    this.words = words;
  }
  getEasyWords(): Array<WordObject> {
    return this.words.filter(
      (wordObj) => wordObj.difficulty === DifficultyEnum.easy
    );
  }
  getMediumWords(): Array<WordObject> {
    return this.words.filter(
      (wordObj) => wordObj.difficulty === DifficultyEnum.medium
    );
  }
  getHardWords(): Array<WordObject> {
    return this.words.filter(
      (wordObj) => wordObj.difficulty === DifficultyEnum.hard
    );
  }
}

const category_modules: Array<iCategoryModule> = [];
categoryModules.forEach((cat) => {
  const catWords = cat.words.map((wordObj) => {
    if (wordObj.unlockAt) {
      wordObj.unlockAt = new Date(wordObj.unlockAt);
    }
    return wordObj;
  });
  category_modules[cat.id] = new CategoryModule(
    cat.fileName,
    cat.name,
    cat.id,
    cat.question,
    catWords
  );
});

export { category_modules, iCategoryModule, WordObject, AssetObject };
