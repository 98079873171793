import { atom } from "recoil";

const gameScoresAtom = atom({
  key: "gameScoreAtom",
  default: {
    chatLeaderboard: [],
    userGameScore: 0,
    userPlacement: 0,
    totalScore: 0,
    totalGuesses: 0,
    totalCorrectGuesses: 0,
    totalIncorrectGuesses: 0,
  },
});

export default gameScoresAtom;
