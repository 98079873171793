import axios from "axios";
import { staticAssetsPath, useCDNLinks } from "../../api";
import {
  generateRandomIntExclude,
  wordRegEx,
  shuffleArray,
  getRandomInt,
} from "../utils/utils";

const setupWord = (
  categoryID,
  newWordInPlay,
  randomziedWordList,
  commandPrefix,
  options_limt,
  assetIndex = -1
) => {
  newWordInPlay.tileOptions = [];
  newWordInPlay.tileOptions.push(newWordInPlay);

  //Add hardcoded guess options, if any
  let numHardCodedOptions = newWordInPlay.options
    ? newWordInPlay.options.length
    : 0;
  if (numHardCodedOptions) {
    let pickedIndexes = [];
    for (
      let index = 0;
      index < numHardCodedOptions && index < options_limt - 1;
      index++
    ) {
      let pickIndex = generateRandomIntExclude(
        0,
        numHardCodedOptions - 1,
        pickedIndexes
      );
      newWordInPlay.tileOptions.push(newWordInPlay.options[pickIndex]);
      pickedIndexes.push(pickIndex);
    }
  }
  //Prepare a list of allowed words that has no dups for guesss options generation
  let allowedWordList = [];
  randomziedWordList.forEach((wordObj) => {
    let contains = false;
    if (
      wordRegEx(wordObj.word.toLowerCase()).test(
        newWordInPlay.word.toLowerCase()
      )
    ) {
      contains = true;
    }
    newWordInPlay.tileOptions.forEach((wordObjOption) => {
      if (
        wordRegEx(wordObj.word.toLowerCase()).test(
          wordObjOption.word.toLowerCase()
        )
      ) {
        contains = true;
      }
    });
    allowedWordList.forEach((wordObjAllowed) => {
      if (
        wordRegEx(wordObj.word.toLowerCase()).test(
          wordObjAllowed.word.toLowerCase()
        )
      ) {
        contains = true;
      }
    });
    if (!contains) {
      allowedWordList.push(wordObj);
    }
  });

  //generate any remaining guess options
  let generateOptionsNum = options_limt - numHardCodedOptions;
  let pickedIndexes = [];
  for (
    let index = 0;
    index < generateOptionsNum - 1 &&
    pickedIndexes.length < allowedWordList.length;
    index++
  ) {
    let option_pick = generateRandomIntExclude(
      0,
      allowedWordList.length - 1,
      pickedIndexes
    );
    newWordInPlay.tileOptions.push(allowedWordList[option_pick]);
    pickedIndexes.push(option_pick);
  }
  //if the allowedWordList is too small then we just fill with empty
  // const leftovers = generateOptionsNum - pickedIndexes.length;
  // for (let ctr = 0; ctr < leftovers - 1; ctr++) {
  //   newWordInPlay.tileOptions.push({ word: "(Empty)", translate: "no" });
  // }

  //shuffle guess options
  newWordInPlay.tileOptions = shuffleArray(newWordInPlay.tileOptions);
  //set command strings
  newWordInPlay.tileOptions = newWordInPlay.tileOptions.map((option, index) => {
    if (option.word === newWordInPlay.word) {
      newWordInPlay.commandString = `${commandPrefix}${index + 1}`;
    }
    return {
      word: option.word,
      translate: option.translate ? option.translate : "yes",
      commandString: `${commandPrefix}${index + 1}`,
    };
  });
  //pick an asset
  if (assetIndex === -1) {
    newWordInPlay.chosenAsset =
      newWordInPlay.assets[getRandomInt(0, newWordInPlay.assets.length - 1)];
  } else {
    newWordInPlay.chosenAsset = newWordInPlay.assets[assetIndex];
  }
  //store word to localstorage
  if (categoryID != -1) {
    rememberWord(newWordInPlay, categoryID);
  }
  return newWordInPlay;
};

//setup asset
const setupAsset = (wordInPlay) => {
  return new Promise((reslove, reject) => {
    //Load asset
    if (
      wordInPlay.chosenAsset.type === "image" ||
      wordInPlay.chosenAsset.type === "audio"
    ) {
      loadAsset(
        wordInPlay.chosenAsset,
        useCDNLinks,
        wordInPlay.chosenAsset.type
      )
        .then((res) => {
          reslove(res);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reslove({
        data: wordInPlay.chosenAsset.data,
        contentType: wordInPlay.chosenAsset.type,
      });
    }
  });
};

const loadAsset = (asset, useCDNLink, fileType, retry = false) => {
  return new Promise((reslove, reject) => {
    let url = useCDNLink ? asset.cdn_url : `${staticAssetsPath}/${asset.data}`;
    // console.log(url);
    let contentType;
    switch (fileType) {
      case "audio":
        contentType = "audio/mpeg";
        break;
      case "image":
        contentType = "image/webp";
        break;
    }
    axios
      .get(url, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": contentType,
        },
      })
      .then(({ data }) => {
        reslove({
          data: URL.createObjectURL(
            new Blob([data], {
              type: contentType,
            })
          ),
          contentType: contentType,
        });
      })
      .catch((err) => {
        if (!retry) {
          console.error(
            `Retrying asset fetch with ${!useCDNLink ? "CDN" : "Static Folder"}`
          );
          loadAsset(asset, !useCDNLink, fileType, true)
            .then((objectData) => {
              reslove(objectData);
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          reject(err);
        }
      });
  });
};

const pickWord = (pickingIndex, wordList, categoryID) => {
  const store = localStorage.getItem(`Seen_Words_${categoryID}`);
  let wordPicked = wordList[pickingIndex];
  let indexPicked = pickingIndex;
  let wordSeen = false;
  if (store) {
    let wordsSeenInCat = JSON.parse(store);
    //if user seen all the words, then do not do a seen check
    if (wordsSeenInCat[wordPicked.difficulty - 1].length < wordList.length) {
      for (let index = pickingIndex; index < wordList.length; index++) {
        if (
          !hasWordBeenSeen(
            wordList[index],
            wordsSeenInCat,
            wordPicked.difficulty
          )
        ) {
          wordPicked = wordList[index];
          indexPicked = index;
          break;
        }
      }
    }
    wordSeen = hasWordBeenSeen(
      wordPicked,
      wordsSeenInCat,
      wordPicked.difficulty
    );
  }

  return {
    wordPicked: structuredClone(wordPicked),
    indexPicked: indexPicked,
    wordSeen: wordSeen,
  };
};

const hasWordBeenSeen = (wordObj, wordsSeenInCat, difficulty) => {
  return wordsSeenInCat[difficulty - 1].find(
    (seenWord) => seenWord.id === wordObj.id
  )
    ? true
    : false;
};

const rememberWord = (wordInPlay, categoryID) => {
  let newWordSeen = {
    id: wordInPlay.id,
    word: wordInPlay.word,
    assetData: wordInPlay.chosenAsset.data,
    difficulty: wordInPlay.difficulty,
  };
  //categoires->categroy->diffculties
  let store = localStorage.getItem(`Seen_Words_${categoryID}`);
  let wordsSeenInCat = [[], [], []];
  if (store) {
    wordsSeenInCat = JSON.parse(store);
    if (
      !wordsSeenInCat.find((difficultyArray) =>
        difficultyArray.find((seenWord) => seenWord.id === newWordSeen.id)
      )
    ) {
      wordsSeenInCat[wordInPlay.difficulty - 1].push(newWordSeen);
    }
  } else {
    wordsSeenInCat[wordInPlay.difficulty - 1].push(newWordSeen);
  }

  localStorage.setItem(
    `Seen_Words_${categoryID}`,
    JSON.stringify(wordsSeenInCat)
  );
};

export { pickWord, setupWord, setupAsset, loadAsset };
