import * as React from "react";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { gameStateAtom, GameStateEnum } from "../../recoil/gameState";
import useGameStateSet from "./useGameStateSet";
import {
  activeCatAtom,
  allWordsSelector,
  activeDifficultiesAtom,
} from "../../recoil/activeCat";
import { chatAtom } from "../../recoil/chat";
import { userAtom } from "../../recoil/user";
import categories from "../../game/categories";
import { roundScoresAtom, gameScoresAtom } from "../../recoil/roundState";
import { userGuessAtom } from "../../recoil/userGuess";
import { ErrorLevelEnum, errorStateAtom } from "../../recoil/errorState";
import { settingsAtom } from "../../recoil/settings";
import { bGStateAtom, BGTransitionStateEnum } from "../../recoil/BGState";

function DebugTools() {
  const gameState = useRecoilValue(gameStateAtom);
  const gameStateSet = useGameStateSet();
  const [bGState, setBGState] = useRecoilState(bGStateAtom);
  const activeCat = useRecoilValue(activeCatAtom);
  const activeDifficulties = useRecoilValue(activeDifficultiesAtom);
  const allWords = useRecoilValue(allWordsSelector);
  const user = useRecoilValue(userAtom);
  const chat = useRecoilValue(chatAtom);
  const roundScores = useRecoilValue(roundScoresAtom);
  const gameScores = useRecoilValue(gameScoresAtom);
  const userGuess = useRecoilValue(userGuessAtom);
  const settings = useRecoilValue(settingsAtom);
  const setErrorState = useSetRecoilState(errorStateAtom);

  const displayGameState = () => {
    return Object.keys(GameStateEnum)[gameState];
  };

  return (
    <div className="py-4 w-full bg-gray-700 text-white">
      <h2 className="font-bold">DebugTools</h2>
      <span>GameState: </span>
      <span>{displayGameState()}</span>
      <span className="ml-2">AuthType: </span>
      <span>{user.authType}</span>
      <span className="ml-2">
        Chat Connected: {chat.chatConnected ? "true" : "false"}
      </span>

      <span className="ml-5 bg-gray-500">
        <button
          onClick={() => {
            console.log(bGState);
          }}
        >
          Console BG State
        </button>
      </span>

      <span className="ml-5 bg-gray-500">
        <button
          onClick={() => {
            setBGState({ state: BGTransitionStateEnum.Start });
          }}
        >
          BG: Start Transition
        </button>
      </span>

      <span className="ml-5 bg-gray-500">
        <button
          onClick={() => {
            setBGState({ state: BGTransitionStateEnum.NextRound });
          }}
        >
          BG: Next Face Transition
        </button>
      </span>

      <span className="ml-5 bg-gray-500">
        <button
          onClick={() => {
            setBGState({ state: BGTransitionStateEnum.End });
          }}
        >
          BG: End Transition
        </button>
      </span>

      <span className="ml-5 bg-gray-500">
        <button
          onClick={() => {
            gameStateSet(GameStateEnum.Selection);
          }}
        >
          Set GameState: Selection
        </button>
      </span>

      <span className="ml-5 bg-gray-500">
        <button
          onClick={() => {
            gameStateSet(GameStateEnum.Selection);
          }}
        >
          Set GameState: RoundStart
        </button>
      </span>

      <span className="ml-5 bg-yellow-500">
        <button
          onClick={() => {
            setErrorState({
              show: true,
              message: "Test Dialog",
              errorLevelEnum: ErrorLevelEnum.FATAL,
            });
          }}
        >
          Test Error Dialog FATAL
        </button>
      </span>

      <span className="ml-5 bg-yellow-500">
        <button
          onClick={() => {
            setErrorState({
              show: true,
              message: "Test Dialog",
              errorLevelEnum: ErrorLevelEnum.INFO,
            });
          }}
        >
          Test Error Dialog INFO
        </button>
      </span>

      <span className="ml-5 bg-gray-400">
        <button
          onClick={() => {
            console.log(categories);
          }}
        >
          Console All Category
        </button>
      </span>

      <span className="ml-5 bg-gray-400">
        <button
          onClick={() => {
            console.log(activeCat);
          }}
        >
          Console Chosen Category
        </button>
      </span>
      <span className="ml-5 bg-gray-500">
        <button
          onClick={() => {
            console.log(activeDifficulties);
          }}
        >
          Console Active Difficulties
        </button>
      </span>

      <span className="ml-5 bg-gray-400">
        <button
          onClick={() => {
            console.log(settings);
          }}
        >
          Console Settings
        </button>
      </span>

      <span className="ml-5 bg-gray-400">
        <button
          onClick={() => {
            console.log(allWords);
          }}
        >
          Console All Words
        </button>
      </span>

      <span className="ml-5 bg-gray-400">
        <button
          onClick={() => {
            console.log(user);
          }}
        >
          Console User
        </button>
      </span>

      <span className="ml-5 bg-gray-400">
        <button
          onClick={() => {
            console.log(chat);
          }}
        >
          Console Chat Obj
        </button>
      </span>

      <span className="ml-5 bg-blue-400">
        <button
          onClick={() => {
            console.log(roundScores);
          }}
        >
          Console Round Scores
        </button>
      </span>

      <span className="ml-5 bg-blue-400">
        <button
          onClick={() => {
            console.log(gameScores);
          }}
        >
          Console Game Scores
        </button>
      </span>
      <span className="ml-5 bg-blue-400">
        <button
          onClick={() => {
            console.log(userGuess);
          }}
        >
          Console User Guess
        </button>
      </span>
    </div>
  );
}

export default DebugTools;
