import { atom } from "recoil";
import ErrorTypeEnum from "./ErrorTypeEnum";
import ErrorLevelEnum from "./ErrorLevelEnum";

const errorStateAtom = atom({
  key: "errorStateAtom",
  default: {
    show: false,
    message: "",
    errorTypeEnum: ErrorTypeEnum.none,
    errorLevelEnum: ErrorLevelEnum.INFO,
  },
});

export default errorStateAtom;
