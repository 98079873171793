import * as React from "react";
import PropTypes from "prop-types";
import zoomOut from "../../audio/zoomOut.mp3";
import Timer from "./Timer";

import { useRecoilValue } from "recoil";
import { settingsAtom } from "../../recoil/settings";

const progessionKeyFrames = [0.9, 0.7, 0.5, 0.4, 0.25]; //values are in precentages

const ImageView = ({ wordInPlay, objectURL, timer_secs, debug }) => {
  return (
    <div className="w-full h-full flex justify-center items-center relative">
      <div className="w-11/12 h-5/6 overflow-hidden">
        <Zoom
          doZoom={wordInPlay.chosenAsset.zoomPoint ? true : false}
          zoomPoint={wordInPlay.chosenAsset.zoomPoint}
          debug={debug}
          timer_secs={timer_secs}
        >
          <Blur
            doBlur={wordInPlay.chosenAsset.doBlur ? true : false}
            timer_secs={timer_secs}
            debug={debug}
          >
            <div
              style={{
                backgroundImage: `url(${objectURL})`,
              }}
              className="bg-no-repeat bg-contain bg-center w-full h-full"
            ></div>
          </Blur>
        </Zoom>
      </div>
    </div>
  );
};
ImageView.propTypes = {
  wordInPlay: PropTypes.object.isRequired,
  objectURL: PropTypes.string.isRequired,
  debug: PropTypes.bool.isRequired,
  timer_secs: PropTypes.number.isRequired,
};

const Blur = ({ doBlur, timer_secs, debug, children }) => {
  const blurValuesOnFrames = [1.5, 1.2, 0.8, 0.5, 0]; //Note: length must be the same as progessionKeyFrames

  const [allowBlur, setAllowBlur] = React.useState(doBlur);
  const [blurTime, setBlurTime] = React.useState(timer_secs);
  const [keyFramesTrack, setKeyFramesTrack] = React.useState([]);
  const [blurValue, setBlurValue] = React.useState(blurValuesOnFrames[0]);
  const settings = useRecoilValue(settingsAtom);

  const [timerKey, setTimerKey] = React.useState("Timer1");

  React.useEffect(() => {
    setAllowBlur(doBlur);
    if (!debug && doBlur) {
      blurStart();
    }
  }, []);

  const blurStart = () => {
    setBlurValue(blurValuesOnFrames[0]);
    //Setup keyframes tracking data
    let newKeyFramesTrack = [];
    progessionKeyFrames.forEach((keyFrame) => {
      newKeyFramesTrack.push({ frame: keyFrame, displayed: false });
    });
    setKeyFramesTrack(newKeyFramesTrack);
  };

  const blurDebugTools = () => {
    return (
      <div className="absolute left-0 bottom-0 z-10 text-left text-xs text-white">
        {allowBlur ? (
          <>
            <button
              className="block bg-black p-1"
              onClick={() => {
                //Force a timer remount
                setTimerKey(timerKey === "Timer1" ? "Timer2" : "Timer1");
                blurStart();
              }}
            >
              Run Blur
            </button>
            <button
              className="block bg-black p-1"
              onClick={() => {
                setBlurTime(10);
              }}
            >
              Set Blur Timer 10s
            </button>
            <button
              className="block bg-black p-1"
              onClick={() => {
                setBlurTime(35);
              }}
            >
              Set Blur Timer 35s
            </button>
          </>
        ) : (
          <>
            <button
              className="block bg-black p-1"
              onClick={() => {
                setAllowBlur(true);
              }}
            >
              Force Blur
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {debug && blurDebugTools()}
      {allowBlur && keyFramesTrack && (
        <Timer
          key={timerKey}
          timeInSecs={blurTime}
          hideContent={true}
          onTick={(secs) => {
            if (blurValue != 0) {
              let timeLeftPercentage = secs / blurTime;
              let newKeyFramesTrack = keyFramesTrack;
              keyFramesTrack.forEach((keyFrameObj, index) => {
                if (
                  keyFrameObj.frame >= timeLeftPercentage &&
                  !keyFrameObj.displayed
                ) {
                  newKeyFramesTrack[index].displayed = true;
                  setBlurValue(blurValuesOnFrames[index]);
                  if (!debug) {
                    const sfx = new Audio(zoomOut);
                    sfx.volume = settings.volume;
                    sfx.play();
                  }
                }
              });
              setKeyFramesTrack(newKeyFramesTrack);
            }
          }}
        />
      )}

      <div
        style={{
          filter: `${allowBlur && `blur(${blurValue}rem)`}`,
          transition: "1s filter linear",
        }}
        className="w-full h-full transition-transform duration-500 ease-in"
      >
        {children}
      </div>
    </>
  );
};

Blur.propTypes = {
  doBlur: PropTypes.bool,
  zoomPoint: PropTypes.string,
  timer_secs: PropTypes.number,
  debug: PropTypes.bool,
  children: PropTypes.node,
};

const Zoom = ({ doZoom, zoomPoint, timer_secs, debug, children }) => {
  const settings = useRecoilValue(settingsAtom);

  const [allowZoom, setAllowZoom] = React.useState(doZoom);
  const [targetZoomPoint, setTargetZoomPoint] = React.useState();
  const [zoomTime, setZoomTime] = React.useState(null);
  const [zoomOutScale, setZoomOutScale] = React.useState(0);
  const [keyFramesTrack, setKeyFramesTrack] = React.useState([]);
  const [zoom, setZoom] = React.useState(settings.imageZoomAmmount); // 1 is no zoom
  const [timerKey, setTimerKey] = React.useState("Timer1");

  React.useEffect(() => {
    if (!debug) {
      zoomStart();
    }
    if (zoomPoint) {
      setTargetZoomPoint(zoomPoint);
    } else {
      setTargetZoomPoint("50% 50%");
    }
  }, []);

  const zoomStart = () => {
    if (zoomTime === null) {
      setZoomTime(timer_secs);
    }
    //Setup keyframes tracking data
    let newKeyFramesTrack = [];
    progessionKeyFrames.forEach((keyFrame) => {
      newKeyFramesTrack.push({ frame: keyFrame, displayed: false });
    });
    setKeyFramesTrack(newKeyFramesTrack);
  };

  const debugZoomTools = () => {
    return (
      <div className="absolute left-0 top-0 z-10 text-left text-xs text-white">
        {allowZoom ? (
          <>
            {/* input */}
            {targetZoomPoint != null && (
              <input
                className="text-black bg-gray-200 mb-1 w-20"
                type="text"
                defaultValue={targetZoomPoint}
                onChange={(e) => {
                  setTargetZoomPoint(e.target.value);
                }}
              />
            )}

            <br />
            <button
              className="bg-black p-1 mb-1"
              onClick={() => {
                setZoomTime(10);
              }}
            >
              Set Zoom Timer 10s
            </button>
            <br />
            <button
              className="bg-black p-1 mb-1"
              onClick={() => {
                setZoomTime(35);
              }}
            >
              Set Zoom Timer 35s
            </button>
            <br />
            <button
              className="bg-black p-1"
              onClick={() => {
                //Force a timer remount
                setTimerKey(timerKey === "Timer1" ? "Timer2" : "Timer1");
                zoomStart();
              }}
            >
              Run Zoom
            </button>
          </>
        ) : (
          <>
            <button
              className="bg-black p-1"
              onClick={() => {
                setAllowZoom(true);
              }}
            >
              Force Zoom
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {debug && debugZoomTools()}
      {allowZoom && zoomTime && (
        <Timer
          key={timerKey}
          timeInSecs={zoomTime}
          hideContent={true}
          onStart={() => {
            setZoom(settings.imageZoomAmmount);
            setZoomOutScale(
              settings.imageZoomAmmount / progessionKeyFrames.length
            );
          }}
          onTick={(secs) => {
            if (zoom != 1) {
              let remainingPercentage = secs / zoomTime;
              let newKeyFramesTrack = keyFramesTrack;
              keyFramesTrack.forEach((keyFrameObj, index) => {
                if (
                  keyFrameObj.frame >= remainingPercentage &&
                  !keyFrameObj.displayed
                ) {
                  newKeyFramesTrack[index].displayed = true;
                  let newZoom = zoom - zoomOutScale;

                  if (newZoom <= 1) {
                    setZoom(1);
                  } else {
                    setZoom(zoom - zoomOutScale);
                  }
                  if (!debug) {
                    const sfx = new Audio(zoomOut);
                    sfx.volume = settings.volume;
                    sfx.play();
                  }
                }
              });
              setKeyFramesTrack(newKeyFramesTrack);
            }
          }}
        />
      )}
      <div
        style={{
          transform: `scale(${allowZoom ? zoom : "1"})`,
          transformOrigin: targetZoomPoint,
        }}
        className="w-full h-full transition-transform duration-500 ease-in"
      >
        {children}
      </div>
    </>
  );
};

Zoom.propTypes = {
  doZoom: PropTypes.bool,
  zoomPoint: PropTypes.string,
  timer_secs: PropTypes.number,
  debug: PropTypes.bool,
  children: PropTypes.node,
};

export default ImageView;
