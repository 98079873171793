import * as React from "react";
import PropTypes from "prop-types";
import OptionTile from "./OptionTile";
import Timer from "../utils/Timer";
import AudioPlayer from "../utils/AudioPlayer";
import ImageView from "../utils/ImageView";
import SkipBtn from "./SkipBtn";
import { Transition } from "@headlessui/react";

import { useRecoilValue, useRecoilState } from "recoil";
import { chatAtom } from "../../recoil/chat";
import { settingsAtom } from "../../recoil/settings";
import { bypassPrefix, skipWords as debugSkipWords } from "../../api";

import { calcScore, calcPenaltyScore } from "./scoreUtils";
import { RegExpEscape } from "../utils/utils";
import { roundScoresAtom } from "../../recoil/roundState";
import { userGuessAtom } from "../../recoil/userGuess";
import { userAtom } from "../../recoil/user";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglass } from "@fortawesome/free-solid-svg-icons";

import EndDelayCountdown from "./EndDelayCountdown";

function Guess({
  wordInPlay,
  assetData,
  onEnd,
  currentRound,
  max_rounds,
  timer_secs,
  debug = false,
}) {
  const chat = useRecoilValue(chatAtom);
  const settings = useRecoilValue(settingsAtom);
  const user = useRecoilValue(userAtom);
  const [roundScores, setRoundScores] = useRecoilState(roundScoresAtom);
  const [userGuess, setUserGuess] = useRecoilState(userGuessAtom);

  const [correctList, setCorrectList] = React.useState([]);
  const [incorrectList, setIncorrectList] = React.useState([]);
  const [totalCorrect, setTotalCorrect] = React.useState(0);
  const [totalIncorrect, setTotalIncorrect] = React.useState(0);
  const [currentSecs, setCurrentSecs] = React.useState(timer_secs);
  const [optionsGuessCounts, setOptionsGuessCounts] = React.useState({
    "!1": 0,
    "!2": 0,
    "!3": 0,
    "!4": 0,
    "!5": 0,
    "!6": 0,
  });

  const [roundEndDelay, setRoundEndDelay] = React.useState(false);
  const [showCountDelay, setShowCountDelay] = React.useState(false);

  const prefixReg = new RegExp(
    `^${RegExpEscape(settings.commandPrefix)}[1-6](\\s|$)`
  );
  const correctReg = new RegExp(
    `^${RegExpEscape(wordInPlay.commandString)}(\\s|$)`
  );

  const commandStringsRegEx = wordInPlay.tileOptions.map((option) => {
    return {
      commandString: option.commandString,
      regex: new RegExp(`^${RegExpEscape(option.commandString)}(\\s|$)`),
    };
  });

  React.useEffect(() => {
    setRoundScores({
      correctList: [],
      incorrectList: [],
      userScore: 0,
      optionsGuessCount: {},
    });
    setUserGuess({ ...userGuess });
  }, []);

  //handle chat guesses
  React.useEffect(() => {
    if (!chat.message) {
      return;
    }
    let messageObj = structuredClone(chat.message);
    onGuess(messageObj.display_name, messageObj.msg, messageObj.colour, false);
  }, [chat.message]);

  //handle user's guess
  React.useEffect(() => {
    if (userGuess.guessCommandString != null) {
      onGuess(user.display_name, userGuess.guessCommandString, "white", true);
    }
  }, [userGuess.guessCommandString]);

  const onGuess = (display_name, msg, colour, isUser) => {
    if (!msg || msg.length == 0) {
      return;
    }
    if (!(prefixReg.test(msg) || bypassPrefix)) {
      return;
    }

    //Check if player did not guess already
    let contains = false;
    correctList.forEach((playerGuess) => {
      if (
        playerGuess.display_name.toLowerCase() === display_name.toLowerCase()
      ) {
        contains = true;
      }
    });
    incorrectList.forEach((playerGuess) => {
      if (
        playerGuess.display_name.toLowerCase() === display_name.toLowerCase()
      ) {
        contains = true;
      }
    });

    //Note: don't count the very first second guesses (spam prevention)
    if (!contains && currentSecs != timer_secs) {
      if (correctReg.test(msg) || bypassPrefix) {
        //Is correct
        const correctObj = {
          msg: msg,
          display_name: display_name,
          timeLeft: currentSecs,
          timePassed: timer_secs - currentSecs,
          placement: correctList.length + 1,
          score: calcScore(
            timer_secs - currentSecs,
            wordInPlay.difficulty,
            correctList.length
          ),
          colour: colour,
          isUser: isUser,
        };

        //if its from the guess window, we update their own tracking score
        if (isUser) {
          setRoundScores({
            ...roundScores,
            userScore: correctObj.score,
          });
        }

        let newCorrectList = correctList;
        newCorrectList.push(correctObj);
        setCorrectList(newCorrectList);
        setTotalCorrect(newCorrectList.length);
        // console.log(`Correct: ${message.display_name}`);
      } else {
        //Is incorrect
        let newIncorrectList = incorrectList;
        const incorrectObj = {
          msg: msg,
          display_name: display_name,
          timeLeft: currentSecs,
          timePassed: timer_secs - currentSecs,
          colour: colour,
          isUser: isUser,
          scorePenalty: calcPenaltyScore(timer_secs - currentSecs),
        };
        newIncorrectList.push(incorrectObj);
        setIncorrectList(newIncorrectList);
        setTotalIncorrect(newIncorrectList.length);
        // console.log(`Incorrect: ${message.display_name}`);
      }
      //count guess totals for each option
      updateGuessOptionsCounts(msg);
    }
  };

  const updateGuessOptionsCounts = (msg) => {
    let newOptionsGuessCount = optionsGuessCounts;
    for (let index = 0; index < commandStringsRegEx.length; index++) {
      const element = commandStringsRegEx[index];
      if (element.regex.test(msg)) {
        newOptionsGuessCount[element.commandString] += 1;
      }
    }
    setOptionsGuessCounts(newOptionsGuessCount);
  };

  const listOptions = () => {
    return wordInPlay.tileOptions.map((option, index) => (
      <React.Fragment key={index}>
        <div
          key={`${option.word}-${option.commandString}`}
          className="w-5/12 lg:w-6/12 xl:w-5/12 h-1/4"
        >
          <OptionTile
            word={option.word}
            commandString={option.commandString}
            translate={option.translate}
          />
        </div>
        {index + 1 === 3 && (
          <div key={`${index + 1}-break`} className="h-full"></div>
        )}
      </React.Fragment>
    ));
  };

  const onRoundEnd = (skipped) => {
    setRoundScores({
      ...roundScores,
      correctList: correctList,
      incorrectList: incorrectList,
      optionsGuessCount: optionsGuessCounts,
    });
    onEnd(skipped);
  };

  return React.useMemo(
    () => (
      <>
        <Transition
          className="h-full w-full relative"
          appear={true}
          show={!roundEndDelay}
          enter="transition-opacity duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <span className="font-bold w-32 absolute top-2 left-2 text-lg text-black">
            <span className="mr-1">Round: </span>
            <span translate="no">{`${currentRound}/${max_rounds}`}</span>
          </span>

          {wordInPlay.wordSeen ||
            (debugSkipWords && (
              <span className=" absolute bottom-2 right-2">
                <SkipBtn
                  onSkip={() => {
                    onRoundEnd(true);
                  }}
                />
              </span>
            ))}

          <div className="h-full w-full flex flex-col items-center justify-center gap-5">
            <div className="flex justify-center text-center relative -top-2">
              <div className="bg-BOTC_DarkBlue w-max px-2 py-4 border border-black rounded-lg flex items-center">
                <FontAwesomeIcon
                  icon={faHourglass}
                  size="3x"
                  className="mx-2 text-BOTC_LightBlue"
                />
                <Timer
                  spanClass="block text-3xl md:text-5xl font-bold"
                  timeInSecs={timer_secs}
                  onTick={(secs) => {
                    setCurrentSecs(secs);
                  }}
                  onEnd={() => {
                    setRoundEndDelay(true);
                    setTimeout(() => {
                      setShowCountDelay(true);
                    }, 250);
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col justify-center">
              <h2 className="text-2xl lg:text-4xl font-bold py-5 px-7 border-2 border-black rounded-lg bg-BOTC_DarkBlue w-max">
                {wordInPlay.question}
              </h2>
              <span className="text-center text-xl font-bold text-black">
                <span className="opacity-div--spawn">
                  <span className="mr-1">Guesses: </span>
                  <span translate="no">{`${
                    totalCorrect + totalIncorrect
                  }`}</span>
                </span>
              </span>
            </div>

            <div className="w-full h-full flex justify-center items-center">
              {assetData.contentType === "audio/mpeg" && (
                <AudioPlayer
                  wordInPlay={wordInPlay}
                  objectURL={assetData.data}
                  timer_secs={timer_secs}
                  debug={debug}
                />
              )}
              {assetData.contentType === "image/webp" && (
                <ImageView
                  timer_secs={timer_secs}
                  wordInPlay={wordInPlay}
                  objectURL={assetData.data}
                  debug={debug}
                />
              )}
              {assetData.contentType === "text" && (
                <span
                  translate={
                    wordInPlay.chosenAsset.translate
                      ? wordInPlay.chosenAsset.translate
                      : "yes"
                  }
                  className="text-5xl font-bold px-4 mb-8 text-stroke--white"
                >
                  {assetData.data}
                </span>
              )}
            </div>

            <Transition
              className="h-full w-full lg:w-4/5 flex flex-wrap flex-col content-center  justify-center items-center gap-x-1 md:gap-x-10 gap-y-1 md:gap-y-4"
              appear={true}
              show={true}
              enter="transition-opacity duration-500 delay-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
            >
              {listOptions()}
            </Transition>
          </div>
        </Transition>

        <Transition
          className="h-full"
          appear={true}
          show={showCountDelay}
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <EndDelayCountdown
            settings={settings}
            onRoundEnd={onRoundEnd}
            totalCorrect={totalCorrect}
            totalIncorrect={totalIncorrect}
          />
        </Transition>
      </>
    ),
    [
      settings,
      userGuess,
      correctList,
      totalCorrect,
      totalIncorrect,
      roundEndDelay,
      showCountDelay,
      assetData,
    ]
  );
}
Guess.propTypes = {
  wordInPlay: PropTypes.object.isRequired,
  assetData: PropTypes.object.isRequired,
  onEnd: PropTypes.func.isRequired,
  currentRound: PropTypes.number.isRequired,
  max_rounds: PropTypes.number.isRequired,
  timer_secs: PropTypes.number.isRequired,
  debug: PropTypes.bool,
};

export default Guess;
