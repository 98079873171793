import * as React from "react";

const strictEquals = (prev, next) => prev === next;

export default function usePrevious(value, compare = strictEquals) {
  const prevRef = React.useRef();
  const curRef = React.useRef(value);
  const isFirstMount = useFirstMountState();

  if (!isFirstMount && !compare(curRef.current, value)) {
    prevRef.current = curRef.current;
    curRef.current = value;
  }

  return prevRef.current;
}

function useFirstMountState() {
  const isFirst = React.useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}
