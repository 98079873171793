import * as React from "react";

import { useRecoilValue } from "recoil";
import { gameScoresAtom } from "../../recoil/roundState";
import { numberWithCommas } from "../utils/utils";
import ChatLeaderboard from "../utils/ChatLeaderboard";

function LeaderBoard() {
  const gameScores = useRecoilValue(gameScoresAtom);

  return (
    <div className="flex flex-col h-full">
      <span className="font-medium text-center text-3xl h-24 flex justify-center items-center flex-shrink-0">
        <span translate="no" className="font-bold text-yellow-600">
          {numberWithCommas(gameScores.totalScore)}
        </span>
      </span>

      <div className="w-full">
        <div className="relative">
          <h1 className="block font-bold text-center text-3xl text-BOTC_Yellow pb-2">
            LeaderBoard
          </h1>
        </div>
      </div>
      <div className="overflow-y-scroll text-xl">
        {gameScores.chatLeaderboard.length > 0 && (
          <ChatLeaderboard showAll={false} />
        )}
      </div>
    </div>
  );
}

export default LeaderBoard;
