import * as React from "react";
import { Transition } from "@headlessui/react";
import DebugTools from "./utils/DebugTools";
import DebugCategory from "./utils/debugCategory/DebugCategory";
import SelectionPanel from "./selectionPanel/SelectionPanel";
import Landing from "./Landing";
import PlayPanel from "./playPanel/PlayPanel";
import SidePanel from "./sidePanel/SidePanel";
import ErrorDialog from "./ErrorDialog";
import BGCanvas from "./three/BGCanvas";
import YourGuessWindow from "./sidePanel/YourGuessWindow";
import Tutorial from "./Tutorial";
import FinalResults from "./FinalResults";
import LinkBar from "./LinkBar";
import Pages from "./Pages";
import BoxPattern from "./playPanel/BoxPattern";

import { useRecoilValue } from "recoil";
import { gameStateAtom, GameStateEnum } from "../recoil/gameState";
import { bGStateAtom } from "../recoil/BGState";
import useGameStateSet from "./utils/useGameStateSet";
import { debugTools, debugCategory, bypassChatRequirement } from "../api";
import useChat from "./singletonHooks/useChat";
import useSettingsState from "./singletonHooks/useSettingsState";
import useWindowDimensions from "./utils/useWindowDimensions";
import { dataLayerPush } from "./utils/utils";
import AudioPreLoader from "./utils/AudioPreLoader";

const MainContent = () => {
  const gameState = useRecoilValue(gameStateAtom);
  const bGState = useRecoilValue(bGStateAtom);
  const gameStateSet = useGameStateSet();
  const [selectionScreen, setSelectionScreen] = React.useState(false);
  const [finalResults, setFinalResults] = React.useState(false);
  const [playScreen, setPlayScreen] = React.useState(false);
  const [roundTransition, setRoundTransition] = React.useState(false);
  const [landingScreen, setLandingScreen] = React.useState(false);
  const [tutorial, setTutorial] = React.useState(false);

  const [inTransition, setInTransition] = React.useState(
    bypassChatRequirement ? false : true
  );

  const [pagesScreen, setPagesScreen] = React.useState(false);
  const [page, setPage] = React.useState("");

  useChat(); //used for initializing the chat, getting a chat message event is done by the chat atom
  useSettingsState(); //used for initializing settings from local storage

  const { height: window_height } = useWindowDimensions();

  React.useEffect(() => {
    setSelectionScreen(gameState === GameStateEnum.Selection ? true : false);
    setPlayScreen(
      gameState >= GameStateEnum.RoundTransition &&
        gameState <= GameStateEnum.Results
    );
    setRoundTransition(gameState === GameStateEnum.RoundTransition);
    setFinalResults(gameState === GameStateEnum.FinalResults ? true : false);
    setLandingScreen(gameState === GameStateEnum.SignIn);
    setTutorial(gameState === GameStateEnum.Tutorial);

    if (gameState === GameStateEnum.ChatConnected) {
      gameStateSet(GameStateEnum.Selection);
    }
  }, [gameState]);

  React.useEffect(() => {
    document.body.className = "";
    document.body.classList.add(bGState.backgroundClass);
    document.body.classList.add("background-transition--fast");
  }, [bGState]);

  return React.useMemo(
    () => (
      <>
        <AudioPreLoader />
        <BGCanvas />
        {(tutorial || playScreen) && !roundTransition && (
          <BoxPattern
            tileHeight={80}
            tileWidth={70}
            tileClass=" bg-BOTC_Orange"
            tileWrapper={"left-0"}
            tick={3000}
            ammount={{ min: 15, max: 20 }}
            opacity={{ min: 0.7, max: 1 }}
          />
        )}

        <main
          className={`relative min-h-screen ${
            window_height < 800 ? "lg:h-hMinScreen" : "lg:h-screen"
          }`}
        >
          {debugTools && <DebugTools />}
          {debugCategory && <DebugCategory />}
          <YourGuessWindow />
          <ErrorDialog />

          {(landingScreen || selectionScreen) && !debugCategory && (
            <LinkBar
              onPageChange={(pageName) => {
                setPagesScreen(true);
                setPage(pageName);
                dataLayerPush("pages_click", { page_name: pageName });
              }}
            />
          )}

          {pagesScreen && (
            <Pages
              pageName={page}
              onBack={() => {
                setPagesScreen(false);
                dataLayerPush("pages_back");
              }}
            />
          )}

          <Transition
            show={landingScreen && !pagesScreen}
            appear={true}
            className="w-full h-full"
            leave="transition-opacity duration-900"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => {
              setInTransition(false);
            }}
          >
            <Landing />
          </Transition>

          {tutorial && !inTransition && <Tutorial />}
          {finalResults && <FinalResults />}

          {/* Panels */}
          <Transition
            show={
              !landingScreen && !finalResults && !pagesScreen && !inTransition
            }
            className={`w-full ${!tutorial && "h-full"}`}
            enter="transition-opacity duration-900"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            beforeLeave={() => {
              setInTransition(true);
            }}
            afterLeave={() => {
              setInTransition(false);
            }}
          >
            <div className="flex h-full flex-wrap relative">
              <div className="w-full order-3 lg:order-1 lg:w-4/12 xl:w-3/12 h-full flex-none">
                <Transition
                  className="w-full h-full"
                  appear={true}
                  show={(selectionScreen || playScreen) && !roundTransition}
                  enter="transition-opacity duration-700"
                  enterFrom={`${!selectionScreen && "opacity-0 "}`}
                  enterTo="opacity-100"
                >
                  <SidePanel />
                </Transition>
              </div>
              <div
                className={`w-full h-full order-2 lg:w-8/12 xl:w-9/12 ${
                  finalResults && "hidden"
                }`}
              >
                {selectionScreen && <SelectionPanel />}
                {playScreen && (
                  <div
                    className={`${roundTransition && "hidden"} h-280 lg:h-full`}
                  >
                    <PlayPanel />
                  </div>
                )}
              </div>
            </div>
          </Transition>
        </main>
      </>
    ),
    [
      selectionScreen,
      finalResults,
      playScreen,
      roundTransition,
      landingScreen,
      tutorial,
      pagesScreen,
      page,
      inTransition,
      window_height,
    ]
  );
};

export default MainContent;
