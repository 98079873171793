import * as React from "react";
import PropTypes from "prop-types";
import Slider from "rc-slider";

//https://slider-react-component.vercel.app/

const DTCSlider = ({
  defaultValue = 0,
  onChange,
  onAfterChange,
  ariaLabel,
}) => {
  return (
    <Slider
      railStyle={{
        backgroundColor: "#3B414E",
        height: 13,
        boxShadow: "inset 1px 3px 11px -6px #000000",
        borderRadius: 1,
      }}
      trackStyle={{
        backgroundColor: "#538845",
        height: 13,
        boxShadow: "inset 1px 3px 11px -6px #000000",
        borderRadius: 1,
      }}
      handleStyle={{
        borderColor: "white",
        height: 25,
        width: 15,
        backgroundColor: "white",
        borderRadius: 1,
      }}
      ariaLabelForHandle={ariaLabel}
      tabIndex={0}
      onAfterChange={onAfterChange}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
};

DTCSlider.propTypes = {
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
  onAfterChange: PropTypes.func,
  ariaLabel: PropTypes.string,
};

export default DTCSlider;
