import {
  category_modules,
  iCategoryModule,
  WordObject,
} from "./category_modules";
import { DifficultyEnum } from "../recoil/activeCat";
import { dummyCategory } from "../api";

interface iCategory {
  readonly id: number;
  readonly name: string;
  translateTitle: string;
  categoryModObjs: Array<CategoryModObj>;
  supportedDifficulties: Array<number>;
  active: boolean;
  overrideQuestion?: string;

  easyWords: WordObject[];
  mediumWords: WordObject[];
  hardWords: WordObject[];
}

type CategoryModObj = {
  supportedDifficulties: Array<number>;
  categoryModule: iCategoryModule;
};

class Category implements iCategory {
  id: number;
  name: string;
  categoryModObjs: CategoryModObj[];
  supportedDifficulties: number[];
  translateTitle: string;
  active = false;
  overrideQuestion: string;

  easyWords: WordObject[] = [];
  mediumWords: WordObject[] = [];
  hardWords: WordObject[] = [];

  constructor(
    id: number,
    name: string,
    categoryModules: Array<CategoryModObj>,
    supportedDifficulties: number[],
    overrideQuestion: string = null,
    translateTitle = "yes"
  ) {
    this.id = id;
    this.name = name;
    this.categoryModObjs = categoryModules;
    this.supportedDifficulties = supportedDifficulties;
    this.translateTitle = translateTitle;
    this.overrideQuestion = overrideQuestion;

    //Get all words from the cat modules based on supported difficulties
    this.categoryModObjs.forEach((categoryModObj) => {
      if (categoryModObj.supportedDifficulties.includes(DifficultyEnum.easy)) {
        categoryModObj.categoryModule.getEasyWords().forEach((wordObj) => {
          wordObj.fileName = categoryModObj.categoryModule.fileName;
          wordObj.question = this.overrideQuestion
            ? this.overrideQuestion
            : wordObj.question
            ? wordObj.question
            : categoryModObj.categoryModule.question;
          this.easyWords.push(wordObj);
        });
      }
      if (
        categoryModObj.supportedDifficulties.includes(DifficultyEnum.medium)
      ) {
        categoryModObj.categoryModule.getMediumWords().forEach((wordObj) => {
          wordObj.fileName = categoryModObj.categoryModule.fileName;
          wordObj.question = this.overrideQuestion
            ? this.overrideQuestion
            : wordObj.question
            ? wordObj.question
            : categoryModObj.categoryModule.question;
          this.mediumWords.push(wordObj);
        });
      }
      if (categoryModObj.supportedDifficulties.includes(DifficultyEnum.hard)) {
        categoryModObj.categoryModule.getHardWords().forEach((wordObj) => {
          wordObj.fileName = categoryModObj.categoryModule.fileName;
          wordObj.question = this.overrideQuestion
            ? this.overrideQuestion
            : wordObj.question
            ? wordObj.question
            : categoryModObj.categoryModule.question;
          this.hardWords.push(wordObj);
        });
      }
    });
  }

  getWords(difficultyEnums: number[]): Array<WordObject> {
    let words: Array<WordObject> = [];
    if (difficultyEnums.includes(DifficultyEnum.easy)) {
      words = words.concat(this.easyWords);
    }
    if (difficultyEnums.includes(DifficultyEnum.medium)) {
      words = words.concat(this.mediumWords);
    }
    if (difficultyEnums.includes(DifficultyEnum.hard)) {
      words = words.concat(this.hardWords);
    }
    return words;
  }
}

const categories: Category[] = [];

categories.push(
  new Category(
    1,
    "Video Games OST",
    [
      {
        supportedDifficulties: [
          DifficultyEnum.easy,
          DifficultyEnum.medium,
          DifficultyEnum.hard,
        ],
        categoryModule: category_modules[1],
      },
    ],
    [DifficultyEnum.easy, DifficultyEnum.medium, DifficultyEnum.hard]
  )
);

categories.push(
  new Category(
    2,
    "Country Flags",
    [
      {
        supportedDifficulties: [DifficultyEnum.medium],
        categoryModule: category_modules[2],
      },
    ],
    [DifficultyEnum.medium]
  )
);

categories.push(
  new Category(
    3,
    "Live Streamers",
    [
      {
        supportedDifficulties: [
          DifficultyEnum.easy,
          DifficultyEnum.medium,
          DifficultyEnum.hard,
        ],
        categoryModule: category_modules[3],
      },
    ],
    [DifficultyEnum.easy, DifficultyEnum.medium, DifficultyEnum.hard]
  )
);

categories.push(
  new Category(
    4,
    "Video Games Sounds",
    [
      {
        supportedDifficulties: [
          DifficultyEnum.easy,
          DifficultyEnum.medium,
          DifficultyEnum.hard,
        ],
        categoryModule: category_modules[4],
      },
    ],
    [DifficultyEnum.easy, DifficultyEnum.medium, DifficultyEnum.hard]
  )
);

categories.push(
  new Category(
    5,
    "Celebrity Faces",
    [
      {
        supportedDifficulties: [DifficultyEnum.medium],
        categoryModule: category_modules[5],
      },
    ],
    [DifficultyEnum.medium]
  )
);

categories.push(
  new Category(
    6,
    "Brand Logos",
    [
      {
        supportedDifficulties: [DifficultyEnum.easy, DifficultyEnum.medium],
        categoryModule: category_modules[6],
      },
    ],
    [DifficultyEnum.easy, DifficultyEnum.medium]
  )
);

categories.push(
  new Category(
    7,
    "2010's Music",
    [
      {
        supportedDifficulties: [DifficultyEnum.easy],
        categoryModule: category_modules[7],
      },
    ],
    [DifficultyEnum.easy]
  )
);
categories.push(
  new Category(
    8,
    "2000's Music",
    [
      {
        supportedDifficulties: [DifficultyEnum.medium],
        categoryModule: category_modules[8],
      },
    ],
    [DifficultyEnum.medium]
  )
);

if (dummyCategory) {
  categories.push(
    new Category(
      99,
      "Dummy",
      [
        {
          supportedDifficulties: [
            DifficultyEnum.easy,
            DifficultyEnum.medium,
            DifficultyEnum.hard,
          ],
          categoryModule: category_modules[99],
        },
      ],
      [DifficultyEnum.easy, DifficultyEnum.medium, DifficultyEnum.hard]
    )
  );
}

export { Category, CategoryModObj };
export default categories;
