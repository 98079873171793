import * as React from "react";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CSSTransition } from "react-transition-group";
import { Transition } from "@headlessui/react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { gameStateAtom, GameStateEnum } from "../../recoil/gameState";
import { guessWindowAtom } from "../../recoil/userGuess";
import { activeCatAtom } from "../../recoil/activeCat";
import ChannelName from "../utils/ChannelName";
import Placeholder from "./Placeholder";
import usePrevious from "../utils/usePrevious";
import { dataLayerPush } from "../utils/utils";

import LeaderBoard from "./LeaderBoard";
import Settings from "./Settings";

function SidePanel() {
  const gameState = useRecoilValue(gameStateAtom);
  const previousGameState = usePrevious(gameState);
  const activeCatgory = useRecoilValue(activeCatAtom);
  const setGuessWindowState = useSetRecoilState(guessWindowAtom);
  const [currentPanel, setCurrentPanel] = React.useState("MainMenu");
  const [transitionState, setTransitionState] = React.useState(true);
  const [reverseTransition, setReverseTransition] = React.useState(false);

  const [showContent, setShowContent] = React.useState(false);

  const setPanel = (panelName) => {
    setTransitionState(false);
    setTimeout(() => {
      setCurrentPanel(panelName);
      setTransitionState(true);
      setReverseTransition((reverseTransition) => !reverseTransition);
    }, 200);
  };

  React.useEffect(() => {
    if (
      gameState >= GameStateEnum.RoundStart &&
      gameState <= GameStateEnum.Results
    ) {
      //delay must be the sames as the spawn animation duration
      setTimeout(() => {
        setShowContent(true);
      }, 2500);
    } else {
      setShowContent(true);
    }
  }, []);

  const SelectionContent = () => {
    return (
      <div className="w-full text-center pt-12">
        <ChannelName />
        <div className="flex justify-center items-center flex-col ">
          <button
            className="border py-2 px-5 font-bold bg-white text-black hover:border-2 hover:border-black mt-12 w-max"
            onClick={() => {
              location.reload();
            }}
          >
            <span className="font-bold">Sign Out</span>
          </button>
        </div>
      </div>
    );
  };

  const GameContent = () => {
    return (
      <div className="h-full pl-6 pr-2 pb-1">
        <LeaderBoard />
      </div>
    );
  };
  const PanelTemplate = (childern) => {
    return (
      <>
        <Transition
          className={`absolute left-1/2 lg:left-0 z-10 transform -translate-x-1/2 lg:translate-x-0 ${
            gameState === GameStateEnum.Selection && "mx-auto lg:m-0"
          }`}
          appear={true}
          show={currentPanel === "MainMenu"}
          enter="transition-opacity duration-700"
          enterFrom="opacity-0 "
          enterTo="opacity-100"
        >
          <button
            className="p-2"
            onClick={() => {
              dataLayerPush("settings_click");
              setPanel("Settings");
            }}
          >
            <FontAwesomeIcon icon={faCog} size="lg" />
          </button>
        </Transition>

        <Transition
          className="w-full absolute"
          appear={true}
          show={
            gameState >= GameStateEnum.RoundStart && currentPanel == "MainMenu"
          }
          enter="transition-opacity duration-700"
          enterFrom="opacity-0 "
          enterTo="opacity-100"
        >
          <h1 className="text-center w-full text-2xl text-white font-bold pt-8 lg:pt-5">
            {activeCatgory && activeCatgory.name}
          </h1>
        </Transition>

        <Transition
          className="w-full h-full"
          appear={true}
          show={showContent}
          enter="transition-opacity duration-700"
          enterFrom={`${gameState !== GameStateEnum.Selection && "opacity-0 "}`}
          enterTo="opacity-100"
        >
          <CSSTransition
            in={transitionState}
            classNames={reverseTransition ? "reverseFade" : "fade"}
            timeout={200}
          >
            <div className="h-p92 lg:h-p57">
              <div
                className={
                  currentPanel == "MainMenu" ? "block h-full" : "hidden"
                }
              >
                {/* Placeholder for cat name */}
                <div
                  style={{ height: "10%" }}
                  className={`${
                    gameState >= GameStateEnum.RoundTransition
                      ? "block"
                      : "hidden"
                  }`}
                >
                  <div style={{ height: "52px" }}></div>
                </div>

                <div style={{ height: "90%" }}>{childern}</div>
              </div>
              <div
                className={
                  currentPanel == "Settings" ? "block h-full" : "hidden"
                }
              >
                <Settings setPanel={setPanel} />
              </div>
            </div>
          </CSSTransition>
          <div className="h-p8 lg:h-p43 ">
            <div className="h-full lg:h-p8 w-max mx-auto">
              <button
                onClick={() => {
                  setGuessWindowState({ opening: true });
                }}
                label="Open You Guess Window"
                className="font-bold text-sm text-white border py-1 px-2 hover:bg-white hover:text-black transition-colors duration-300"
              >
                Your Guess Window
              </button>
            </div>
            <div className="h-p92 lg:block hidden">
              <Placeholder />
            </div>
          </div>
        </Transition>
      </>
    );
  };

  return (
    <>
      {/* selection screen */}
      {gameState <= GameStateEnum.Selection && (
        <div className="px-1 py-20 lg:py-0 h-170 lg:h-full overflow-hidden ">
          {PanelTemplate(<SelectionContent />)}
        </div>
      )}
      {/* play screen,  
          /* cut the leave transition if gameState came from selection*/}
      {gameState >= GameStateEnum.RoundTransition &&
        previousGameState != GameStateEnum.Selection && (
          <div
            className={
              "overflow-hidden h-170 lg:h-full bg-BOTC_DarkBlue drop-shadow-2xl sidepanel-div--spawn  relative"
            }
          >
            <div className="bg-BOTC_Green absolute w-1 h-full"></div>
            <div className="px-1 h-full relative">
              {PanelTemplate(<GameContent />)}
            </div>
          </div>
        )}
    </>
  );
}

export default SidePanel;
