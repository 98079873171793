import * as React from "react";
import PropTypes from "prop-types";
import { useThree, useFrame } from "@react-three/fiber";
import Transition from "./Transition";
import { Vector3 } from "three";

const initalState = {
  zoom: 2.5,
  position: new Vector3(0, 0, 5),
};

const CameraStateEnum = Object.freeze({
  None: 0,
  Start: 1,
  Next: 2,
  NextEnded: 3,
  End: 4,
});

const CameraTransition = ({
  cameraStateEnum,
  onStartEnd = () => null,
  onNextEnd = () => null,
}) => {
  const { camera } = useThree();
  const startTransition = React.useRef();
  const startTransitionData = React.useRef();

  const nextFaceTransition = React.useRef();
  const nextFaceTransitionData = React.useRef();

  const endTransition = React.useRef();
  const endTransitionData = React.useRef();

  React.useEffect(() => {
    startTransitionData.current = {
      targetZoomOut: 0.9,
      targetZoomIn: 40,

      restAtTick: 60,
      restAmmount: 150,

      zoomOutRate: null,
      zoomInRate: null,
    };
    startTransition.current = new Transition({
      durationMills: 3000,
      tickIntervalMills: 10,
      onStart: () => {
        const transData = startTransitionData.current;

        let ticksNeededPhase1 = transData.restAtTick;
        let ticskNeededPhase2 =
          startTransition.current.ticksNeeded -
          (transData.restAtTick + transData.restAmmount);

        transData.zoomOutRate =
          (camera.zoom - transData.targetZoomOut) / ticksNeededPhase1;
        transData.zoomInRate =
          (transData.targetZoomIn - transData.targetZoomOut) /
          ticskNeededPhase2;
      },
      onUpdate: () => {
        const startTrans = startTransition.current;
        const transData = startTransitionData.current;

        camera.lookAt(0, 0, 0);

        const totalRest = transData.restAtTick + transData.restAmmount;
        if (
          startTrans.elapsedTicks <= totalRest &&
          startTrans.elapsedTicks > transData.restAtTick
        ) {
          // console.log("Resting...");
        } else {
          if (startTrans.elapsedTicks <= transData.restAtTick) {
            camera.zoom -= transData.zoomOutRate;
          } else {
            camera.zoom += transData.zoomInRate;
          }
          // console.log(
          //   `Zoom:${camera.zoom} elapsedTicks ${startTrans.elapsedTicks}`
          // );
          camera.updateProjectionMatrix();
        }
      },
      onEnd: onStartEnd, //Update state
    });
    //---------------------------------------------------------------
    nextFaceTransitionData.current = {
      targetZoomOut: 4,
      targetZoomIn: startTransitionData.current.targetZoomIn,

      restAtTick: 80,
      restAmmount: 125,

      zoomOutRate: null,
      zoomInRate: null,
    };

    nextFaceTransition.current = new Transition({
      durationMills: 3200,
      tickIntervalMills: 10,
      onStart: () => {
        const transData = nextFaceTransitionData.current;

        let ticksNeededPhase1 = transData.restAtTick;
        let ticskNeededPhase2 =
          nextFaceTransition.current.ticksNeeded -
          (transData.restAtTick + transData.restAmmount);

        transData.zoomOutRate =
          (camera.zoom - transData.targetZoomOut) / ticksNeededPhase1;
        transData.zoomInRate =
          (transData.targetZoomIn - transData.targetZoomOut) /
          ticskNeededPhase2;
      },
      onUpdate: () => {
        const nextTrans = nextFaceTransition.current;
        const transData = nextFaceTransitionData.current;

        camera.lookAt(0, 0, 0);

        const totalRest = transData.restAtTick + transData.restAmmount;
        if (
          nextTrans.elapsedTicks <= totalRest &&
          nextTrans.elapsedTicks > transData.restAtTick
        ) {
          // console.log("Resting...");
        } else {
          if (nextTrans.elapsedTicks <= transData.restAtTick) {
            camera.zoom -= transData.zoomOutRate;
          } else {
            camera.zoom += transData.zoomInRate;
          }
          // console.log(
          //   `Zoom:${camera.zoom} elapsedTicks ${startTrans.elapsedTicks}`
          // );
          camera.updateProjectionMatrix();
        }
      },
      onEnd: onNextEnd,
    });

    //---------------------------------------------------------------
    endTransitionData.current = {
      targetZoomOut: initalState.zoom,

      zoomOutRate: null,
    };

    endTransition.current = new Transition({
      durationMills: 4000,
      tickIntervalMills: 10,
      onStart: () => {
        camera.zoom = 20;
        const transData = endTransitionData.current;
        transData.zoomOutRate =
          (camera.zoom - transData.targetZoomOut) /
          endTransition.current.ticksNeeded;
      },
      onUpdate: () => {
        const transData = endTransitionData.current;
        camera.lookAt(0, 0, 0);
        camera.zoom -= transData.zoomOutRate;
        // console.log(
        //   `Zoom:${camera.zoom} elapsedTicks ${endTransition.current.elapsedTicks}`
        // );
        camera.updateProjectionMatrix();
      },
      onEnd: () => null,
    });

    camera.zoom = initalState.zoom;
    camera.position.set(
      initalState.position.x,
      initalState.position.y,
      initalState.position.z
    );
    camera.updateProjectionMatrix();
    camera.lookAt(0, 0, 0);
  }, []);

  React.useEffect(() => {
    if (cameraStateEnum === CameraStateEnum.Start) {
      // console.log("Camera Start");
      startTransition.current.start();
    } else if (cameraStateEnum === CameraStateEnum.Next) {
      // console.log("Camera Next");
      nextFaceTransition.current.start();
    } else if (cameraStateEnum === CameraStateEnum.End) {
      // console.log("Camera End");
      endTransition.current.start();
    }
  }, [cameraStateEnum]);

  useFrame((state) => {
    startTransition.current.onFrameUpdate(state);
    nextFaceTransition.current.onFrameUpdate(state);
    endTransition.current.onFrameUpdate(state);
  });

  return <></>;
};

CameraTransition.propTypes = {
  cameraStateEnum: PropTypes.number.isRequired,
  onStartEnd: PropTypes.func,
  onNextEnd: PropTypes.func,
};

export { CameraTransition, CameraStateEnum };
